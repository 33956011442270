import React, { useEffect } from 'react';
import './App.css';
import { AppRouter } from './router/AppRouter';
import { IdProvider } from './context/IdProvider';
import { AuthProvider } from './auth';
import useDeviceScale from './hooks/useDeviceScale';




function App() {

  const scale = useDeviceScale();

  const appStyle = {
    transform: `scale(${1 / scale})`,
    transformOrigin: '0 0',
    width: `${100 * scale}vw`,
    height: `${100 * scale}vh`,
    overflow: 'auto' // Para manejar cualquier overflow
  };



  return (
    <div
      className="appContainer"
      // style={appStyle}
    >
      <AuthProvider>
        <IdProvider>
          <AppRouter />
        </IdProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
