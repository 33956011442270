import { NotData } from '../../shared/alert/notData'
import { VentasDeudor } from '../resumen/graficosPie/VentasDeudor'
export const Card4 = ({
  ResVensDdr
}) => {
  console.log(ResVensDdr)
  const msg = 'No tenemos datos para ventas x deudor.'
  const paleta = [
    '#0D2E82',
    '#74D3E8',
    '#657AB0',
    '#EBC3B0',
    '#B55C48',
    '#A2AFCF',
    '#BC9C8C',
    '#D9D9D9',
  ]
  const porcentaje = []
  let suma = 0
  let sum = 0
  let counter = -1
  if (ResVensDdr.length > 0) {
    ResVensDdr.forEach((numeros) => {
      suma += numeros.montoFactura;
    });
    ResVensDdr.forEach((numeros) => {
      porcentaje.push((numeros.montoFactura * 100 / suma).toFixed(1))
      sum += numeros.montoFactura * 100 / suma;
    });
    ResVensDdr.forEach((numeros) => {
      let porc = (numeros.montoFactura * 100 / suma).toFixed(1)
      numeros.porcentaje = parseFloat(porc)
      sum += numeros.montoFactura * 100 / suma;
    });
  }
  const idSelector = () => {
    return 'modal'
  }
  return (
    <>
      {ResVensDdr.length > 0 ?
        <div className="card  mb-3" onClick={() => idSelector()}
          data-bs-toggle="modal" data-bs-target="#ModalDetalleGraficoVentasDeudor">
          <div className="row g-0">
            <p className='titGraf ps-2 py-3'>Ventas x deudor</p>
            <hr />
            <div className="col-md-4 ps-5 py-3">
              <VentasDeudor
                monto={ResVensDdr.montoFactura}
                porcentaje={porcentaje}
              />
            </div>
            <div className="col-md-8 align-self-center pb-3 ps-5">
              {ResVensDdr.map(resvd => (
                < div style={{
                  fontSize: "0.65em",
                }}
                  key={resvd.idDeudor}
                  counter={counter += 1}
                >
                  <div className="d-flex pe-4">
                    <div className="cuadrado mt-1 me-3" style={{ background: paleta[counter] }}></div>
                    {resvd.porcentaje}% {resvd.nombreDeudor}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        :
        <div className="card  mb-3">
          <div className="row g-0">
            <p className='titGraf ps-2 py-3'>Ventas x deudor</p>
            <hr />
            <div className="my-5 d-flex justify-content-center">
              <NotData msg={msg} />
            </div>
          </div>
        </div>
      }
    </>
  )
}