

export const formatVal = {
    // maxSig(num) {
    //     let dig = 5

    //     if (num > 999999999) {
    //         return dig = 6
    //     }
    //     if (num > 99999999) {
    //         return dig = 5
    //     }
    //     if (num > 9999999) {
    //         return dig = 4
    //     }
    // },

    formatValueCLP(valor) {
        const valorFormateado = Math.round(valor / 1000).toLocaleString("es-CL")
        return `$  ${valorFormateado.replace(',', '.')}`; // Reemplazar el punto decimal por coma
    },
    formatValueCLPFull(valor) {
        const valorFormateado = valor.toLocaleString("es-CL")
        return `$  ${valorFormateado.replace(',', '.')}`; // Reemplazar el punto decimal por coma
    },

    formatRut(rut, digitoVerificador) {
        // Si solo se proporciona una variable, asumimos que contiene el RUT completo con dígito verificador.
        if (digitoVerificador === undefined) {
            const rutString = rut?.toString().replace(/[^0-9kK]/g, ''); // Eliminar cualquier carácter no numérico ni 'k' o 'K'.
            digitoVerificador = rutString?.slice(-1); // El último carácter es el dígito verificador.
            rut = rutString?.slice(0, -1); // El resto es el RUT.
        }

        // Asegurarse de que el RUT no contenga el dígito verificador al formatear.
        rut = rut?.toString().replace(/\D/g, ''); // Eliminar caracteres no numéricos.

        // Invierte el RUT, agrupa de a tres y luego vuelve a invertir
        const rutReversed = rut?.split('').reverse().join('');
        const rutFormateado = rutReversed?.match(/.{1,3}/g).join('.').split('').reverse().join('');

        return `${rutFormateado}-${digitoVerificador}`;
    },
    formatPercent(number) {
        if (typeof number !== 'number' || isNaN(number)) {
            return ''; // Devuelve una cadena vacía si el valor no es un número válido
        }
        const percentage = number / 100;
        return new Intl.NumberFormat('es-ES', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(percentage);
    }
}



