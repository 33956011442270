import { useState } from 'react';
import TableContainer from '../../shared/TableContainer';
export const TablaFactNoCedDemo = ({
    FactNoCed,
    isLoading
}) => {
    console.log(FactNoCed)
    // const facturasFilt = []
    // if (FactNoCed.length > 0) {
    //     FactNoCed.forEach((datos) => {
    //         if (datos.cedido === 'no cedido') {
    //             facturasFilt.push(datos);
    //         }
    //     });
    // }

    // Filtrar las facturas que no están cedidas y cuyo estado sea distinto a 5
    const facturasFilt = FactNoCed
    // .sort((a, b) => {
    //     // Convertir las fechas a formato ISO (YYYY-MM-DD) para comparación precisa
    //     const dateA = new Date(a.fechaEmision);
    //     const dateB = new Date(b.fechaEmision);

    //     // Ordenar fechas en orden descendente
    //     return dateB - dateA;  // Asegura que la más reciente (mayor) esté primero
    // });
    .filter(datos => datos.cedido === 'no cedido' && datos.estado !== 5)
    .sort((a, b) => {
        // Convertir las fechas a formato ISO (YYYY-MM-DD) para comparación precisa
        const dateA = new Date(a.fechaEmision);
        const dateB = new Date(b.fechaEmision);

        // Ordenar fechas en orden descendente
        return dateB - dateA;  // Asegura que la más reciente (mayor) esté primero
    });

    const facturasTop5 = facturasFilt.slice(0, 5);


    console.log(facturasFilt)
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    let isPagination = false
    const pagSiz = 5
    const columns = [
        {
            accessor: 'fechaEmision', //access nested data with dot notation
            Header: 'Fecha Emis.',
            maxSize: 100,
            formato: 'fecha',
            headerPost: 'center',
        },
        {
            accessor: 'rutDeudor',
            Header: 'RUT',
            maxSize: 100,

            formato: 'rut',
        },
        {
            accessor: 'nomDeudor', //normal accessor
            Header: 'Nombre',
            minSize: 400,
            maxSize: 800,
            formato: 'string',
        },
        {
            accessor: 'nroDocto',
            Header: 'Numero',
            maxSize: 100,
            final: true
        },
        {
            accessor: 'montoTotal',
            Header: 'Monto',
            maxSize: 100,
            headerPost: 'center',
            formato: 'moneda',
            final: true
        },
    ]
    return (
        <div className='h-50'>
            <TableContainer
                columns={columns}
                data={facturasTop5}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={pagSiz}
                isPagination={isPagination}
                isLoading={isLoading}
                filterable={false}
                iscustomPageSizeOptions={false}
                tableClass="TbXs align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
                sortName='fechaEmision'
                secondarySortName='montoTotal'
                sortOrder='asc'
                pageIndex={pageIndex} // Pasar pageIndex
                setPageIndex={setPageIndex} // Pasar setPageIndex
            />
        </div>

    )
}


