import TableContainer from '../../shared/TableContainer';
import { useState } from 'react';


export const TablaTgrConveniosDemo = ({
    DetConvenios,
    CuotConvenios,
    isLoading
}) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual

    const hoy = new Date(); // Obtiene la fecha actual
    function agregarInfoCuotas(array1, array2) {
        return array1.map(convenio => {
            let cuotasVigentes = 0, montoVigente = 0;
            let cuotasPagadas = 0, montoPagado = 0;
            let cuotasMorosas = 0, montoMoroso = 0;
            const hoy = new Date();
            // Filtrar cuotas del convenio actual y que tienen un monto mayor que 0
            const cuotas = array2.filter(cuota => cuota.resolucion === convenio.resolucion && cuota.monto > 0);
            // Procesar cada cuota válida
            cuotas.forEach(cuota => {
                if (cuota.estadoPago) {
                    cuotasPagadas++;
                    montoPagado += cuota.monto;
                } else {
                    const fechaVence = new Date(cuota.fechaVence);
                    if (fechaVence < hoy) {
                        cuotasMorosas++;
                        montoMoroso += cuota.monto;
                    } else {
                        cuotasVigentes++;
                        montoVigente += cuota.monto;
                    }
                }
            });
            // Devolver convenio con la información agregada
            return {
                ...convenio,
                cuotasVigentes,
                montoVigente,
                cuotasPagadas,
                montoPagado,
                cuotasMorosas,
                montoMoroso
            };
        }).filter(convenio => convenio.montoVigente > 0 || convenio.montoPagado > 0 || convenio.montoMoroso > 0);
    }
    // Ejemplo de uso de la función
    const resultado = agregarInfoCuotas(DetConvenios, CuotConvenios);
    let isPagination = true
    const pagSiz = 10
    if (DetConvenios.length < pagSiz) {
        isPagination = false
    }
    const columns = [
        {
            Header: 'Resolución',
            headerPost: 'center',
            columns: [
                {
                    accessor: 'formulario', //access nested data with dot notation
                    Header: 'Formulario',
                    headerPost: 'center',
                    formato: 'centro',
                },
                {
                    accessor: 'folio',
                    Header: 'Folio',
                    headerPost: 'center',
                    formato: 'centro',
                },
                {
                    accessor: 'resolucion',
                    Header: 'Numero',
                    headerPost: 'center',
                    formato: 'centro',
                },
                {
                    accessor: 'fechaVence',
                    Header: 'Fecha',
                    formato: 'fecha',
                    headerPost: 'center',

                },
                {
                    accessor: 'neto',
                    Header: 'Saldo Capital',
                    headerPost: 'center',
                    formato: 'moneda',
                    final: true,
                    padd: '1em'
                }
            ]
        },
        {
            Header: 'Pagado',
            headerPost: 'center',

            columns: [
                {
                    accessor: 'cuotasPagadas', //normal accessorKey
                    Header: 'Cuotas',
                    headerPost: 'center',
                    formato: 'centro',
                    personalStyle: {
                        backgroundColor: '#D7F5D9', // Rosa claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    }
                },
                {
                    accessor: 'montoPagado', //normal accessorKey
                    Header: 'Monto',
                    headerPost: 'center',
                    formato: 'moneda',
                    final: true,
                    padd: '1em',
                    personalStyle: {
                        backgroundColor: '#D7F5D9', // Rosa claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    }
                },
            ]
        },
        {
            Header: 'Vigente',
            headerPost: 'center',
            columns: [
                {
                    accessor: 'cuotasVigentes', //normal accessorKey
                    Header: 'Cuotas',
                    headerPost: 'center',
                    formato: 'centro',
                    personalStyle: {
                        backgroundColor: '#f4ddd2', // Verde claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    }
                },
                {
                    accessor: 'montoVigente', //normal accessorKey
                    Header: 'Monto',
                    headerPost: 'center',
                    formato: 'moneda',
                    final: true,
                    padd: '1em',
                    personalStyle: {
                        backgroundColor: '#f4ddd2', // Verde claro por ejemplo
                        // backgroundColor: '#F5ECD7', // Verde claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    }
                },
            ]
        },
        {
            Header: 'Moroso',
            headerPost: 'center',
            columns: [
                {
                    accessor: 'cuotasMorosas', //normal accessorKey
                    Header: 'Cuotas',
                    headerPost: 'center',
                    formato: 'centro',
                    personalStyle: {
                        backgroundColor: '#e5a294', // color de fondo
                        color: '#000000', // color del texto
                        borderRight: '1px solid #E0E0E0' // borde derecho
                    }
                },
                {
                    accessor: 'montoMoroso', //normal accessorKey
                    Header: 'Monto',
                    headerPost: 'center',
                    formato: 'moneda',
                    final: true,
                    padd: '1em',
                    personalStyle: {
                        backgroundColor: '#e5a294', // color de fondo
                        color: '#000000', // color del texto
                        borderRight: '1px solid #E0E0E0' // borde derecho
                    },
                },
            ]
        },
    ]

    return <TableContainer
        columns={columns}
        data={resultado}
        isAddOptions={false}
        customPageSize={pagSiz}
        isPagination={isPagination}
        filterable={false}
        iscustomPageSizeOptions={false}
        tableClass="TbXs align-middle table-nowrap table-check"
        theadClass="table-light"
        pagination="pagination pagination-rounded justify-content-end mb-2"
        isLoading={isLoading}
        // isTotal
        sortName='fechaVence'
        sortOrder='asc'
        pageIndex={pageIndex} // Pasar pageIndex
        setPageIndex={setPageIndex} // Pasar setPageIndex
    />;
}

