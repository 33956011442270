import { useState, useEffect } from 'react';
import { comercial } from '../../helpers/comercial';
import { TablaComercialDetalleVentCompCrDetalleDemo } from '../tablas/TablaComercialDetalleVentCompCrDetalleDemo';
import { VentasVsCompras } from '../comercial/grafico/VentasVsCompras';

export const ModalDetalleCyV = ({
    row,
    mp
}) => {
    const [DetVentCompCr, setDetVentCompCr] = useState([]);
    let montoMin = 0
    const notPropagation = (e) => {
        e.stopPropagation();
    }
    useEffect(() => {
        comercial.getComprasVentasClienteDEudorProveedor(row.rgId, row.idDeudor, mp, montoMin).then((r) => {
            if (r.status === 200) {
                setDetVentCompCr(r.data.listadoClienteDeudorProveedor)
                console.log(r.data.listadoClienteDeudorProveedor)
            } else {
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [row])
    return (
        <div class="modal fade" id="ModalDetalleCyV" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalToggleLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="titGraf fs-5" id="ModalDetalleCyVLabel">{row.nomDeu}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div>
                            <VentasVsCompras
                            mp = { mp }
                            data={DetVentCompCr}
                            />
                        </div>
                        <div className="table-responsive">
                            <TablaComercialDetalleVentCompCrDetalleDemo
                                DetVentCompCr={DetVentCompCr}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
