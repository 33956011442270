import { dataApi } from "../api/dataApi";

export const comercial = {
    getFacturasNoCedidas: function(id,rgIdFact){
        return dataApi.get(`${process.env.REACT_APP_COMERCIAL_FACTURAS_PARA_GESTION}${id}/${rgIdFact}`)
    },
    getVentasComprasCruzadasCliPeriodo: function (id ,periodo, rgIdFact) {
        return dataApi.get(`${process.env.REACT_APP_COMERCIAL_VENTAS_COMPRAS_CRUZADAS_CLI_PERIODO}${id}/${periodo}/${rgIdFact}`)
    },
    getComprasVentasClienteDEudorProveedor: function (rgIdCliente ,rgIdDeudor, periodo, montoMin) {
        return dataApi.get(`${process.env.REACT_APP_COMERCIAL_COMPRAS_VENTAS_CLIENTE_DEUDOR_PROVEEDOR}${rgIdCliente}/${rgIdDeudor}/${periodo}/${montoMin}`)
    }
    
};
