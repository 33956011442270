import { GraficoPie } from "../resumen/graficosPie/GraficoPie"
export const CardGPCs = ({
    ResCsrNoBancDet,
    Periodo
}) => {
    const paleta = [
        '#0D2E82',
        '#74D3E8',
        '#657AB0',
        '#EBC3B0',
        '#B55C48',
        '#A2AFCF',
        '#BC9C8C',
        '#D9D9D9',
    ]
    const porcentaje = []
    const monto = []
    let suma = 0
    let sumaN = 0
    let porcientBancario = 0
    let sumaB = 0
    let porcient = 0
    let counter = 0
    let sumaT = 0
    let sumaTB = 0
    ResCsrNoBancDet.forEach((numeros) => {
            monto.push(numeros.monto)
            suma += numeros.monto;
    });
    ResCsrNoBancDet.forEach((numeros) => {
        if (numeros.bancario) {
            sumaB += numeros.monto;
        } else {
            monto.push(numeros.monto)
            sumaN += numeros.monto;
        }
    });
    ResCsrNoBancDet.forEach((numeros) => {
            porcentaje.push((numeros.monto * 100 / suma).toFixed(1))
            numeros.porcentaje = (numeros.monto * 100 / suma).toFixed(1)
    });


    sumaT = suma + sumaB

    porcientBancario = sumaB * 100 / sumaT
    porcient = suma * 100 / sumaT
    porcientBancario = porcientBancario.toFixed(1)
    porcient = porcient.toFixed(1)
    sumaB = sumaB.toLocaleString("es-CL", { style: "currency", currency: "CLP" })
    suma = suma.toLocaleString("es-CL", { style: "currency", currency: "CLP" })
    const idSelector = () => {
        return 'modal'
    }
    return (
        <div className="card cardDot"
        >
            <div className="row  g-3">
                <p className='col-12 titGraf pt-3 text-center' style={{ fontSize: 20 }}>Cesionarios</p>
                <div className="col-4 ps-5 mb-5">
                    <GraficoPie
                        monto={monto}
                        porcentaje={porcentaje}
                    />
                </div>
                <div className="col-7">
                    <table className="table TbXs">
                        <thead>
                            <tr>
                                <th></th>
                                <th data-bs-toggle="tooltip" title="Porcentaje del total">Porcentaje</th>
                                <th style={{ width: '30%' }} data-bs-toggle="tooltip" title="Nombre completo del deudor">Nombre del Deudor</th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">Monto {Periodo} meses</th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">30 Días </th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">60 Días </th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">90 Días </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ResCsrNoBancDet.map((resvd, index) => (
                                <tr key={index}>
                                    <td style={{ width: '5%' }}>
                                        <div className="cuadradoDetalle mt-1 me-1" style={{ background: paleta[index % paleta.length] }}></div>
                                    </td>
                                    <td className="right-aligned  pe-4" style={{ width: '10%' }}>
                                        {resvd.porcentaje}%
                                    </td>
                                    <td style={{ width: '55%' }}>{resvd.nombreCesionario}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.monto.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.rango0_30.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.rango31_60.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.rango61_90.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="h5 pt-5">
                        Total cesiones no bancario {sumaN.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}
                    </div>
                    <div className="h5 pt-1">
                        Total cesiones bancario {sumaB.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}
                    </div>
                </div>
            </div>
        </div>
    )
}