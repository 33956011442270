import { useState } from 'react';
import TableContainer from '../../../shared/TableContainer';

export const BienRaizSII = ({
  data
}) => {
  const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual

  let isPagination = true
  const pagSiz = 5
  const isLoading = false
  if (data.length < pagSiz) {
    isPagination = false
  }

  const columns = [
    {
      accessor: 'brRol',
      Header: 'ROL',
      headerPost: 'center',
      inicio: true,
      formato: 'numero',

    },
    {
      accessor: 'brDestino',
      Header: 'Destino',
      headerPost: 'center',
      formato: 'string',
      inicio: true,
    },
    {
      accessor: 'brComuna',
      Header: 'Comuna',
      headerPost: 'center',
      formato: 'string',

    },
    {
      accessor: 'brDireccion',
      Header: 'Dirección',
      headerPost: 'center',
      formato: 'string',
      final: true
    }
  ];
  
  return <TableContainer
    columns={columns}
    data={data}
    isGlobalFilter={false}
    isAddOptions={false}
    customPageSize={20}
    isPagination={true}
    filterable={false}
    iscustomPageSizeOptions={false}
    tableClass="TbXs align-middle table-nowrap table-check"
    theadClass="table-light"
    pagination="pagination pagination-rounded justify-content-end mb-2"
    isLoading={isLoading}
    pageIndex={pageIndex} // Pasar pageIndex
    setPageIndex={setPageIndex} // Pasar setPageIndex
  />;
}




