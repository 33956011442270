import { useState } from 'react';
import TableContainer from '../../shared/TableContainer';

export const TablaTgrCobranzaJudicialDemo = ({
    CobranzaJudicial,
    isLoading
}) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    let isPagination = true
    const pagSiz = 5
    const columns = [
        {
            accessor: 'nroExpediente',
            Header: 'N°',
            formato: 'centro',
            headerPost: 'center',
            inicio: true
        },
        {
            accessor: 'agno',
            Header: 'Año',
            formato: 'centro',
            headerPost: 'center',
            final: true
        },
        {
            accessor: 'comuna',
            Header: 'Comuna',
            formato: 'string',
            headerPost: 'left',
            inicio: true
        },
        {
            accessor: 'etapa',
            Header: 'Etapa',
            formato: 'string',
            headerPost: 'left',
            totalTitulo: true,
            icono: true,
            Cell: ({ row }) => {
                const backgroundColor = row.original.etapa === 'Embargo' ? '#B55C48' : '#000';
                const fontWeightBold = row.original.etapa === 'Embargo' ? 'bold' : 0;
                return (
                    <>
                        <span style={{ color: backgroundColor, fontWeight: fontWeightBold }}>
                            {row.original.etapa}
                        </span>
                    </>
                );
            }
        },
        {
            accessor: 'totalLiquidado',
            Header: 'Total Liquidado',
            formato: 'moneda',
            headerPost: 'right',
            final: true,
            total: true
        },
    ];
    return <TableContainer
        columns={columns}
        data={CobranzaJudicial}
        isAddOptions={false}
        customPageSize={pagSiz}
        isPagination={isPagination}
        filterable={false}
        iscustomPageSizeOptions={false}
        tableClass="TbXs align-middle table-nowrap table-check"
        theadClass="table-light"
        pagination="pagination pagination-rounded justify-content-end mb-2"
        isLoading={isLoading}
        isTotal
        sortName='agno'
        sortOrder='asc'
        pageIndex={pageIndex} // Pasar pageIndex
        setPageIndex={setPageIndex} // Pasar setPageIndex
    />;
}

