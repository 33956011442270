import TableContainer from '../../shared/TableContainer';
import ShieldX from '../../shared/icon/shieldX.svg'
import ShieldW from '../../shared/icon/shieldW.svg'
import ShieldOk from '../../shared/icon/shieldOk.svg'
import { useContext, useState } from 'react';
import { IdContext } from '../../context/IdContext';
import { useNavigate } from 'react-router-dom'
import { ModalBoletinConcursalNew } from '../modal/ModalBoletinConcursalNew';

export const TablaInicioDemo = ({
  Empresas,
  isLoading
}) => {
  console.log(Empresas)
  const navigate = useNavigate();
  const { setIdProvider } = useContext(IdContext);
  const { setIdNombre } = useContext(IdContext);
  const { setIdRut } = useContext(IdContext);
  const [modal, setModal] = useState(false);
  const [consulta, setConsulta] = useState(null);
  const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  let isPagination = true
  const pagSiz = 20
  if (Empresas?.length < pagSiz) {
    isPagination = false
  }
  const idSelector = (row, fixId) => {
    if (fixId) {
      setIdProvider(row.idEmpresa)
      setConsulta(row.idEmpresa);
      toggle();
      return console.log('abrir modal eliminacion del idSelector:', row.idEmpresa);
    }
    if (!fixId && row.activo) {
      setIdProvider(row.idEmpresa)
      setIdNombre(row.nombre)
      setIdRut(row.rutEmpresa)
      return navigate(`/resumen`);
    }
    if (!fixId && !row.activo)
      return 'modal'
  }
  const columns = [
    {
      accessor: 'rutEmpresa', //access nested data with dot notation
      Header: 'Rut',
      formato: 'rut',
      headerPost: 'center',
      headerSlice: true,
      inicio: true,
      padd: '-1.5em'
    },
    {
      accessor: 'nombre',
      Header: 'Cliente',
      formato: 'string',
      minWidth:'40em'
    },
    {
      accessor: 'fechaIngreso', //normal accessor
      Header: 'Fecha Ingreso',
      formato: 'fecha',
      headerPost: 'center',
      headerSlice: true,
    },
    {
      accessor: 'credencialActiva', //normal accessor
      Header: 'Cliente Activo',
      formato: 'string',
      inicio: true,
      headerSlice: true,
      icono: true,
      Cell: ({ row }) => {
        return (
          <>
            {!row.original.credencialActiva
              ?
              <span
                style={{ marginLeft: '1.5em', cursor: row.original.isLoading ? 'not-allowed' : 'pointer' }}
              >
                <img src={ShieldX} alt="" width="20" height="20" /> {/* Espacio entre el icono y el texto */}
              </span>
              :
              <span
                onClick={() => !row.original.isLoading && idSelector(row.original, false)}
                style={{ marginLeft: '1.5em', cursor: row.original.isLoading ? 'not-allowed' : 'pointer' }}>
                <img className='' src={ShieldOk}
                  alt="" width="20" height="20" />
              </span>
            }
          </>
        )
      }
    },
    {
      accessor: 'existeCobJudicial', //normal accessor
      Header: 'TGR',
      formato: 'string',
      inicio: true,
      headerSlice: true,
      icono: true,
      Cell: ({ row }) => {
        return (
          <>
            {row.original.cuotaMorosa || row.original.etapaEmbargo ||  row.original.ivaPostergado === 3 ||  row.original.ivaDeclarado === 0    ///row.original.deudaFiscalMorosa || aun falta definir
              ?
              <span
                onClick={() => !row.original.isLoading && idSelector(row.original, false)}
                style={{ marginLeft: '0.8em', cursor: row.original.isLoading ? 'not-allowed' : 'pointer' }}
              >
                <img src={ShieldX} alt="" width="20" height="20" title="Cliente tiene Embargo o IVA postergado o cuotas morosas." /> {/* Espacio entre el icono y el texto */}
              </span>
              :
              row.original.existeConvenio && !row.original.cuotaMorosa || row.original.ivaPostergado >= 1 && row.original.ivaPostergado <= 2 ||row.original.ivaDeclarado >= 0 && row.original.ivaDeclarado <= 1?
                <span
                  onClick={() => !row.original.isLoading && idSelector(row.original, false)}
                  style={{ marginLeft: '0.8em', cursor: row.original.isLoading ? 'not-allowed' : 'pointer' }}>
                  <img className='' src={ShieldW}
                    alt="" width="20" height="20" title="El cliente tiene convenios activos" />
                </span>
                :
                <span
                  onClick={() => !row.original.isLoading && idSelector(row.original, false)}
                  style={{ marginLeft: '0.8em', cursor: row.original.isLoading ? 'not-allowed' : 'pointer' }}>
                  <img className='' src={ShieldOk}
                    alt="" width="20" height="20" />
                </span>
            }
          </>
        )
      }
    },
    {
      accessor: 'boletinConcursal', //normal accessor
      Header: 'Boletín Concursal',
      inicio: true,
      headerSlice: true,
      icono: true,
      Cell: ({ row }) => {
        // style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', position: 'relative' }}   
        return (
          <>
            {row.original.boletinConcursal
              ?

              <span onClick={() => !row.original.isLoading && idSelector(row.original, true)}
                style={{ marginLeft: '2em', cursor: row.original.isLoading ? 'not-allowed' : 'pointer' }}
              >
                <img src={ShieldX} alt="" width="20" height="20" /> {/* Espacio entre el icono y el texto */}
              </span>
              :
              <span onClick={() => !row.original.isLoading && idSelector(row.original, false)} style={{ marginLeft: '2em', cursor: row.original.isLoading ? 'not-allowed' : 'pointer' }}>
                <img className='' src={ShieldOk}
                  alt="" width="20" height="20" />
              </span>
            }
          </>
        )
      }
    },
    {
      accessor: 'fhCredencialInvalida', //normal accessor
      Header: 'Fecha última actualización',
      formato: 'fecha',
      headerPost: 'center',
      padd: '1em',
      headerSlice: true
    },
  ]
  return (
    <>
      <TableContainer
        columns={columns}
        data={Empresas}
        isGlobalFilter={true}
        filterAll={true}
        isAddOptions={false}
        customPageSize={pagSiz}
        isPagination={isPagination}
        filterable={false}
        iscustomPageSizeOptions={false}
        tableClass="TbXs align-middle table-nowrap table-check"
        theadClass="table-light"
        pagination="pagination pagination-rounded justify-content-end mb-2"
        isLoading={isLoading}
        isTotal={false}
        sortName='nombre'
        sortOrder='desc'
        selectorRow={idSelector}
        rowDisabled={'isLoading'}
        pageIndex={pageIndex} // Pasar pageIndex
        setPageIndex={setPageIndex} // Pasar setPageIndex
      />
      <ModalBoletinConcursalNew
        modal={modal}
        toggle={() => setModal(!modal)}
        data={consulta}
      />
    </>
  )
}