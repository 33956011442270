import React, { useState } from 'react';
import { NavLink } from 'reactstrap';
import { Card, CardBody, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { Formulario29SII } from './tabs/Formulario29SII';
import { Formulario22SII } from './tabs/Formulario22SII';
import { SituacionTrubutariaSII } from './tabs/SituacionTrubutariaSII';
import { ActividadEconomicaSII } from './tabs/ActividadEconomicaSII';
import { ReactComponent as ArticleIcon } from '../../shared/icon/newIcons/article.svg';
import { ReactComponent as AssignmentIcon } from '../../shared/icon/newIcons/assignment.svg';
import { ReactComponent as EnterpriseIcon } from '../../shared/icon/newIcons/enterprise.svg';
import { ReactComponent as AccountBalanceIcon } from '../../shared/icon/newIcons/account_balance.svg';

export const TabsSII2 = ({
  F29,
  F22,
  SituacionTributaria,
  actividadesEconomicas
}) => {

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [customTextTab, setcustomTextTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
    if (customTextTab !== tab) {
      setcustomTextTab(tab);
    }
  };
  const getNavLinkColor = (isActive) => {
    if (!isActive) {
      return 'transparent';
    }
    return "#F0F2F7";
  };
  const getNavTextColor = (isActive) => {
    if (!isActive) {
      return '#999';
    }
    return "#000000";
  };
  const getNavIconColor = (tab) => {
    if (tab !== customTextTab) {
      return '#999';
    }
    switch (tab) {
      case "1": return "#267107";
      case "2": return "#0D2F82";
      case "3": return "#B55C48";
      case "4": return "#657AB0";
      default: return "#000000";
    }
  };
  const renderIcon = (IconComponent, tab) => (
    <IconComponent className='mb-1' fill={getNavIconColor(tab)} width="22" height="22" />
  );
  return (
    <React.Fragment>
      <Card>
        <CardBody className='p-1'>
          <Row className='pt-2'>
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "1")
                  }}
                  className={classnames({ active: customActiveTab === "1" })}
                  onClick={() => { toggleCustom("1"); }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "1") }}>
                    {renderIcon(ArticleIcon, "1")} Formulario 29
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "2")
                  }}
                  className={classnames({ active: customActiveTab === "2" })}
                  onClick={() => { toggleCustom("2"); }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "2") }}>
                    {renderIcon(AssignmentIcon, "2")} Formulario 22
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "3")
                  }}
                  className={classnames({ active: customActiveTab === "3" })}
                  onClick={() => { toggleCustom("3"); }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "3") }}>
                    {renderIcon(EnterpriseIcon, "3")} Actividad Economica
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "4")
                  }}
                  className={classnames({ active: customActiveTab === "4" })}
                  onClick={() => { toggleCustom("4"); }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "4") }}>
                    {renderIcon(AccountBalanceIcon, "4")} Situacion Tributaria
                  </span>
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent
              activeTab={customActiveTab}
              className="text-muted p-0"
            >
              <TabPane tabId="1" style={{ backgroundColor: getNavLinkColor(customActiveTab) }}>
                <Formulario29SII
                  F29={F29}
                />
              </TabPane>
              <TabPane tabId="2" style={{ backgroundColor: getNavLinkColor(customActiveTab) }} className='pt-2'>
                <Formulario22SII
                  data={F22}
                />
              </TabPane>
              <TabPane tabId="3" style={{ backgroundColor: getNavLinkColor(customActiveTab) }}>
                <ActividadEconomicaSII
                  actividadesEconomicas={actividadesEconomicas}
                />
              </TabPane>
              <TabPane tabId="4" style={{ backgroundColor: getNavLinkColor(customActiveTab) }} className='pt-2'>
                <SituacionTrubutariaSII
                  SituacionTributaria={SituacionTributaria}
                />
              </TabPane>
            </TabContent>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};