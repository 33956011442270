import { formatVal } from "../../helpers/formatVal"
import { NotData } from "../../shared/alert/notData";

export const TablaSiiBalance = ({
    Balance,
    isLoadingMiSII,
    isLoading22
}) => {
    const codesToCheck = ['102', '122', '123'];
    const hasCodes = Balance.some(item => codesToCheck.includes(item.codigo));
    let data = []
    if (Balance.length > 0) {
        data = Balance
    }
    const periodos = []
    const ActCorr = []
    const actNoCorriente = []
    const totalActivo = []
    const pasivo = []
    const patrimFinanciero = []
    const TotPasPatr = []
    let datosPorAnio = {};
    data.forEach((indice) => {
        // Verificar si el índice tiene uno de los códigos que necesitamos
        if (codesToCheck.includes(indice.codigo)) {
            // Verificar si el periodo existe y no está repetido en periodos
            if (indice.periodo && !periodos.includes(indice.periodo)) {
                // Agregar el periodo al array de periodos
                periodos.push(indice.periodo);
                // Crear una nueva entrada en datosPorAnio para el año actual
                datosPorAnio[indice.periodo] = [];
            }
            // Almacenar los datos correspondientes al año actual
            datosPorAnio[indice.periodo].push(indice);
        }
    });
    const years = Object.keys(datosPorAnio);

    years.forEach((year) => {
        let totalPasivoPatrimonio = 0;
        let valor122 = 0;
        let valor123 = 0;
        let valor102 = 0;
        // Verificar si hay datos para el año actual
        if (datosPorAnio[year]) {
            datosPorAnio[year].forEach((code) => {
                if (code.codigo === '102') {
                    valor102 = code.valor;
                    // ActCorr.push({ 'monto': formatVal.formatValueCLP(code.valor), 'valor': code.valor });
                }
                if (code.codigo === '122') {
                    valor122 = code.valor;
                    totalActivo.push({ 'monto': formatVal.formatValueCLP(code.valor), 'valor': code.valor });
                }
                if (code.codigo === '123') {
                    valor123 = code.valor;
                    pasivo.push({ 'monto': formatVal.formatValueCLP(code.valor), 'valor': code.valor });
                    totalPasivoPatrimonio += parseFloat(code.valor);
                }
            });
        } else {
            patrimFinanciero.push({ 'monto': '', 'valor': 0 });
        }
        ActCorr.push(valor102 !== 0 ? { 'monto': formatVal.formatValueCLP(valor102), 'valor': valor102 } : { 'monto': '', 'valor': 0 })
        let actNoCorr = valor122 - valor102;
        let actNoCorr2 = valor122 - valor123;
        if (actNoCorr > 0) {
            actNoCorriente.push({ 'monto': formatVal.formatValueCLP(actNoCorr), 'valor': valor122 });
        } else {
            actNoCorriente.push({ 'monto': '', 'valor': valor122 });
        }
        if (actNoCorr2 !== 0) {
            patrimFinanciero.push({ 'monto': formatVal.formatValueCLP(actNoCorr2), 'valor': actNoCorr2 });
        } else {
            patrimFinanciero.push({ 'monto': '', 'valor': valor122 });
        }
        if (totalPasivoPatrimonio > 0) {
            TotPasPatr.push({ 'monto': formatVal.formatValueCLP(totalPasivoPatrimonio), 'valor': totalPasivoPatrimonio });
        }
        const patrimonioPresente = verificarPatrimonioPresente(datosPorAnio, year);
    });

    function verificarPatrimonioPresente(datosPorAnio, year) {
        const datosAnio = datosPorAnio[year];
        const patrimonioPresente = datosAnio.some(item => item.codigo === '843');
        return patrimonioPresente;
    }
    function calcularVariacion(array) {
        // Si hay menos de 2 años en periodos, no calculamos variaciones
        if (!periodos.length > 1) {
            return [];
        }
        const variaciones = [];
        for (let i = 1; i < array.length; i++) {
            // Check if both 'valor' and 'monto' are not 0
            if (array[i].valor === 0 || array[i - 1].valor === 0 || 
                array[i].monto.trim() === '$  0' || array[i - 1].monto.trim() === '$  0') {
                continue; // Skip the iteration if either valor or monto is 0
            }
            const variacion = (((array[i].valor - array[i - 1].valor) * 100) / array[i - 1].valor);
            variaciones.push({ 'monto': formatVal.formatPercent(variacion), 'valor': variacion });
        }
        return variaciones;
    }
    const ActCorrVariacion = calcularVariacion(ActCorr?.slice(-2));
    const actNoCorrienteVariacion = calcularVariacion(actNoCorriente?.slice(-2));
    const totalActivoVariacion = calcularVariacion(totalActivo?.slice(-2));
    const pasivoVariacion = calcularVariacion(pasivo?.slice(-2));
    const patrimFinancieroVariacion = calcularVariacion(patrimFinanciero?.slice(-2));
    return (
        <div className="table-container" style={{ position: 'relative' }}>
            {!hasCodes && (
                <div style={{
                    position: 'absolute',
                    top: '75%',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 10,
                    textAlign: 'center',
                    fontSize: '1.5em'
                }}>
                    <NotData
                        msg='Sin información de SII.' />
                </div>
            )}
            <table className="table table-hover tableStandard TblBlnc">
                <thead className="table-info">
                    <tr>
                        <th scope="col ">Balance <span className='titGraf22'>(Miles de pesos)</span></th>
                        {periodos.reverse().map((periodo, index) => (
                            <th className="text-end" key={index}>
                                {periodo - 1}
                            </th>
                        ))}
                        {periodos.length > 1 ?
                            <th className="text-end">Variación</th>
                            :
                            ''
                        }

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> Capital Efectivo </td>
                        {ActCorr.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 ?
                            <td className="text-end">
                                {ActCorrVariacion.length > 0 ? ActCorrVariacion[ActCorrVariacion.length - 1].monto : ''}
                            </td>
                            :
                            ''
                        }
                    </tr>
                    <tr>
                        <td> Otros Activos </td>
                        {actNoCorriente.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 ?
                            <td className="text-end">
                                {actNoCorrienteVariacion.length > 0 ? actNoCorrienteVariacion[actNoCorrienteVariacion.length - 1].monto : ''}
                            </td>
                            :
                            ''
                        }
                    </tr>
                    <tr>
                        <td><b> Total Activo </b></td>
                        {totalActivo.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                <b>{periodo.monto}</b>
                            </td>
                        ))}
                        {periodos.length > 1 ?
                            <td className="text-end">
                                <b>{totalActivoVariacion.length > 0 ? totalActivoVariacion[totalActivoVariacion.length - 1].monto : ''}</b>
                            </td>
                            :
                            ''
                        }
                    </tr>
                    <tr>
                        <td> Pasivo </td>
                        {pasivo.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 ?
                            <td className="text-end">
                                {pasivoVariacion.length > 0 ? pasivoVariacion[pasivoVariacion.length - 1].monto : ''}
                            </td>
                            :
                            ''
                        }
                    </tr>
                    <tr>
                        <td> Patrimonio </td>
                        {patrimFinanciero.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 ?
                            <td className="text-end">
                                {patrimFinancieroVariacion.length > 0 ? patrimFinancieroVariacion[patrimFinancieroVariacion.length - 1].monto : ''}
                            </td>
                            :
                            ''
                        }
                    </tr>
                </tbody>
            </table>
        </div>

    )
}