


import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Chevron_right from '../../shared/icon/newIcons/chevron_right.svg';
import { IdContext } from '../../context/IdContext';
import { Padding } from '@mui/icons-material';
import EnvironmentBanner from './EnvironmentBanner';

const SideBar = () => {
  let isGetId = false
  const idProvider = useContext(IdContext);
  const id = idProvider.idProvider
  if (id === 0) {
    isGetId = 'disabled'
  } else {
    isGetId = 'active'
  }

  const links = [
    { label: 'Inicio', path: 'inicio' },
    { label: 'Resumen', path: 'resumen' },
    { label: 'TGR', path: 'tgr' },
    { label: 'SII', path: 'sii' },
    { label: 'Comercial', path: 'comercial' },
    { label: 'Gestión de Negocios', path: 'gestion' }
  ];
  return (
    <div className="row p-3 navPrin">
      <ul className="nav flex-column mb-auto">
        {links.map(({ label, path }, index) => (
          <NavLink
            key={index}
            to={isGetId === 'disabled' && label !== 'Inicio' && label !== 'Gestión de Negocios' ? '#' : `/${path}`}
            className={({ isActive }) => `nav-link ${isActive ? 'active ms-1' : ''} ${isGetId}`}
            style={({ isActive }) => ({
              color: isGetId === 'disabled' && label !== 'Inicio' && label !== 'Gestión de Negocios' ? '#1A1E21' : (isActive ? 'white' : 'white'),
              pointerEvents: isGetId === 'disabled' && label !== 'Inicio' && label !== 'Gestión de Negocios' ? 'none' : 'auto',
              opacity: isGetId === 'disabled' && label !== 'Inicio' && label !== 'Gestión de Negocios' ? '0.5' : '1'
            })}
          >
            
             {({ isActive }) => 
              isActive  && isGetId !== 'disabled' ? 
              // (isActive && (path === 'inicio' || path === 'gestion')) && !isGetId !== 'disabled' ? 
              <span><img className='' style={{paddingBottom: '0.1em'}} src={Chevron_right} alt="" />{label}</span> 
              : 
              path === 'inicio' && isActive|| path === 'gestion' && isActive?
              <span><img className='' style={{paddingBottom: '0.1em'}} src={Chevron_right} alt="" />{label}</span> 
              :
              
              label}
          </NavLink>
        ))}
      </ul>
      <EnvironmentBanner/>
    </div>
  );
 }

export default SideBar;