import { useEffect, useState } from 'react'
import { Cesionarios } from '../resumen/graficosPie/Cesionarios'
import { NotData } from '../../shared/alert/notData'
export const Card6 = ({
  ResCsrNoBanc,
}) => {

  console.log(ResCsrNoBanc)
  const msg = 'No tenemos datos para cesiones x cesionario.'
  const paleta = [
    '#0D2E82',
    '#74D3E8',
    '#657AB0',
    '#EBC3B0',
    '#B55C48',
    '#A2AFCF',
    '#BC9C8C',
    '#D9D9D9',
  ]
  const porcentaje = []
  const monto = []
  let suma = 0
  let sumaN = 0
  let porcientBancario = 0
  let sumaB = 0
  let porcient = 0
  let counter = 0
  let sumaT = 0
  let sumaTB = 0
  ResCsrNoBanc.forEach((numeros) => {
    monto.push(numeros.monto)
    suma += numeros.monto;
  });
  ResCsrNoBanc.forEach((numeros) => {
    if (numeros.bancario) {
      sumaB += numeros.monto;
    } else {
      monto.push(numeros.monto)
      sumaN += numeros.monto;
    }
  });
  ResCsrNoBanc.forEach((numeros) => {
    porcentaje.push((numeros.monto * 100 / suma).toFixed(1))
    numeros.porcentaje = (numeros.monto * 100 / suma).toFixed(1)
  });
  porcientBancario = sumaB * 100 / suma
  porcient = sumaN * 100 / suma
  porcientBancario = porcientBancario.toFixed(1)
  porcient = porcient.toFixed(1)
  sumaB = sumaB.toLocaleString("es-CL", { style: "currency", currency: "CLP" })
  suma = suma.toLocaleString("es-CL", { style: "currency", currency: "CLP" })
  const idSelector = () => {
    return 'modal'
  }
  return (
    <>
      {ResCsrNoBanc.length > 0 ?
        <div className="card  mb-3" onClick={() => idSelector()}
          data-bs-toggle="modal" data-bs-target="#ModalDetalleGraficoCesiones">
          <div className="row">
            <div className="col-6 pb-3">
              <p className='titGraf ps-2 pt-1 '>Cesionarios</p>
            </div>
            <div className="col-6 d-flex pt-1">
              <p className='pe-2 titGraf ' style={{ color: '#979797' }}> <b>Bancario</b> - {porcientBancario}%</p>
              <p className='ps-2 titGraf' style={{ color: '#4163B9' }}> <b>No Bancario </b> - {porcient}%</p>
            </div>
            <hr />
            <div className="col-md-4 ps-5 py-2">
              <Cesionarios
                monto={ResCsrNoBanc.monto}
                porcentaje={porcentaje}
              />
            </div>
            <div className="col-md-8 align-self-center pb-3 ps-5">
                {ResCsrNoBanc.map(resvd => (
                  < div style={{
                    fontSize: "0.65em",
                  }}
                    key={resvd.idDeudor}
                  >
                    <div className="d-flex ">
                      <div className="cuadrado mt-1 me-3" style={{ background: paleta[counter] }} counter={counter += 1}></div>
                      {resvd.porcentaje}% {resvd.nombreCesionario}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        :
        <div className="card  mb-3">
          <div className="row">
            <div className="col-5 pb-3">
              <p className='titGraf ps-2 pt-1 '>Cesiones x Cesionario</p>
            </div>
            <hr />
            <div className="my-5 d-flex justify-content-center">
              <NotData msg={msg} />
            </div>
          </div>
        </div>
      }
    </>

  )
}