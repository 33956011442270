export const ModalDetalleSituacionTributaria = ({
    data
}) => {
    const formattedDate = new Date(data.stFecha).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
    const notPropagation = (e) => {
        e.stopPropagation();
    }

    return (

        <div className="modal fade" id="ModalDetalleSituacionTributaria" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content" style={{ width: 'auto', minWidth: '700px' }}>
                    <div className="modal-body">
                        <div className="text-center mt-2"><h5>{formattedDate}</h5></div>
                        <div className=" mx-5 mt-2 mb-5 col table-responsive ">
                            <div> <b>Situacion:</b>  <p>{data.stSituacion}</p></div>
                            <div> <b>Descripcion:</b>  <p>{data.stDescripcion}</p></div>
                            <div>  <b>Acciones:</b>  <p>{data.stAcciones}</p></div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}
