
import { useState } from 'react';
import TableContainer from '../../../shared/TableContainer';


export const ActividadEconomicaSII = ({
  actividadesEconomicas
}) => {
  const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
  let isPagination = true
  const isLoading = false
  const pagSiz = 5
  if (actividadesEconomicas.length < pagSiz) {
    isPagination = false
  }
  const columns = [
    {
      accessor: 'aeNombre',
      Header: 'Nombre',
      size: 160,
      formato: 'string'
    },
    {
      accessor: 'aeCodigo',
      Header: 'Codigo',
      size: 40,
    },
    {
      accessor: 'aeCategoriaTributaria',
      Header: 'Cat Tributaria',
      size: 40,
    },
    {
      accessor: 'aeAfectaIva',
      Header: 'AfectaIva',
      size: 40,
      formato: 'bool',
      formtTr: 'SI',
      formtFl: 'NO'
    },
  ];
  return <TableContainer
    columns={columns}
    data={actividadesEconomicas}
    isGlobalFilter={false}
    isAddOptions={false}
    customPageSize={20}
    isPagination={true}
    filterable={false}
    iscustomPageSizeOptions={false}
    tableClass="TbXs align-middle table-nowrap table-check"
    theadClass="table-light"
    pagination="pagination pagination-rounded justify-content-end mb-2"
    isLoading={isLoading}
    pageIndex={pageIndex} // Pasar pageIndex
    setPageIndex={setPageIndex} // Pasar setPageIndex
  />;
}




