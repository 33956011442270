import TableContainer from '../../shared/TableContainer';
import { useState } from 'react';


export const TablaTgrDeudaNoMorosaDemo = ({
    certificado,
    isLoading
}) => {
    console.log(certificado)
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    let isPagination = true
    const pagSiz = 10
    if (certificado.length < pagSiz) {
        isPagination = false
    }
        // Filtrar y transformar el array de certificados para mostrar solo los que no están vencidos
        const data = certificado
        .filter(item => !item.cdfVencida)
        .map(item => ({
            formulario: item.cdfFormulario,
            gtDescri: item.gtDescri,
            Tipo: item.cdfFormulario,
            folio: item.cdfFolio,
            fechaVence: item.cdfFechaVenc,
            neto: item.cdfDeudaNeta,
            Reajuste: item.cdfReajuste,
            Interes: item.cdfIntereses,
            Multa: item.cdfMultas,
            Total: item.cdfDeudaTotal
        }));
        const columns = [
            {
                accessor: 'formulario', //access nested data with dot notation
                Header: 'Formulario',
                headerPost: 'center',
                formato: 'centro',
                icono: true,
                Cell: ({ row }) => {
                    console.log(row.original.formulario);
                    return (
                        <div style={{ textAlign: 'center' }} title={row.original.gtDescri}>
                            {row.original.formulario}
                        </div>
                    );
                }
            },
            {
                accessor: 'Tipo',
                Header: 'tipo',
                headerPost: 'center',
                formato: 'centro',
            },
            {
                accessor: 'folio',
                Header: 'Folio',
                headerPost: 'center',
                formato: 'centro',
            },
            {
                accessor: 'fechaVence',
                Header: 'Fecha Vcto.',
                formato: 'fecha',
                headerPost: 'center',
                totalTitulo: true,
                totalTituloNombre: 'Total Deuda No Vencida',
            },
            {
                accessor: 'neto',
                Header: 'Deuda Neta',
                headerPost: 'right',
                formato: 'moneda',
                padd: '-0.2em',
                total: true,
                headerPaddR:'1em'             
            },
            {
                accessor: 'Reajuste',
                Header: 'Reajuste',
                headerPost: 'right',
                formato: 'moneda',
                total: true,
                headerPaddR:'1em'    
            },
            {
                accessor: 'Interes',
                Header: 'Interes',
                headerPost: 'right',
                formato: 'moneda',
                total: true,
                headerPaddR:'1em'    
            },
            {
                accessor: 'Multa',
                Header: 'Multa',
                headerPost: 'right',
                formato: 'moneda',
                total: true,
                headerPaddR:'1em'    
            },
            {
                accessor: 'Total',
                Header: 'Total',
                headerPost: 'right',
                formato: 'moneda',
                total: true,
                headerPaddR:'2em'  
            }
        ];

    return <TableContainer
        columns={columns}
        data={data}
        isAddOptions={false}
        customPageSize={pagSiz}
        isPagination={isPagination}
        filterable={false}
        iscustomPageSizeOptions={false}
        tableClass="TbXs align-middle table-nowrap table-check"
        theadClass="table-light"
        pagination="pagination pagination-rounded justify-content-end mb-2"
        isLoading={isLoading}
        isTotal
        sortName='fechaVence'
        sortOrder='asc'
        pageIndex={pageIndex} // Pasar pageIndex
        setPageIndex={setPageIndex} // Pasar setPageIndex
    />;
}

