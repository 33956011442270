import React, { useState } from 'react';
import { NavLink } from 'reactstrap';
import { Card, CardBody, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { ActividadEconomicaSII } from './tabs/ActividadEconomicaSII';
import { BienRaizSII } from './tabs/BienRaizSII';
import { SociosAccionistasSII } from './tabs/SociosAccionistasSII';
import { CaracteristicasSII } from './tabs/CaracteristicasSII';
import { SituacionTrubutariaSII } from './tabs/SituacionTrubutariaSII';
import { SociedadesSII } from './tabs/SociedadesSII';
import { ReactComponent as GroupsIcon } from '../../shared/icon/newIcons/groups.svg';
import { ReactComponent as ApartmentIcon } from '../../shared/icon/newIcons/apartment.svg';
import { ReactComponent as NotificationsIcon } from '../../shared/icon/newIcons/notifications.svg';
import { ReactComponent as StoreIcon } from '../../shared/icon/newIcons/store.svg';

export const TabsSII = ({
  bienRaiz,
  socios,
  caracteristicas,
  sociedades,
  representante
}) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [customTextTab, setcustomTextTab] = useState("1");

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
    if (customTextTab !== tab) {
      setcustomTextTab(tab);
    }
  };

  const getNavLinkColor = (isActive) => {
    if (!isActive) {
      return 'transparent';
    }
    return "#F0F2F7";
  };

  const getNavTextColor = (isActive) => {
    if (!isActive) {
      return '#999';
    }
    return "#000000";
  };
  const getNavIconColor = (tab) => {
    if (tab !== customTextTab) {
      return '#999';
    }
    switch (tab) {
      case "1": return "#267107";
      case "2": return "#0D2F82";
      case "3": return "#B55C48";
      case "4": return "#657AB0";
      default: return "#000000";
    }
  };



  const renderIcon = (IconComponent, tab) => (
    <IconComponent className='mb-1' fill={getNavIconColor(tab)} width="22" height="22" />
  );

  return (
    <React.Fragment>
      <Card>
        <CardBody className='p-1'>
          <Row className='pt-2'>
            <Nav tabs className="nav-tabs-custom nav-justified">
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "1")
                  }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "1") }}>
                    {renderIcon(GroupsIcon, "1")} Socios y Accionistas
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "2")
                  }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "2") }}>
                    {renderIcon(ApartmentIcon, "2")} Bienes Raices
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "3")
                  }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "3") }}>
                    {renderIcon(NotificationsIcon, "3")} Notificaciones
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    backgroundColor: getNavLinkColor(customActiveTab === "4")
                  }}
                  className={classnames({
                    active: customActiveTab === "4",
                  })}
                  onClick={() => {
                    toggleCustom("4");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block titGraf" style={{ color: getNavTextColor(customTextTab === "4") }}>
                    {renderIcon(StoreIcon, "4")} Sociedades
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={customActiveTab}
              className="text-muted p-0"
            >
              <TabPane tabId="1" style={{ backgroundColor: getNavLinkColor(customActiveTab) }}>
                <div className="col">
                  <p className='titGraf mt-0 py-2 mx-3'>Socios</p>
                </div>
                <SociosAccionistasSII
                  representante={representante}
                  data={socios}
                />
              </TabPane>
              <TabPane tabId="2" style={{ backgroundColor: getNavLinkColor(customActiveTab) }}>
                <BienRaizSII
                  data={bienRaiz}
                />
              </TabPane>
              <TabPane tabId="3" style={{ backgroundColor: getNavLinkColor(customActiveTab) }}>
                <CaracteristicasSII
                  caracteristicas={caracteristicas}
                />
              </TabPane>
              <TabPane tabId="4" className='pt-2' style={{ backgroundColor: getNavLinkColor(customActiveTab) }}>
                <SociedadesSII
                  sociedades={sociedades}
                />
              </TabPane>
            </TabContent>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};