import { NotData } from '../../shared/alert/notData'
import { Cesiones } from '../resumen/graficosBarra/Cesiones'
import { TablaFactNoCedDemo } from '../tablas/TablaFacNoCedDemo'
export const Card3 = (
  {
    ComVensCr,
    FactNoCed,
    Periodo,
    isLoading,
  }
) => {
  const msg = 'Cliente no registra cesiones.'
  const periodos = [];
  const ventas = [];
  const cedidos = [];
  const cedidosFactoring = [];
  ComVensCr.forEach((datos) => {
    periodos.push(datos.periodo.toString().slice(2));
    ventas.push(datos.ventas - datos.notaCred);
    cedidos.push(datos.cedidos)
    cedidosFactoring.push(datos.cedidosFactoring)
  });
  return (
    <div className="card">
      <div className='row'>
        <div className='col-9'>
          <p className='titGraf mx-3 ps-2'>Cesiones</p>
          <p className='titGraf2 mx-3 ps-2'>Detalle mensual de cesiones efectuadas por período</p>
        </div>
        <div className='d-flex justify-content-evenly col-3 titGraf3 float-end'>
          <p style={{ color: '#B55C48' }}>Cedidos factoring</p>
          <p style={{ color: '#BC9C8C' }}>Cedido</p>
          <p style={{ color: '#4163B9' }}>Ventas</p>
        </div>
        <div className="card-body" id='Cesiones'>
          {cedidos.some(valor => valor > 0) ?
            < Cesiones
              periodos={periodos.reverse()}
              ventas={ventas.reverse()}
              cedidos={cedidos.reverse()}
              cedidosFactoring={cedidosFactoring.reverse()}
              mp={Periodo}
            />
            :
            <div className="my-5 pb-3 d-flex justify-content-center">
              <NotData
                msg={msg} />
            </div>
          }
        </div>
      </div>
      <div className='pt-0 position-sticky' style={{ top: '0' }}>
        <p className='titGraf mx-3 ps-2'>Facturas Disponibles</p>
        <p className='titGraf2 mx-3 ps-2'>Detalle de últimas facturas emitidas / No Cedidas</p>
        <TablaFactNoCedDemo
          FactNoCed={FactNoCed}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}


