import { useReducer } from "react"
import { AuthContext } from "./AuthContext"
import { authReducer } from "./AuthReducer"
import { types } from "../types/types"

// const initialState = {
//     logged: false,
// }

const init = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return {
        logged: !!user,
        user: user,
    }
}

export const AuthProvider = ({ children }) => {
    const [authState, dispatch] = useReducer(authReducer, { }, init);

    const login = (name = '', nameFactorin= '', nameLog= ''  ,token ='', rgIdFact  ='') => {

        const user = { id: '', name, nameLog, nameFactorin, token, rgIdFact}
        const action = { type: types.login, payload: user}

        localStorage.setItem('user', JSON.stringify(user))

        dispatch(action);
    }
    // const getToken = (tk= '') => {

    //     const token = { tk}
    //     const action = { type: types.token, payload: token}

    //     localStorage.setItem('token', JSON.stringify(token))

    //     dispatch(action);
    // }
    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        const action = {type: types.logout};
        dispatch(action)
    }
    return (
        <AuthContext.Provider value={{
            ...authState,
            login: login,
            logout: logout

        }}>
            {children}
        </AuthContext.Provider>
    )
}


