import { VentasVsNotasC } from '../resumen/graficosBarra/VentasVsNotasC'
import { NotData } from '../../shared/alert/notData'
export const Card2 = (
  {
    ComVensCr,
    Periodo
  }
) => {
  let calc = 0
  const periodos = []
  const ventas = []
  const notaCred = []
  const msg = 'Cliente no registra Notas de crédito.'
  ComVensCr.forEach((datos) => {
    periodos.push(datos.periodo.toString().slice(2));
    ventas.push(datos.ventas - datos.notaCred);
    notaCred.push(datos.notaCred);
    calc = calc + datos.ventas + datos.notaCred;
  });
  return (
    <div className="card">
      <div className='row'>
        <div className='col-9'>
          <p className='titGraf mx-4'>Gráfico de ventas vs Notas de crédito</p>
          <p className='titGraf2 mx-4'>Detalle de Notas de crédito emitido vs Ventas</p>
        </div>
        <div className='d-flex justify-content-evenly col-3 titGraf3 float-end'>
          <p style={{ color: '#BC9C8C' }}>Notas de crédito</p>
          <p style={{ color: '#4163B9' }}>Ventas</p>
        </div>
      </div>
      <div className="card-body">
        {calc > 0 ?
          <VentasVsNotasC
            periodos={periodos.reverse()}
            ventas={ventas.reverse()}
            notaCred={notaCred.reverse()}
            mp={Periodo}
          />
          :
          <div className="my-5 pb-3 d-flex justify-content-center">
            <NotData
              msg={msg} />
          </div>
        }
      </div>
    </div>
  )
}


