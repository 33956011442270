import { MaterialReactTable } from 'material-react-table';
import { useState } from 'react';
import { Box } from '@mui/material';
export const TablaComercialDetalleVentCompCrDetalleDemo = ({
    DetVentCompCr
}) => {
    let FormatTemp1 = []; // Asegura que FormatTemp1 es accesible en todo el componente
    if (DetVentCompCr?.length > 0) {
        let subTV = 0; // Subtotal de ventas
        let subTC = 0; // Subtotal de compras
        // Procesar datos y calcular subtotales
        DetVentCompCr.forEach((datos) => {
            // Asegúrate de que los montos son números
            const montoVenta = Number(datos.montoVenta);
            const montoCompra = Number(datos.montoCompra);
            // Actualiza el subtotal de compras
            if (datos.tipDocCom === 61) {
                subTC -= montoCompra; // Resta si es tipo 61
            } else {
                subTC += montoCompra; // Suma en otro caso
            }
            // Actualiza el subtotal de ventas
            if (datos.tipDocVta === 61) {
                subTV -= montoVenta; // Resta si es tipo 61
            } else {
                subTV += montoVenta; // Suma en otro caso
            }
            // Agregar datos formateados
            FormatTemp1.push({
                rgId: datos.rgId,
                fechaVenta: datos.fecRecVta, doctoVenta: datos.nroDocVta, montoVenta: montoVenta, desDocCom: datos.desDocCom,
                fechaCompra: datos.fecRecCom, doctoCompra: datos.nroDocCom, montoCompra: montoCompra, desDocVta: datos.desDocVta
            });
        });
        // Agregar los subtotales generales al final del array
        FormatTemp1.push({
            doctoVenta: 'Total Ventas', montoVenta: subTV,
            doctoCompra: 'Total Compras', montoCompra: subTC
        });
    }
    let isPagination = true
    const pagSiz = 20
    if (DetVentCompCr.length < pagSiz) {
        isPagination = false
    }
    const columns = [
        {
            header: 'Ventas',
            columns: [
                {
                    accessorKey: 'fechaVenta', //normal accessorKey
                    header: 'Fecha Venta',
                    size: 120,
                    enableSorting: false,
                },
                {
                    accessorKey: 'desDocVta', //access nested data with dot notation
                    header: 'Tipo Doc',
                    size: 120,
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    enableSorting: false,
                },
                {
                    accessorKey: 'doctoVenta',
                    header: 'Documento',
                    size: 120,
                    enableSorting: false,
                    Cell: ({ cell }) => (
                        <>
                            {cell.getValue() === 'Subtotal Ventas'
                                ? <b>{cell.getValue()}</b>
                                : cell.getValue() === 0 ? '' : cell.getValue()
                            }
                        </>
                    ),
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                },
                {
                    accessorKey: 'montoVenta',
                    header: 'Monto',
                    Cell: ({ cell, row }) => {
                        const value = cell.getValue();
                        let formattedValue;
                        let isNegative = false;
                        if (row.original.desDocVta === 'NOTA-CR-EL') {
                            const negativeValue = -value;
                            const formattedNegativeValue = negativeValue.toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                            });
                            // Insertar el signo menos antes del símbolo de moneda
                            formattedValue = formattedNegativeValue.replace('-', '-$').replace('$', '');
                            isNegative = true;
                        } else {
                            formattedValue = value.toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                            });
                        }
                        return (
                            <>
                                {row.getValue('doctoVenta') === 'Subtotal Ventas' ? (
                                    <b>{formattedValue}</b>
                                ) : (
                                    value === 0 ? '' : formattedValue
                                )}
                            </>
                        );
                    },
                    muiTableBodyCellProps: ({ cell, row }) => {
                        const value = cell.getValue();
                        const isNegative = row.original.desDocVta === 'NOTA-CR-EL';
                        return {
                            align: 'right',
                            style: {
                                backgroundColor: isNegative ? '#F5D7E4' : undefined, // Fondo para valores negativos
                                color: isNegative ? '#000000' : undefined, // Color del texto
                                borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                            }
                        };
                    },
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 180,
                    enableSorting: false,
                }
            ]
        },
        {
            header: 'Compras',
            columns: [
                {
                    accessorKey: 'fechaCompra',
                    header: 'Fecha Compra',
                    size: 120,
                    enableSorting: false,
                },
                {
                    accessorKey: 'desDocCom', //access nested data with dot notation
                    header: 'Tipo Doc',
                    size: 120,
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    enableSorting: false,
                },
                {
                    accessorKey: 'doctoCompra',
                    header: 'Documento',
                    size: 120,
                    enableSorting: false,
                    Cell: ({ cell }) => (
                        <>
                            {cell.getValue() === 'Subtotal Compra'
                                ? <b>{cell.getValue()}</b>
                                : cell.getValue() === 0 ? '' : cell.getValue()
                            }
                        </>
                    ),
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                },
                {
                    accessorKey: 'montoCompra',
                    header: 'Monto',
                    Cell: ({ cell, row }) => {
                        const value = cell.getValue();
                        let formattedValue;
                        let isNegative = false;
                        if (row.original.desDocCom === 'NOTA-CR-EL') {
                            const negativeValue = -value;
                            const formattedNegativeValue = negativeValue.toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                            });
                            // Insertar el signo menos antes del símbolo de moneda
                            formattedValue = formattedNegativeValue.replace('-', '-$').replace('$', '');
                            isNegative = true;
                        } else {
                            formattedValue = value.toLocaleString('es-CL', {
                                style: 'currency',
                                currency: 'CLP',
                            });
                        }
                        return (
                            <>
                                {row.getValue('doctoCompra') === 'Subtotal Compra' ? (
                                    <b>{formattedValue}</b>
                                ) : (
                                    value === 0 ? '' : formattedValue
                                )}
                            </>
                        );
                    },
                    muiTableBodyCellProps: ({ cell, row }) => {
                        const value = cell.getValue();
                        const isNegative = row.original.desDocCom === 'NOTA-CR-EL';
                        return {
                            align: 'right',
                            style: {
                                backgroundColor: isNegative ? '#F5D7E4' : undefined, // Fondo para valores negativos
                                color: isNegative ? '#000000' : undefined, // Color del texto
                                borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                            }
                        };
                    },
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    size: 180,
                    enableSorting: false,
                }
            ]
        }
    ]
    const [Pagination, setPagination] = useState({ pagination: { pageSize: pagSiz, pageIndex: 0 }, density: 'compact', });
    const [Options, setOptions] = useState({ options: { rowStyle: { height: 20 } } });
    const renderTopToolbarCustomActions = ({ table }) => (
        <Box
            sx={{ height: 41 }}
        >
            <div className="col">
                <p className='titGraf  pb-4 mx-3'>Detalle de Compras y Ventas Cruzadas</p>
            </div>
        </Box>
    )
    return (
        <MaterialReactTable
            renderTopToolbarCustomActions={renderTopToolbarCustomActions}
            columns={columns}
            data={FormatTemp1}
            enableRowSelection={false}
            enableColumnOrdering={false}
            enableGlobalFilter={true}
            enableClickToCopy={true}
            enableColumnActions={false}
            enableColumnDragging={false}
            enableColumnFilterModes={false}
            enableColumnVirtualization={false}
            enableDensityToggle={false}
            enableTopToolbar={true}
            enablePagination={isPagination}
            initialState={Pagination}
            enableColumnResizing={true}
            options={Options}
            enableBottomToolbar={isPagination}
        />
    )
}


