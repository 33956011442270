import TableContainer from '../../shared/TableContainer';
import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Screen_search_desktop from '../../shared/icon/newIcons/screen_search_desktop.svg'
import { ModalDetalleCyV } from '../modal/ModalDetalleCyV';

export const TablaComercialDetalleVentCompCrDemo = ({
    DetVentCompCr,
    isLoading,
    mp
}) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    const [rowSelect, setRowSelect] = useState([]);
    let isPagination = true
    const pagSiz = 10
    if (DetVentCompCr.length < pagSiz) {
        isPagination = false
    }
    const updatedData = DetVentCompCr.map(item => ({
        ...item,
        rutDeuConcat: `${item.rutDeu}-${item.digDeu}`,
        totalVenta30: item.venta1,
        totalCompra30: item.compra1,
        totalVenta60: item.venta1 + item.venta2,
        totalCompra60: item.compra1 + item.compra2,
        totalVenta90: item.venta1 + item.venta2 + item.venta3,
        totalCompra90: item.compra1 + item.compra2 + item.compra3,
        totalVentaUlt: item.venta1 + item.venta2 + item.venta3 + item.venta4 + (mp === 24 ? item.venta5 : 0),
        totalCompraUlt: item.compra1 + item.compra2 + item.compra3 + item.compra4 + (mp === 24 ? item.compra5 : 0),
    }));
    const columns = [
        {
            Header: ' ',
            columns: [
                {
                    accessor: 'rutDeuConcat', //access nested data with dot notation
                    Header: 'Rut',
                    headerPost: 'center',
                    formato: 'rut2',
                },
                {
                    accessor: 'nomDeu',
                    Header: 'Nombre',
                    headerPost: 'center',
                    formato: 'string',
                    minWidth:'28em'
                }
            ]
        },
        {
            Header: 'Últimos 30 días',
            headerPost: 'center',
            columns: [
                {
                    accessor: 'totalVenta30', //normal accessor
                    Header: 'Ventas',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#D7F5D9', // Rosa claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                        borderLeft: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
                {
                    accessor: 'totalCompra30', //normal accessor
                    Header: 'Compras',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#D7F5D9', // Rosa claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
            ]
        },
        {
            Header: 'Últimos 60 días',
            headerPost: 'center',
            columns: [
                {
                    accessor: 'totalVenta60', //normal accessor
                    Header: 'Ventas',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#F5ECD7', // Verde claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
                {
                    accessor: 'totalCompra60', //normal accessor
                    Header: 'Compras',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#F5ECD7', // Verde claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
            ]
        },
        {
            Header: 'Últimos 90 días',
            headerPost: 'center',
            columns: [
                {
                    accessor: 'totalVenta90', //normal accessor
                    Header: 'Ventas',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#f4ddd2', // Azul claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
                {
                    accessor: 'totalCompra90', //normal accessor
                    Header: 'Compras',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#f4ddd2', // Azul claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
            ]
        },
        {
            Header: `Últimos ${mp} meses`,
            headerPost: 'center',
            columns: [
                {
                    accessor: 'totalVentaUlt', //normal accessor
                    Header: 'Ventas',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#e5a294', // Verde claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
                {
                    accessor: 'totalCompraUlt', //normal accessor
                    Header: 'Compras',
                    headerPost: 'center',
                    formato: 'moneda',
                    personalStyle: {
                        backgroundColor: '#e5a294', // Verde claro por ejemplo
                        color: '#000000', // Cambiar el color del texto si es necesario
                        borderRight: '1px solid #E0E0E0', // Borde derecho blanco
                    },
                },
            ]
        },
        {
            accessor: 'idDeu',
            Header: '',
            size: 50,
            icono: true,
            enableSorting: false,
            Cell: ({ cell, row }) => (
                <Tooltip title="Comportamiento" className='p-0'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <IconButton color="success" onClick={() => openModalDetalleComportamiento(row)} data-bs-toggle="modal" data-bs-target="#ModalDetalleCyV">
                            <img className='pb-1 pe-1' src={Screen_search_desktop} alt="" width="22" height="22" />
                        </IconButton>
                    </div>
                </Tooltip>
            ),
        },
    ];
    const openModalDetalleComportamiento = (row) => {
        setRowSelect(row.original)
    };

    return (
        <>
            <TableContainer
                columns={columns}
                data={updatedData}
                isAddOptions={false}
                customPageSize={pagSiz}
                isPagination={isPagination}
                filterable={false}
                iscustomPageSizeOptions={false}
                tableClass="TbXs align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
                isLoading={isLoading}
                // isTotal
                sortName='nomDeu'
                sortOrder='desc'
                pageIndex={pageIndex} // Pasar pageIndex
                setPageIndex={setPageIndex} // Pasar setPageIndex
            />
            <ModalDetalleCyV
                mp={mp}
                row={rowSelect} />
        </>


    )
}


