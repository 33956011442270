export const formatTXT = {

  corregirFechaArray(array, nombreFecha) {
    return array.map(item => {
      const fechaOriginal = item[nombreFecha];
      let fechaConvertida = '';

      // Verifica si la fecha existe y si es válida antes de transformarla
      if (fechaOriginal) {
        const partes = fechaOriginal.split('/');
        if (partes.length === 3 && partes[0].length === 2 && partes[1].length === 2 && partes[2].length === 4) {
          fechaConvertida = `${partes[2]}-${partes[1].padStart(2, '0')}-${partes[0].padStart(2, '0')}`;
        } else {
          console.error('Fecha en formato incorrecto:', fechaOriginal);
        }
      } else {
        console.error('Propiedad de fecha no disponible en este objeto:', item);
      }

      // Devuelve el objeto actualizado con la fecha convertida
      return {
        ...item,
        [nombreFecha]: fechaConvertida
      };
    });
  },

  corregirArray(array) {
    return array.map(elemento => {
      const elementoCorregido = { ...elemento };

      for (const propiedad in elementoCorregido) {
        if (typeof elementoCorregido[propiedad] === 'string') {
          elementoCorregido[propiedad] = formatTXT.corregirTXT(elementoCorregido[propiedad]);
        }
      }

      return elementoCorregido;
    });
  },

  corregirTXT(texto) {
    if (!texto) {
      return '';
    }
    const mapa = {
      'Ã\xED': 'í',
      'Ã\xFA': 'ú',
      'Ã\xCA': 'É',
      'Ã\x81': 'á',
      'Ã\x89': 'é',
      'Ã\x93': 'ó',
      'Ã\x8F': 'í',
      'Ã\x94': 'í',
      'Ã\x8C': 'é',
      'Ã\x99': 'é',
      'Ã\x87': 'á',
      'Ã\x92': 'ó',
      'Ã\x9A': 'ú',
      'Ã\x84': 'á',
      'Ã\x85': 'á',
      'Ã\x9B': 'ú',
      'Ã\x9C': 'ú',
      'Ã\x91': 'ó',
      'Ã\x9F': 'í',
      'Ã\x95': 'é',
      'Ã\x9E': 'ú',
      'Ã\xA1': 'á',
      'Ã\xA9': 'é',
      'Ã\xAA': 'í',
      'Ã\xAB': 'ó',
      'Ã\xAC': 'ú',
      'Ã\xAD': 'ú',
      'Ã\xB1': 'á',
      'Ã\xB9': 'é',
      'Ã\xBA': 'í',
      'Ã\xBB': 'ó',
      'Ã\xBC': 'ú',
      'Ã\xBD': 'ú',
      'Ã\xB5': 'í',
      'Ã\xB6': 'í',
      'Ã\xB3': 'ó',
      'Ã\xB4': 'ó',
      'Ã\xA3': 'á',
      'Ã\xA2': 'á',
      'Ã\xAF': 'é',
      'Ã\xAE': 'é',
      'Ã\xA5': 'á',
      'Ã\xA4': 'á',
      'Ã\xBF': 'í',
      'Ã\xB7': 'ú',
      'Ã\xB0': 'á',
      'Ã\xB8': 'é',
      'Ã\xB2': 'ó',
      'Ã\xB6': 'í',
      'Ã\xB5': 'í',
      'Ã\xA6': 'é',
      'Ã\xA7': 'é',
      'Ã\xA0': 'á',
      'Ã\xA8': 'é',
      'Ã\x80': 'á',
      'Ã\x83': 'á',
      'Ã\x82': 'á',
      'Ã\x86': 'á',
      'Ã\x88': 'é',
      'Ã\x8A': 'é',
      'Ã\x8B': 'é',
      'Ã\x96': 'ó',
      'Ã\x97': 'ó',
      'Ã\x98': 'ó',
      'Ã\x9D': 'ú',
      'Ã\x98': 'ú',
      'Ã\x96': 'ú',
      'Ã\x90': 'ú',
      'Ã\x90': 'ú',
      'Ã\x8E': 'í',
      'Ã\x8D': 'í',
      'aáo': 'año',
      'Â': '',
      'Ã\x91': 'Ñ',
      'Ã±': 'ñ'
    };
    let textoCorregido = texto;

    for (const [clave, valor] of Object.entries(mapa)) {
      textoCorregido = textoCorregido.replace(new RegExp(clave, 'g'), valor);
    }

    return textoCorregido;
  }
}