import { CardGPCD } from "../cards/CardGPCD";

export const ModalDetalleGraficoCesionesDeudor = ({
    ResCssDdr,
    Periodo
}) => {
    console.log(ResCssDdr)
    const notPropagation = (e) => {
        e.stopPropagation();
    }
    return (
        <div className="modal fade" id="ModalDetalleGraficoCesionesDeudor" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
            <div className="modal-dialog" style={{ maxWidth: '75%', width: 'auto', top: '50%', margin: 'auto', transform: 'translate(0%, -50%)' }}>
                <CardGPCD
                    ResCssDdr={ResCssDdr}
                    Periodo={Periodo}
                />
            </div>
        </div>
    )
}

