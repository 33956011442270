import { Navigate, Route, Routes } from "react-router-dom";
// import { Inicio } from '../componets/inicio/Inicio';
// import { Resumen } from '../componets/resumen/Resumen';
// import { Tgr } from "../componets/tgr/Tgr";
// import { Sii } from "../componets/sii/Sii";
// import { Comercial } from "../componets/comercial/Comercial";
// import { GestionFinanciera } from "../componets/gestion/GestionFinanciera";
import { Login } from "../componets/login/Login";
import { PrivateRoute } from "./PrivateRoute";
import { ComponentRoutes } from "../componets/routes/ComponentRoutes";

// const rutaServidor ="/"
const rutaServidor ="/AFD"
export const AppRouter = () => {
  return (
    <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="/*" element={
          <PrivateRoute>
            <ComponentRoutes/>
          </PrivateRoute>
        }
        />
        {/* <Route path="/inicio" element={<Inicio/>} />
        <Route path="/resumen/" element={<Resumen/>} />
        <Route path="/tgr/" element={<Tgr/>} />
        <Route path="/sii/" element={<Sii/>} />
        <Route path="/comercial/" element={<Comercial/>} />       
        <Route path="/gestion/" element={<GestionFinanciera/>} />       
        <Route path="*" element={<Navigate to="/Login"/>} /> */}
    </Routes>
  )
}


