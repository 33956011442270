import { useLocation, useNavigate } from "react-router-dom";
import { TablaSiiIndice } from "../tablas/TablaSiiIndice";
import { useEffect, useContext, useState } from "react";
import { IdContext } from "../../context/IdContext";
import { TablaSiiBalance } from "../tablas/TablaSiiBalance";
import { TablaSiiEstadoResultado } from "../tablas/TablaSiiEstadoResultado";
import { SII } from "../../helpers/SII";
import { TabsSII } from "./TabsSII";
import { TabsSII2 } from "./TabsSII2"
import { formatTXT } from '../../helpers/formatTXT';
import { formatVal } from "../../helpers/formatVal";

export const Sii = () => {
  const IdNombre = useContext(IdContext);
  const NombreCli = IdNombre.IdNombre
  const rut = IdNombre.IdRut
  const idProvider = useContext(IdContext);
  const id = idProvider.idProvider
  const location = useLocation();
  const navigate = useNavigate();
  const [EstadoResultado, setEstadoResultado] = useState([])
  const [Balance, setBalance] = useState([])
  const [Indice, setIndice] = useState([])
  const [F29, setF29] = useState([])
  const [F22, setF22] = useState([])
  const [F22x, setF22x] = useState([])
  const [miSII, setMiSII] = useState([])
  const [direccion, setDireccion] = useState([])
  const [bienRaiz, setBienRaiz] = useState([])
  const [socios, setSocios] = useState([])
  const [sociedades, setSociedades] = useState([])
  const [caracteristicas, setCaracteristicas] = useState([])
  const [actividadesEconomicas, setActividadesEconomicas] = useState([])
  const [SituacionTributaria, setSituacionTributaria] = useState([])
  const [representante, setRepresentante] = useState([])
  const [isLoadingMiSII, setIsLoadingMiSII] = useState(true);
  const [isLoading29, setIsLoading29] = useState(true);
  const [isLoading22, setIsLoading22] = useState(true);

  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      SII.getObtenerMiSII(id).then((r) => {
        if (r.status === 200) {
          setDireccion(formatTXT.corregirTXT(r.data.siiDirecciones[0].diDireccion))
          setBienRaiz(formatTXT.corregirArray(r.data.siiBienesRaices))
          setSocios(formatTXT.corregirArray(r.data.siiSocios))
          setRepresentante(formatTXT.corregirArray(r.data.siiRepresentantes))
          setSociedades(formatTXT.corregirArray(r.data.siiSociedades))
          setCaracteristicas(formatTXT.corregirArray(r.data.siiCaracteristicas))
          setActividadesEconomicas(formatTXT.corregirArray(r.data.siiActividadesEconomicas))
          setSituacionTributaria(formatTXT.corregirArray(r.data.siiSituacionTributaria))
          setMiSII(r.data)
          setIsLoadingMiSII(false);

        } else {

          setDireccion([])
          setBienRaiz([])
          setSocios([])
          setRepresentante([])
          setSociedades([])
          setCaracteristicas([])
          setActividadesEconomicas([])
          setSituacionTributaria([])
          setMiSII([])
          setIsLoadingMiSII(false);

        }
      }).catch((e) => {
        console.error(e)
      })
      SII.getResumenSiiF29(id).then((r) => {
        if (r.status === 200) {
          setF29(r.data.listadoFormulario29Resumen)
          setIsLoading29(false);
        } else {
          setF29([])
          setIsLoading29(false);
        }
      }).catch((e) => {
        console.error(e)
      })
      SII.getResumenSiiF22(id).then((r) => {
        if (r.status === 200) {
          setF22(formatTXT.corregirArray(r.data.listadoFormulario22))
          setEstadoResultado(r.data.listadoFormulario22Datos)
          setBalance(r.data.listadoFormulario22Datos)
          setIndice(r.data.listadoFormulario22Datos)
          setIsLoading22(false);
        } else {
          setF22([])
          setEstadoResultado([])
          setBalance([])
          setIndice([])
          setIsLoading22(false);
        }
      }).catch((e) => {
        console.error(e)
      })
    }


  }, [])


  const formatAddress = (address) => {
    // Verificar que address es una cadena
    if (typeof address !== 'string') {
      return '';
    }

    // Separar la dirección en partes por las comas
    let parts = address.split(',');

    // Verificar que hay al menos dos partes
    if (parts.length < 2) {
      return address;
    }

    // Tomar los elementos relevantes
    let streetPart = parts[0].trim().replace(" Nro.", "").replace(" Depto.", "");
    let comunaPart = parts[1].trim().replace("Comuna ", "");

    // Combinar las partes relevantes
    let formattedAddress = `${streetPart}, ${comunaPart}`;

    return formattedAddress;
  };

  const formatDireccion = formatAddress(direccion)

  return (
    <>
      <div className="row px-3 pt-4 ">
        <div className="col-5  px-2 row">
          <div className="card">
            <div className="col border rounded">
              <ul className="list-group list-group-flush">
                <li className="list-group-item pb-0 ">
                  <div className="d-flex row justify-content-evenl">
                    <p className='titGraf pt-1  mx-2'>{formatVal.formatRut(rut)} | {NombreCli}</p>

                  </div>
                  <p className='titGraf2 mx-2 mt-3 pe-4 cardDot' title={formatDireccion}>
                    {formatDireccion}</p>
                </li>
                <li className="list-group-item">
                  <div className='col-12 d-flex pt-1 pb-3'>
                    <p className='titGrafSP ms-3'>Balance </p>
                  </div>
                  <TablaSiiBalance
                    Balance={Balance}
                    F22={F22}
                    isLoadingMiSII={isLoadingMiSII}
                    isLoading22={isLoading22}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-7 px-2 row">
          <div className="card" >
            <div className="col">
              <TabsSII
                socios={socios}
                representante={representante}
                bienRaiz={bienRaiz}
                caracteristicas={caracteristicas}
                sociedades={sociedades}
                isLoadingMiSII={isLoadingMiSII}
              />
            </div>
          </div>
        </div>

      </div >



      <div className="row px-3 pt-4 ">
        <div className="col-5  px-2 row">
          <div className="card" >
            <div className='col-12 d-flex pt-1 pb-3'>
              <p className='titGraf ms-3'>Estado Resultado  </p>
            </div>
            <div className="col ">
              <TablaSiiEstadoResultado
                EstadoResultado={EstadoResultado}
                isLoadingMiSII={isLoadingMiSII}

              />
            </div>
          </div>
        </div>


        <div className="col-7 px-2 row">
          <div className="card" >
            <div className="col">
              <TabsSII2
                F29={F29}
                F22={F22}
                actividadesEconomicas={actividadesEconomicas}
                SituacionTributaria={SituacionTributaria}
                isLoading29={isLoading29}
                isLoading22={isLoading22}
              />
            </div>
          </div>
        </div>


      </div>
    </>
  )
}
