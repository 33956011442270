import { useState } from 'react';
import { BotonExcel } from '../../helpers/getDataToExcel';
import TableContainer from '../../shared/TableContainer';
import { getEstadosFactura } from '../../helpers/getEstadosFactura';

export const TablaComercialVentasDispDemo = ({
    Facturas,
    isLoading
}) => {
    console.log(Facturas)
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    const facturasFilt = []
    if (Facturas.length > 0) {
        Facturas.forEach((datos) => {
            if (datos.cedido !== 'cedido') {
                facturasFilt.push(datos);
            }
            if (datos.tipoDocto !== 'Factura') {
            }
        });
    }
    let isPagination = true
    const pagSiz = 10
    const tituloComponent = 'Facturas Emitidas No Cedidas'
    if (facturasFilt.length < pagSiz) {
        isPagination = false
    }
    const columns = [
        {
            accessor: 'fechaEmision', //access nested data with dot notation
            Header: 'Fecha Emisión',
            formato: 'fecha',
            headerPost: 'center',
            headerSlice: true
        },
        {
            accessor: 'rutDeudor',
            Header: 'RUT',
            formato: 'rut',
            headerPost: 'center',
            padd: '-1.0em'
        },
        {
            accessor: 'nomDeudor', //normal accessor
            Header: 'Nombre',
            formato: 'string',
            inicio: true,
            title: true,
            truncate: 'max-Width: 20em !important; text-align: left;   white-space: nowrap;  overflow: hidden;   text-overflow: ellipsis;',
        },
        {
            accessor: 'tipoDocto',
            Header: 'Documento',
            size: 100,
            // padd: '2em'
        },
        {
            accessor: 'estado',
            Header: 'Estado',
            size: 100,
            headerPost: 'center',
            icono: true,
            action: false,
            Cell: ({ row }) => {
                const { color, descripcion } = getEstadosFactura(row.original.estado);
                return (
                    <>
                        <span
                            title={descripcion}
                            style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginLeft: '1em',
                                cursor: 'default' // Asegura que el cursor sea el estándar
                            }}
                        ></span>
                    </>
                );
            }
        },
        {
            accessor: 'nroDocto',
            Header: 'Numero Documento',
            size: 100,
            formato: 'centro',
            headerPost: 'center',
            totalTitulo: true,
            headerSlice: true
        },
        {
            accessor: 'montoTotal',
            Header: 'Monto',
            size: 100,
            formato: 'moneda',
            headerPost: 'center',
            final: true,
            total: true,
        },
    ]

    const transformData = (data, columns) => {
        console.log(data)
        return data.map(item => {
            let newItem = { ...item };
            console.log(newItem.estado)
            columns.forEach(column => {
                const { accessor, formato } = column;
                if (formato) {
                    switch (formato) {
                        case 'numero':
                            newItem[accessor] = Number(newItem[accessor]);
                            break;
                        case 'moneda':
                            newItem[accessor] = Number(newItem[accessor]);
                            break;
                        // case 'fecha':
                        //     newItem[accessor] = new Date(newItem[accessor]).toLocaleDateString();
                        //     break;
                        // Puedes agregar más formatos aquí
                        default:
                            break;
                    }
                }
            });
            return newItem;
        });
    };
    const transformDataExcel = (data, columns) => {
        return data.map(item => {
            let newItem = { ...item };
            columns.forEach(column => {
                const { accessor, formato } = column;
                if (accessor === 'estado') {
                    const { descripcion } = getEstadosFactura(newItem[accessor]);
                    newItem[accessor] = descripcion; // Usa la descripción del estado
                }
            });
            return newItem;
        });
    };

    const transformedData = transformData(facturasFilt, columns);
    const transformedDataExcel = transformDataExcel(transformedData, columns);
    console.log(transformedData);
    // Crear una copia del array original y modificar el header
    const modifiedColumns = columns.map(column => {
        if (column.accessor === 'nroDocto') {
            return {
                ...column,
                Header: 'N° Documento' // Modificar el Header
            };
        }
        return column;
    });
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <p className="titGraf mx-3">{tituloComponent}</p>
                    <p className="titGraf2 mx-3">Últimos 30 días</p>
                </div>
                <div className='pe-3'>
                    <BotonExcel data={transformedDataExcel} getColumnNames={modifiedColumns} title={tituloComponent} />
                </div>
            </div>
            <TableContainer
                columns={columns}
                data={transformedData}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={pagSiz}
                isPagination={isPagination}
                filterable={false}
                iscustomPageSizeOptions={false}
                tableClass="TbXs align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
                isLoading={isLoading}
                isTotal={true}
                sortName='fechaEmision'
                secondarySortName='montoTotal'
                sortOrder='asc'
                pageIndex={pageIndex} // Pasar pageIndex
                setPageIndex={setPageIndex} // Pasar setPageIndex
            />
        </>
    )
}


