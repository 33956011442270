// import axios from "axios";


// const URL_DESA ='https://demo-finmonitor-api.prosystem.app/'
// const URL_PROD ='https://finmonitor-api.prosystem.app/'
// let BASE_URL = URL_PROD
// export const dataApi = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//         //'Authorization': `Bearer ${localStorage.getItem("token")}`,
//         'Access-Control-Allow-Origin': '*',
//         'timeout': '1000',
//         // 'origin':'x-requested-with',
//         'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
//         'Content-Type': 'application/json',
//         // 'charset': 'utf-8',
//         // 'responseEncodig': 'utf-8'
//     },
// })
// export const dataApiLg = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//         'Access-Control-Allow-Origin': '*',
//         // 'origin':'x-requested-with',
//         'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
//         'Content-Type': 'application/json',
//         // 'charset': 'utf-8',
//         // 'responseEncodig': 'utf-8'
//     },
// })

import axios from "axios";

// Obtener la URL base desde las variables de entorno
const BASE_URL = process.env.REACT_APP_ENV;
console.log(BASE_URL)
export const dataApi = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'timeout': '1000',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
        'Content-Type': 'application/json',
    },
});

export const dataApiLg = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
        'Content-Type': 'application/json',
    },
});