// // import React, { Fragment, useEffect, useMemo, useState } from "react"
// // import PropTypes from "prop-types"
// // import { Table, Row, Col, Button, Tooltip } from "reactstrap"
// // import JobListGlobalFilter from "./GlobalSearchFilter"
// // import { useAsyncDebounce, useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table"
// // import Pagination from '@mui/material/Pagination';
// // import { parse, format } from 'date-fns';
// // import TableLoader from "./loading/TableLoader"

// // // Define a default UI for filtering
// // // function GlobalFilter({
// // //   preGlobalFilteredRows,
// // //   globalFilter,
// // //   globalFilterCli,
// // //   globalFilterDeu,
// // //   setGlobalFilter,
// // //   isJobListGlobalFilter,
// // // }) {
// // //   const count = preGlobalFilteredRows.length
// // //   const columnIds = 'nomCli'
// // //   const [value, setValue] = React.useState(globalFilter)
// // //   const [valueCli, setValueCli] = React.useState(globalFilterCli)
// // //   const [valueDeu, setValueDeu] = React.useState(globalFilterDeu)


// // //   const onChange = useAsyncDebounce((value) => {
// // //       setGlobalFilter(value || undefined);

// // //   }, 200);

// // //   return (
// // //     <React.Fragment>
// // //       <Col xxl={3} lg={6}>
// // //         <input type="search" className="form-control" id="search-bar-0" value={value || ""} placeholder={`${count} registros...`} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} />
// // //         {/* <input type="search" className="form-control" id="search-bar-0" value={value || ""} placeholder={`${count} registros...`} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} /> */}
// // //       </Col>
// // //       {/* <Col xxl={3} lg={6}>
// // //         <input type="search" className="form-control" id="search-bar-cli" value={valueCli || ""} placeholder={`Clientes...`} onChange={e => { setValueCli(e.target.value); onChange(e.target.value, 'Cli') }} />
// // //       </Col>
// // //       <Col xxl={3} lg={6}>
// // //         <input type="search" className="form-control" id="search-bar-deu" value={valueDeu || ""} placeholder={`Deudores...`} onChange={e => { setValueDeu(e.target.value); onChange(e.target.value, 'Deu') }} />
// // //       </Col> */}
// // //       {/* {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />} */}

// // //     </React.Fragment>
// // //   )
// // // }
// // function GlobalFilter({
// //   preGlobalFilteredRows,
// //   globalFilter,
// //   setGlobalFilter,
// //   setGlobalFilterCli, // Asegúrate de recibir esto
// //   setGlobalFilterDeu, // y esto
// // }) {
// //   const count = preGlobalFilteredRows.length;
// //   const [value, setValue] = useState('');
// //   const [valueCli, setValueCli] = useState('');
// //   const [valueDeu, setValueDeu] = useState('');

// //   const onChange = useAsyncDebounce(value => {
// //     setGlobalFilter(value ? value.toLowerCase() : '');

// //   }, 200);
// //   const onChangeCli = useAsyncDebounce(value => {
// //     setGlobalFilterCli(value ? value.toLowerCase() : '');
// //   }, 200);
// //   const onChangeDeu = useAsyncDebounce(value => {
// //     setGlobalFilterDeu(value ? value.toLowerCase() : '');
// //   }, 200);

// //   return (
// //     <Fragment>
// //       {/* <Col className="row">
// //         <Col className="px-3" xs={3}>
// //           <input
// //             type="search"
// //             className="form-control"
// //             value={value || ""}
// //             placeholder={`Buscar en todos los registros... (${count} registros)`}
// //             onChange={e => {
// //               setValue(e.target.value);
// //               onChange(e.target.value);
// //             }}
// //           />
// //         </Col>
// //         <Col className="px-3"xs={2}>
// //           <input
// //             type="search"
// //             className="form-control"
// //             value={valueCli || ""}
// //             placeholder="Filtrar por nombre de cliente..."
// //             onChange={e => {
// //               setValueCli(e.target.value);
// //               onChangeCli(e.target.value);
// //             }}
// //           />
// //         </Col>
// //         <Col className="px-3"xs={2}>
// //           <input
// //             type="search"
// //             className="form-control"
// //             value={valueDeu || ""}
// //             placeholder="Filtrar por nombre de deudor..."
// //             onChange={e => {
// //               setValueDeu(e.target.value);
// //               onChangeDeu(e.target.value);
// //             }}
// //           />
// //         </Col>
// //       </Col> */}
// //       <Col className="row">
// //         <Col className="px-3" xs={3}>
// //           <input
// //             type="search"
// //             className="form-control"
// //             value={value || ""}
// //             placeholder={`Buscar en todos los registros... (${count} registros)`}
// //             onChange={e => {
// //               setValue(e.target.value);
// //               onChange(e.target.value);
// //             }}
// //           />
// //         </Col>
// //         <Col className="d-flex justify-content-end" xs={9}>
// //           <Col className="px-3" xs={3}>
// //             <input
// //               type="search"
// //               className="form-control"
// //               value={valueCli || ""}
// //               placeholder="Filtrar por nombre de cliente..."
// //               onChange={e => {
// //                 setValueCli(e.target.value);
// //                 onChangeCli(e.target.value);
// //               }}
// //             />
// //           </Col>
// //           <Col className="px-3" xs={3}>
// //             <input
// //               type="search"
// //               className="form-control"
// //               value={valueDeu || ""}
// //               placeholder="Filtrar por nombre de deudor..."
// //               onChange={e => {
// //                 setValueDeu(e.target.value);
// //                 onChangeDeu(e.target.value);
// //               }}
// //             />
// //           </Col>
// //         </Col>
// //       </Col>
// //     </Fragment>
// //   );
// // }


// // const TableContainer = ({
// //   columns,
// //   data,
// //   isGlobalFilter,
// //   isJobListGlobalFilter,
// //   isAddOptions,
// //   isAddUserList,
// //   handleOrderClicks,
// //   handleUserClick,
// //   handleCustomerClick,
// //   isAddCustList,
// //   customPageSize,
// //   customPageSizeOptions,
// //   iscustomPageSizeOptions,
// //   isPagination,
// //   isShowingPageLength,
// //   paginationDiv,
// //   pagination,
// //   tableClass,
// //   theadClass,
// //   isLoading
// // }) => {
// //   const [globalFilterN, setGlobalFilter] = useState('');
// //   const [globalFilterCli, setGlobalFilterCli] = useState('');
// //   const [globalFilterDeu, setGlobalFilterDeu] = useState('');

// //   useEffect(() => {
// //   }, [globalFilterN]);
// //   const filteredData = useMemo(() => {
// //     const filtered = data.filter(item => {
// //       // Asegurarse de que la propiedad existe antes de llamar a toLowerCase()
// //       const globalFilterPass = globalFilterN ? (
// //         item.rutCli.toLowerCase().includes(globalFilterN.toLowerCase()) ||
// //         item.nomCli.toLowerCase().includes(globalFilterN.toLowerCase()) ||
// //         item.tipoDocto.toLowerCase().includes(globalFilterN.toLowerCase()) ||
// //         item.fechaRecepcionSII.toLowerCase().includes(globalFilterN.toLowerCase()) ||
// //         item.nomDeu.toLowerCase().includes(globalFilterN.toLowerCase()) ||
// //         // Asegúrate de convertir los valores numéricos a string antes de llamar a includes
// //         item.nroDocto.toString().includes(globalFilterN) ||
// //         item.montoVenta.toString().includes(globalFilterN) ||
// //         item.diasEmision.toString().includes(globalFilterN) ||
// //         item.nomDeu.toLowerCase().includes(globalFilterN.toLowerCase()) || // Asumiendo que nomDeu podría ser opcional
// //         item.nroDocto.toString().includes(globalFilterN)
// //       ) : true;
// //       // const globalFilterPass = globalFilterN ? (item.someProperty && item.someProperty.toLowerCase().includes(globalFilterN.toLowerCase())) : true;
// //       const clientFilterPass = globalFilterCli ? (item.nomCli && item.nomCli.toLowerCase().includes(globalFilterCli.toLowerCase())) : true;
// //       const debtorFilterPass = globalFilterDeu ? (item.nomDeu && item.nomDeu.toLowerCase().includes(globalFilterDeu.toLowerCase())) : true;
// //       return globalFilterPass && clientFilterPass && debtorFilterPass;
// //     });
// //     return filtered;
// //   }, [data, globalFilterN, globalFilterCli, globalFilterDeu]);

// //   const {
// //     getTableProps,
// //     getTableBodyProps,
// //     headerGroups,
// //     page,
// //     prepareRow,
// //     canPreviousPage,
// //     canNextPage,
// //     pageOptions,
// //     gotoPage,
// //     nextPage,
// //     previousPage,
// //     setPageSize,
// //     state,
// //     preGlobalFilteredRows,
// //     state: { pageIndex, pageSize },
// //   } = useTable(
// //     {
// //       columns,
// //       data: filteredData,
// //       // defaultColumn: { Filter: DefaultColumnFilter },
// //       initialState: {
// //         pageIndex: 0,
// //         pageSize: customPageSize,
// //         sortBy: [
// //           {
// //             desc: true,
// //           },
// //         ],
// //       },
// //     },
// //     useGlobalFilter,
// //     useFilters,
// //     useSortBy,
// //     useExpanded,
// //     usePagination
// //   )
// //   const onGlobalFilterChange = useAsyncDebounce(value => {
// //     setGlobalFilter(value || undefined);
// //   }, 200);

// //   // Función para manejar el cambio en el filtro de cliente
// //   const onGlobalFilterCliChange = useAsyncDebounce(value => {
// //     setGlobalFilterCli(value || undefined);
// //   }, 200);

// //   // Función para manejar el cambio en el filtro de deudor (ejemplo similar al anterior)
// //   const onGlobalFilterDeuChange = useAsyncDebounce(value => {
// //     setGlobalFilterDeu(value || undefined);
// //   }, 200);
// //   const generateSortingIndicator = column => {


// //     let arrowU = '\u2191';
// //     let arrowD = '\u2193';

// //     // return column.isSorted ? (column.isSortedDesc ? 
// //     //   <img className='mx-2' src={ArrowDown} alt="" width="12" height="13" />: " 🔼") : ""

// //     if (page[0] !== undefined) {

// //     }
// //     return column.isSorted ? (column.isSortedDesc ?
// //       ` ${arrowD}` : ` ${arrowU}`
// //     ) : ""
// //     // return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
// //   }

// //   const onChangeInSelect = event => {
// //     setPageSize(Number(event.target.value))
// //   }


// //   const handleChange = (event, value) => {
// //     gotoPage(value)
// //   };

// //   const highlightMatches = (cell, searchTerm) => {
// //     let columsEnd = 'padding-right: 0.8em;'
// //     let columsIni = 'padding-left: 0.8em;'

// //     if (!searchTerm || !cell.value) {
// //       if (cell.column.formato === 'moneda') {
// //         // return cell.value.toLocaleString('es-CL', {
// //         //   style: 'currency',
// //         //   currency: 'CLP',
// //         // });;
// //         return `<div style="text-align: right;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${cell.value.toLocaleString('es-CL', {
// //           style: 'currency',
// //           currency: 'CLP',
// //         })}</div>`;
// //       }
// //       if (cell.column.formato === 'numero') {
// //         // return cell.value.toLocaleString('es-CL', {
// //         //   style: 'currency',
// //         //   currency: 'CLP',
// //         // });;
// //         return `<div style="text-align: right;">${cell.value}</div>`
// //       }
// //       if (cell.column.formato === 'bool') {

// //         return `<div style="text-align: right;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${cell.value ? cell.column.formtTr : cell.column.formtFl}</div>`
// //       }
// //       if (cell.column.formato === 'rut') {
// //         let rut = cell.value
// //         let rut_format = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)
// //         // return (
// //         //   rut_format
// //         // )
// //         return `<div style="text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${rut_format}</div>`;
// //       }
// //       if (cell.column.formato === 'string') {
// //         return `<div style="text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${cell.value}</div>`;
// //       }
// //       if (cell.column.formato === 'centro') {
// //         return `<div style="text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${cell.value}</div>`;
// //       }
// //       if (cell.column.formato === 'fecha') {
// //         // Corregimos el formato de la fecha a analizar para que coincida con el formato de entrada
// //         const fecha = parse(cell.value, 'yyyy-MM-dd', new Date());
// //         // Formateamos la fecha analizada al formato 'DD/MM/YYYY'
// //         const fechaFormateada = format(fecha, 'dd/MM/yyyy');
// //         return `<div style="text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${fechaFormateada}</div>`;
// //       }
// //       else {
// //         // return cell.value; // Si no hay término de búsqueda o el contenido es nulo, devolver el contenido sin cambios
// //         return `<div style="text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${cell.value}</div>`// Si no hay término de búsqueda o el contenido es nulo, devolver el contenido sin cambios
// //       }
// //     }

// //     const textContent = cell.value.toString();
// //     const regex = new RegExp(searchTerm, 'gi');
// //     const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
// //     // Si la columna es 'Action', se trata de una celda que contiene un icono, así que devolvemos el contenido sin cambios
// //     if (cell.column.formato === 'numero' && typeof cell.value === 'number') {
// //       // return cell.value.toLocaleString('es-CL', {
// //       //   style: 'currency',
// //       //   currency: 'CLP',
// //       // });;
// //       return `<div style="text-align: right; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${highlightedContent}</div>`
// //     }
// //     if (cell.column.formato === 'string' && typeof cell.value === 'string') {
// //       return `<div style="text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${highlightedContent}</div>`;
// //     }

// //     if (cell.column.formato === 'fecha' && typeof cell.value === 'string') {
// //       return `<div style="text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">${highlightedContent}</div>`;
// //     }
// //     if (cell.column.Header === 'Action') {
// //       return cell.value;
// //     }
// //     if (cell.column.formato === 'rut' && typeof cell.value === 'string') {
// //       let rut = cell.value
// //       let rut_format = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)

// //       const valorCompleto = cell.value;
// //       const valorReducido = searchTerm;
// //       const matchPos = valorCompleto.indexOf(valorReducido);
// //       const parteAntes = valorCompleto.substring(0, matchPos);
// //       const parteMatch = valorReducido;
// //       const parteDespues = valorCompleto.substring(matchPos + valorReducido.length);

// //       if (matchPos !== -1) {
// //         let resultado = insertarSeparadorRut(parteAntes, parteMatch, parteDespues);
// //         const highlightedFormattedCurrency = `<div style="text-align: right;${cell.column.final ? columsEnd : ''}">${resultado[0]}<span style="text-align: left class="highlight">${resultado[1]}</span>${resultado[2]}</div>`;
// //         // const highlightedFormattedCurrency = `${resultado[0]}<span style="text-align: left class="highlight">${resultado[1]}</span>${resultado[2]}`;
// //         return highlightedFormattedCurrency
// //       } else {
// //         return rut_format
// //       }

// //     }
// //     if (cell.column.formato === 'moneda' && typeof cell.value === 'number') {
// //       if (cell.value.toString().includes(searchTerm)) {
// //         // Si el término de búsqueda está presente, hacer algo aquí
// //         const valorCompleto = cell.value.toString();
// //         const valorReducido = searchTerm;
// //         const matchPos = valorCompleto.indexOf(valorReducido);
// //         const parteAntes = valorCompleto.substring(0, matchPos);
// //         const parteMatch = valorReducido;
// //         const parteDespues = valorCompleto.substring(matchPos + valorReducido.length);

// //         let resultado = insertarSeparadorMiles(parteAntes, parteMatch, parteDespues);

// //         const highlightedFormattedCurrency = `<div style="text-align: right;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}">$${resultado[0]}<span class="highlight">${resultado[1]}</span>${resultado[2]}</div>`;
// //         // const highlightedFormattedCurrency = `$${resultado[0]}<span class="highlight">${resultado[1]}</span>${resultado[2]}`;
// //         return highlightedFormattedCurrency
// //       } else {
// //         // Si el término de búsqueda no está presente, continuar con el procesamiento normal
// //         // Continuar con el procesamiento normal del valor
// //         return cell.value.toLocaleString('es-CL', {
// //           style: 'currency',
// //           currency: 'CLP',
// //         });;
// //       }
// //     }

// //     // Reemplazar todas las ocurrencias del término de búsqueda con el mismo término envuelto en un span con una clase de resaltado
// //     // const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
// //     return highlightedContent;
// //   };


// //   function insertarSeparadorRut(num1, num2, num3) {
// //     // Concatenar los tres valores

// //     let rut = num1 + num2 + num3;

// //     let resultadoConPuntos = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8);

// //     // Cortar la cadena resultante para restaurar num1, num2 y num3 con los puntos añadidos
// //     const puntosEncontrados = resultadoConPuntos.match(/\./g); // Encuentra todos los puntos en la cadena
// //     // Ejemplo de uso:
// //     let longitudTotal = resultadoConPuntos.length;
// //     let rnum1 = ''
// //     let rnum2 = ''
// //     let rnum3 = ''
// //     if (num3) {
// //       rnum3 = num3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
// //     }
// //     if (num1) {
// //       rnum1 = resultadoConPuntos.slice(0, num1.length + Math.floor(num1.length / 3))
// //     }
// //     if (num2) {
// //       let preNum2 = resultadoConPuntos.slice(0, rut.length + puntosEncontrados.length - rnum3.length)
// //       rnum2 = preNum2.slice(rnum1.length)
// //     }
// //     return [rnum1, rnum2, rnum3];
// //   }

// //   function insertarSeparadorMiles(num1, num2, num3) {
// //     // Concatenar los tres valores
// //     let cadenaConcatenada = num1 + num2 + num3;
// //     // Agregar puntos como separadores de miles
// //     let resultadoConPuntos = '';
// //     let contador = 0;
// //     for (let i = cadenaConcatenada.length - 1; i >= 0; i--) {
// //       // Insertar un punto cada tres dígitos, excepto al principio
// //       resultadoConPuntos = cadenaConcatenada[i] + resultadoConPuntos;
// //       contador++;
// //       if (contador % 3 === 0 && i !== 0) {
// //         resultadoConPuntos = '.' + resultadoConPuntos;
// //       }
// //     }
// //     // Cortar la cadena resultante para restaurar num1, num2 y num3 con los puntos añadidos
// //     const puntosEncontrados = resultadoConPuntos.match(/\./g); // Encuentra todos los puntos en la cadena
// //     // Ejemplo de uso:
// //     let longitudTotal = resultadoConPuntos.length;
// //     let rnum1 = ''
// //     let rnum2 = ''
// //     let rnum3 = ''
// //     if (num3) {
// //       // rnum3 = resultadoConPuntos.substring((num3.length + 1) - ((num3.length -1) % 3));
// //       rnum3 = num3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
// //     }
// //     if (num1) {
// //       rnum1 = resultadoConPuntos.slice(0, num1.length + Math.floor(num1.length / 3))
// //     }
// //     if (num2) {
// //       let preNum2 = resultadoConPuntos.slice(0, cadenaConcatenada.length + puntosEncontrados.length - rnum3.length)
// //       rnum2 = preNum2.slice(rnum1.length)
// //     }
// //     return [rnum1, rnum2, rnum3];
// //   }


// //   return (
// //     <Fragment>
// //       <Row className="mb-2">
// //         {iscustomPageSizeOptions &&
// //           <Col md={customPageSizeOptions ? 2 : 1}>
// //             <select
// //               className="form-select"
// //               value={pageSize}
// //               onChange={onChangeInSelect}
// //             >
// //               {[10, 20, 30, 40, 50].map(pageSize => (
// //                 <option key={pageSize} value={pageSize}>
// //                   Show {pageSize}
// //                 </option>
// //               ))}
// //             </select>
// //           </Col>
// //         }

// //         {isGlobalFilter && (
// //           <GlobalFilter
// //             preGlobalFilteredRows={preGlobalFilteredRows}
// //             // globalFilter={state.globalFilter}
// //             setGlobalFilter={setGlobalFilter}
// //             setGlobalFilterCli={setGlobalFilterCli} // Asegúrate de pasar esto
// //             setGlobalFilterDeu={setGlobalFilterDeu} // y esto
// //             isJobListGlobalFilter={isJobListGlobalFilter}
// //           />
// //         )}
// //         {isAddOptions && (
// //           <Col sm="7" xxl="8">
// //             <div className="text-sm-end">
// //               <Button
// //                 type="button"
// //                 color="success"
// //                 className="btn-rounded  mb-2 me-2"
// //                 onClick={handleOrderClicks}
// //               >
// //                 <i className="mdi mdi-plus me-1" />
// //                 Add New Order
// //               </Button>
// //             </div>
// //           </Col>
// //         )}
// //         {isAddUserList && (
// //           <Col sm="7" xxl="8">
// //             <div className="text-sm-end">
// //               <Button
// //                 type="button"
// //                 color="primary"
// //                 className="btn mb-2 me-2"
// //                 onClick={handleUserClick}
// //               >
// //                 <i className="mdi mdi-plus-circle-outline me-1" />
// //                 Create New User
// //               </Button>
// //             </div>
// //           </Col>
// //         )}
// //         {isAddCustList && (
// //           <Col sm="7" xxl="8">
// //             <div className="text-sm-end">
// //               <Button
// //                 type="button"
// //                 color="success"
// //                 className="btn-rounded mb-2 me-2"
// //                 onClick={handleCustomerClick}
// //               >
// //                 <i className="mdi mdi-plus me-1" />
// //                 New Customers
// //               </Button>
// //             </div>
// //           </Col>
// //         )}
// //       </Row>

// //       <div className="table-responsive">
// //         <Table {...getTableProps()} className={tableClass} size="sm">
// //           <thead className={theadClass}>
// //             {headerGroups.map(headerGroup => (
// //               <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
// //                 {/* {headerGroup.headers.map(column => (
// //                   <th key={column.id} className={`${column.isSort ? "sorting " : ''} centered-header`}>
// //                     <div className="m-0" {...column.getSortByToggleProps()}>
// //                       {column.render("Header")}
// //                     </div>
// //                   </th>
// //                 ))} */}

// //                 {headerGroup.headers.map(column => {
// //                   return (
// //                     <Fragment>

// //                       <th key={column.id} className={`${column.isSort ? "sorting " : ''}`} style={{ textAlign: column.headerPost ? column.headerPost : '' }}>
// //                         <div className="m-0" {...column.getSortByToggleProps()}>
// //                           {/* <div className="m-0" {...column.getSortByToggleProps()}> */}
// //                           {column.render("Header") + generateSortingIndicator(column)}
// //                         </div>
// //                         {/* <Filter column={column} /> */}
// //                       </th>
// //                     </Fragment>
// //                   );
// //                 })}
// //               </tr>
// //             ))}
// //           </thead>

// //           {isLoading ?
// //             <tbody>
// //               <tr>
// //                 <td colSpan="100%">
// //                   <TableLoader rows={customPageSize} />
// //                 </td>
// //               </tr>
// //             </tbody>
// //             :
// //             <tbody {...getTableBodyProps()}>
// //               {page.length > 0 ?
// //                 page.map(row => {
// //                   prepareRow(row)
// //                   return (
// //                     <Fragment key={row.getRowProps().key}>
// //                       <tr>
// //                         {row.cells.map(cell => {
// //                           if (cell.column.icono) {
// //                             return (
// //                               <td key={cell.id} {...cell.getCellProps()}>
// //                                 {cell.render("Cell")}
// //                               </td>
// //                             );
// //                           } else {
// //                             return (
// //                               <td key={cell.id} {...cell.getCellProps()}>
// //                                 <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilterN) }} />
// //                               </td>
// //                             );
// //                           }
// //                         })}

// //                       </tr>
// //                     </Fragment>
// //                   )


// //                 })
// //                 :
// //                 <tr>
// //                   <td colSpan="100%" style={{ textAlign: 'center', fontSize: '1.15em', padding: '1em', color: '#878787' }}>
// //                     No hay datos disponibles
// //                   </td>
// //                 </tr>
// //               }
// //             </tbody>
// //           }
// //           {/* <tbody {...getTableBodyProps()}>
// //   {page.map(row => {
// //     prepareRow(row);
// //     // Imprimir datos para debug

// //     // Esta condición parece estar controlando si se renderiza o no la fila
// //     // basada en si 'idDeu' incluye el 'globalFilter'. Esto podría ser un problema
// //     // si 'globalFilter' no está destinado a aplicarse solo al 'idDeu'.
// //     if (String(row.original.idDeu).includes(globalFilter)) {
// //       // Si esta condición es verdadera, no se retorna nada, lo cual previene que la fila se renderice.
// //     } else {
// //       return (
// //         <Fragment key={row.getRowProps().key}>
// //           <tr>
// //             {row.cells.map(cell => {
// //               if (cell.column.icono) {
// //                 return (
// //                   <td key={cell.id} {...cell.getCellProps()}>
// //                     {cell.render("Cell")}
// //                   </td>
// //                 );
// //               } else {
// //                 return (
// //                   <td key={cell.id} {...cell.getCellProps()}>
// //                     <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilter) }} />
// //                   </td>
// //                 );
// //               }
// //             })}
// //           </tr>
// //         </Fragment>
// //       );
// //     }
// //   })}
// // </tbody> */}

// //         </Table>
// //       </div>

// //       {
// //         isPagination && (
// //           <Row className="justify-content-between align-items-center">
// //             {isShowingPageLength && <div className="col-sm">
// //               <div className="text-muted">Showing <span className="fw-semibold">{page.length}</span> of <span className="fw-semibold">{data.length}</span> entries</div>
// //             </div>}
// //             <div className={paginationDiv}>
// //               <ul className={pagination}>
// //                 <Pagination count={pageOptions.length - 1} color="primary" onChange={handleChange} />
// //               </ul>
// //             </div>
// //           </Row>
// //         )
// //       }
// //     </Fragment>
// //   )
// // }

// // TableContainer.propTypes = {
// //   preGlobalFilteredRows: PropTypes.any,
// // }

// // export default TableContainer



// import React, { Fragment, useEffect, useMemo, useState } from "react"
// import PropTypes from 'prop-types';
// import { Table, Row, Col, Button, Spinner } from 'reactstrap';
// import { useAsyncDebounce, useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table"
// import Pagination from '@mui/material/Pagination';
// import TableLoader from './loading/TableLoader';
// import { parse, format, isValid } from 'date-fns';

// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
//   setGlobalFilterCli, // Asegúrate de recibir esto
//   setGlobalFilterDeu, // y esto
//   filterAll
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = useState('');
//   const [valueCli, setValueCli] = useState('');
//   const [valueDeu, setValueDeu] = useState('');

//   const onChange = useAsyncDebounce(value => {
//     setGlobalFilter(value ? value.toLowerCase() : '');

//   }, 200);
//   const onChangeCli = useAsyncDebounce(value => {
//     setGlobalFilterCli(value ? value.toLowerCase() : '');
//   }, 200);
//   const onChangeDeu = useAsyncDebounce(value => {
//     setGlobalFilterDeu(value ? value.toLowerCase() : '');
//   }, 200);

//   return (
//     <Fragment>
//       {/* <Col className="row">
//         {/* <Col className="px-3" xs={3}>
//           <input
//             type="search"
//             className="form-control"
//             value={value || ""}
//             placeholder={`Buscar en todos los registros... (${count} registros)`}
//             onChange={e => {
//               setValue(e.target.value);
//               onChange(e.target.value);
//             }}
//           />
//         </Col> 
//         <Col className="d-flex justify-content-end" xs={9}>
//           <Col className="px-3" xs={3}>
//             <input
//               type="search"
//               className="form-control"
//               value={valueCli || ""}
//               placeholder="Filtrar por nombre de cliente..."
//               onChange={e => {
//                 setValueCli(e.target.value);
//                 onChangeCli(e.target.value);
//               }}
//             />
//           </Col>
//           <Col className="px-3" xs={3}>
//             <input
//               type="search"
//               className="form-control"
//               value={valueDeu || ""}
//               placeholder="Filtrar por nombre de deudor..."
//               onChange={e => {
//                 setValueDeu(e.target.value);
//                 onChangeDeu(e.target.value);
//               }}
//             />
//           </Col>
//         </Col>
//       </Col> */}

//       <Row className="d-flex justify-content-between">

//         {filterAll ?
//           <Col className="px-3" xs={5}>
//             <input
//               type="search"
//               className="form-control"
//               value={value || ""}
//               placeholder={`Buscar en todos los registros... (${count} registros)`}
//               onChange={e => {
//                 setValue(e.target.value);
//                 onChange(e.target.value);
//               }}
//             />
//           </Col>
//           :
//           <>
//             <Col className="ps-5" xs={3}>
//               <input
//                 type="search"
//                 className="form-control"
//                 value={valueCli || ""}
//                 placeholder="Filtrar por nombre de cliente..."
//                 onChange={e => {
//                   setValueCli(e.target.value);
//                   onChangeCli(e.target.value);
//                 }}
//               />
//             </Col>
//             <Col className="pe-5 justify-content-end" xs={3}>
//               <input
//                 type="search"
//                 className="form-control"
//                 value={valueDeu || ""}
//                 placeholder="Filtrar por nombre de deudor..."
//                 onChange={e => {
//                   setValueDeu(e.target.value);
//                   onChangeDeu(e.target.value);
//                 }}
//               />
//             </Col>
//           </>
//         }
//       </Row>
//     </Fragment>
//   );
// }

// const TableContainer = ({
//   columns,
//   data,
//   isGlobalFilter,
//   isJobListGlobalFilter,
//   isAddOptions,
//   isAddUserList,
//   handleOrderClicks,
//   handleUserClick,
//   handleCustomerClick,
//   isAddCustList,
//   customPageSize,
//   customPageSizeOptions,
//   iscustomPageSizeOptions,
//   isPagination,
//   isShowingPageLength,
//   paginationDiv,
//   pagination,
//   tableClass,
//   theadClass,
//   isLoading,
//   isTotal,
//   sortName,
//   secondarySortName,
//   sortOrder,
//   selectorRow,
//   selectorCol,
//   rowDisabled,
//   filterAll

// }) => {

//   const [globalFilterN, setGlobalFilter] = useState('');
//   const [globalFilterCli, setGlobalFilterCli] = useState('');
//   const [globalFilterDeu, setGlobalFilterDeu] = useState('');



//   useEffect(() => {
//   }, [globalFilterN]);
//   const filteredData = useMemo(() => {

//     const filtered = data.filter(item => {
//       const globalFilterPass = Object.values(item).some(value => {
//         if (value !== null && value !== undefined) {
//           const valueStr = String(value).toLowerCase();  // Convertir el valor a string y luego a minúsculas
//           return valueStr.includes(globalFilterN.toLowerCase());
//         }
//         return false;
//       });
//       const clientFilterPass = globalFilterCli ? (item.nomCli && item.nomCli.toLowerCase().includes(globalFilterCli.toLowerCase())) : true;
//       const debtorFilterPass = globalFilterDeu ? (item.nomDeu && item.nomDeu.toLowerCase().includes(globalFilterDeu.toLowerCase())) : true;
//       return globalFilterPass && clientFilterPass && debtorFilterPass;
//     });
//     // const sorted = filtered.sort((a, b) => {
//     //   const valueA = a[sortName];
//     //   const valueB = b[sortName];

//     //   if (valueA == null && valueB != null) return sortOrder === 'asc' ? -1 : 1;
//     //   if (valueA != null && valueB == null) return sortOrder === 'asc' ? 1 : -1;
//     //   if (valueA == null && valueB == null) return 0;

//     //   if (typeof valueA === 'string' && typeof valueB === 'string') {
//     //     return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
//     //   }

//     //   if (typeof valueA === 'number' && typeof valueB === 'number') {
//     //     return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
//     //   }

//     //   if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
//     //     return sortOrder === 'asc' ? (valueA === valueB ? 0 : valueA ? 1 : -1) : (valueA === valueB ? 0 : valueA ? -1 : 1);
//     //   }

//     //   // If the types are different, convert to string and compare
//     //   const valueStrA = String(valueA).toLowerCase();
//     //   const valueStrB = String(valueB).toLowerCase();
//     //   return sortOrder === 'asc' ? valueStrA.localeCompare(valueStrB) : valueStrB.localeCompare(valueStrA);
//     // });

//     const sorted = filtered.sort((a, b) => {
//       const valueA = a[sortName];
//       const valueB = b[sortName];
//       const secondaryValueA = secondarySortName ? a[secondarySortName] : null;
//       const secondaryValueB = secondarySortName ? b[secondarySortName] : null;

//       const compareValues = (valA, valB) => {
//         if (valA == null && valB != null) return sortOrder === 'asc' ? -1 : 1;
//         if (valA != null && valB == null) return sortOrder === 'asc' ? 1 : -1;
//         if (valA == null && valB == null) return 0;

//         if (typeof valA === 'string' && typeof valB === 'string') {
//           const dateA = new Date(valA);
//           const dateB = new Date(valB);
//           if (!isNaN(dateA) && !isNaN(dateB)) {
//             return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
//           }
//           return sortOrder === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
//         }

//         if (typeof valA === 'number' && typeof valB === 'number') {
//           return sortOrder === 'asc' ? valA - valB : valB - valA;
//         }

//         if (typeof valA === 'boolean' && typeof valB === 'boolean') {
//           return sortOrder === 'asc' ? (valA === valB ? 0 : valA ? 1 : -1) : (valA === valB ? 0 : valA ? -1 : 1);
//         }

//         const valueStrA = String(valA).toLowerCase();
//         const valueStrB = String(valB).toLowerCase();
//         return sortOrder === 'asc' ? valueStrA.localeCompare(valueStrB) : valueStrB.localeCompare(valueStrA);
//       };

//       const primaryComparison = compareValues(valueA, valueB);
//       if (primaryComparison !== 0 || !secondarySortName) return primaryComparison;

//       // If primary values are equal and secondarySortName is defined, compare secondary values
//       return compareValues(secondaryValueA, secondaryValueB);
//     });

//     return sorted;
//   }, [data, globalFilterN, globalFilterCli, globalFilterDeu]);
//   const {
//     getTableProps,
//     getTableBodyProps,
//     headerGroups,
//     page,
//     prepareRow,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state,
//     preGlobalFilteredRows,
//     state: { pageIndex, pageSize }
//   } = useTable(
//     {
//       columns,
//       data: filteredData,
//       initialState: {
//         pageIndex: 0,
//         pageSize: customPageSize,
//         sortBy: [
//           {
//             desc: true,
//           },
//         ],
//       },
//     },
//     useGlobalFilter,
//     useSortBy,
//     usePagination
//   );

//   const calcularTotal = (rows, accessor) => {
//     const sumaTotal = rows.reduce((acumulador, objeto) => {
//       return acumulador + objeto[accessor];
//     }, 0);
//     const formattedTotal = sumaTotal?.toLocaleString('es-CL', {
//       style: 'currency',
//       currency: 'CLP'
//     });
//     // Estilos condicionales basados en propiedades de la columna
//     return formattedTotal;
//   };
//   const calcularTotalParcial = (rows, accessor) => {
//     const rowsFiltrados = rows.filter(objeto => objeto.condicionTotalParcial);
//     // Sumar los valores de los rows filtrados
//     const sumaTotal = rowsFiltrados.reduce((acumulador, objeto) => {
//       return acumulador + objeto[accessor];
//     }, 0);
//     // const sumaTotal = rows.reduce((acumulador, objeto) => {
//     //   return acumulador + objeto[accessor];
//     // }, 0);
//     const formattedTotal = sumaTotal?.toLocaleString('es-CL', {
//       style: 'currency',
//       currency: 'CLP'
//     });
//     // Estilos condicionales basados en propiedades de la columna
//     return formattedTotal;
//   };

//   function parseAndFormatDate(dateString) {
//     const formats = ["yyyy-MM-dd'T'HH:mm:ss", 'dd-MM-yyyy HH:mm:ss', 'yyyy-MM-dd', 'yyyy-MM', 'dd/MM/yyyy'];  // Añadir formato dd/MM/yyyy
//     let parsedDate;
//     let outputFormat;

//     const referenceDate = new Date();  // Establece una fecha de referencia válida

//     for (let format of formats) {
//       try {
//         parsedDate = parse(dateString, format, referenceDate);
//         if (isValid(parsedDate)) {  // Verifica si la fecha parseada es válida
//           // Decidir el formato de salida basado en el formato de entrada
//           switch (format) {
//             case 'yyyy-MM':
//               outputFormat = 'MM-yyyy';  // Formato de salida para año-mes
//               break;
//             case 'dd/MM/yyyy':
//               outputFormat = 'dd-MM-yyyy';  // Mantener el formato de entrada para la salida
//               break;
//             default:
//               outputFormat = 'dd-MM-yyyy';  // Formato de salida estándar para otras fechas
//               break;
//           }
//           break;  // Si se parsea correctamente, salir del bucle
//         }
//       } catch (error) {
//         console.log(`Error parsing date with format ${format}: ${error}`);
//       }
//     }

//     // Si se ha parseado una fecha y se ha establecido un formato de salida
//     if (parsedDate && outputFormat && isValid(parsedDate)) {
//       return format(parsedDate, outputFormat);
//     } else {
//       return '';  // Manejo de fechas no parseables
//     }
//   }


//   const highlightMatches = (cell, searchTerm) => {
//     let columsEnd = 'padding-right: 0.8em;'
//     let columsIni = 'padding-left: 0.8em;'
//     let columsp = 'padding-left: 0.8em;'
//     let txtTrunc = 'text-truncate'

//     if (!globalFilterCli && !globalFilterDeu) {
//       if (!searchTerm) {
//         if (cell.column.formato === 'moneda') {

//           return `<div style="${cell.column.truncate ?? ''}; text-align: right;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value?.toLocaleString('es-CL', {
//             style: 'currency',
//             currency: 'CLP',
//           })}</div>`;
//         }
//         if (cell.column.formato === 'numero') {
//           return `<div  style="${cell.column.truncate ?? ''}; text-align: right;${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value}</div>`
//         }
//         if (cell.column.formato === 'bool') {
//           return `<div style="${cell.column.truncate ?? ''}; text-align: center;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value ? cell.column.formtTr : cell.column.formtFl}</div>`
//         }
//         if (cell.column.formato === 'rut') {
//           let rut = cell.value
//           let rut_format = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)

//           return `<div  style="${cell.column.truncate ?? ''}; text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${rut_format}</div>`;
//         }
//         if (cell.column.formato === 'string') {
//           return `<div  style="${cell.column.truncate ?? ''}; text-align: left; 
//           ${cell.column.final ? columsEnd : ''}
//           ${cell.column.inicio ? columsIni : ''}
//           ${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}"
//           >
//           ${cell.value}

//           </div>`;

//         }
//         if (cell.column.formato === 'centro') {
//           return `<div  style="${cell.column.truncate ?? ''}; text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value}</div>`;
//         }
//         if (cell.column.formato === 'fecha') {
//           // Corregimos el formato de la fecha a analizar para que coincida con el formato de entrada
//           // const fecha = parse(cell.value, 'yyyy-MM-dd', new Date());
//           // Formateamos la fecha analizada al formato 'DD/MM/YYYY'
//           const fecha = parseAndFormatDate(cell.value);

//           return `<div  style="${cell.column.truncate ?? ''}; text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${fecha}</div>`;
//         }
//         else {
//           // return cell.value; // Si no hay término de búsqueda o el contenido es nulo, devolver el contenido sin cambios
//           return `<div  style="${cell.column.truncate ?? ''}; text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value}</div>`// Si no hay término de búsqueda o el contenido es nulo, devolver el contenido sin cambios
//         }
//       }
//     }

//     // const textContent = cell.value.toString();
//     const textContent = cell.value !== null && cell.value !== undefined ? cell.value.toString() : '';

//     const regex = new RegExp(searchTerm, 'gi');
//     const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
//     // Si la columna es 'Action', se trata de una celda que contiene un icono, así que devolvemos el contenido sin cambios
//     if (cell.column.formato === 'numero' && typeof cell.value === 'number') {
//       // return cell.value.toLocaleString('es-CL', {
//       //   style: 'currency',
//       //   currency: 'CLP',
//       // });;
//       return `<div style="text-align: right; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`
//     }
//     if (cell.column.formato === 'string' && typeof cell.value === 'string') {
//       if (globalFilterCli.length > 0 && cell.column.id === 'nomCli') {
//         const regex = new RegExp(globalFilterCli, 'gi');
//         const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
//         return `<div style="overflow: hidden!important; white-space: nowrap!important; text-overflow: ellipsis!important; max-width: 27em!important; text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;

//       }
//       if (globalFilterDeu.length > 0 && cell.column.id === 'nomDeu') {
//         const regex = new RegExp(globalFilterDeu, 'gi');
//         const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
//         return `<div style="text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;

//       }
//       return `<div style="text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;
//     }

//     if (cell.column.formato === 'fecha' && typeof cell.value === 'string') {
//       // Formateamos la fecha analizada al formato 'DD/MM/YYYY'
//       const fecha = parseAndFormatDate(cell.value);
//       const textContent = fecha;
//       const regex = new RegExp(searchTerm, 'gi');
//       const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);


//       return `<div style="text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;
//     }
//     if (cell.column.Header === 'Action') {
//       return cell.value;
//     }
//     if (cell.column.formato === 'rut' && typeof cell.value === 'string') {
//       let rut = cell.value
//       let rut_format = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)

//       const valorCompleto = cell.value;
//       const valorReducido = searchTerm;
//       const matchPos = valorCompleto.indexOf(valorReducido);
//       const parteAntes = valorCompleto.substring(0, matchPos);
//       const parteMatch = valorReducido;
//       const parteDespues = valorCompleto.substring(matchPos + valorReducido.length);

//       if (matchPos !== -1) {
//         let resultado = insertarSeparadorRut(parteAntes, parteMatch, parteDespues);


//         const highlightedFormattedCurrency = `<div style="text-align: left;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${resultado[0]}<span style="text-align: left class="highlight">${resultado[1]}</span>${resultado[2]}</div>`;
//         // const highlightedFormattedCurrency = `${resultado[0]}<span style="text-align: left class="highlight">${resultado[1]}</span>${resultado[2]}`;
//         return highlightedFormattedCurrency
//       } else {
//         return rut_format
//       }

//     }
//     if (cell.column.formato === 'moneda' && typeof cell.value === 'number') {
//       if (cell.value.toString().includes(searchTerm)) {
//         // Si el término de búsqueda está presente, hacer algo aquí
//         const valorCompleto = cell.value.toString();
//         const valorReducido = searchTerm;
//         const matchPos = valorCompleto.indexOf(valorReducido);
//         const parteAntes = valorCompleto.substring(0, matchPos);
//         const parteMatch = valorReducido;
//         const parteDespues = valorCompleto.substring(matchPos + valorReducido.length);

//         let resultado = insertarSeparadorMiles(parteAntes, parteMatch, parteDespues);

//         const highlightedFormattedCurrency = `<div style="text-align: right;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">$${resultado[0]}<span class="highlight">${resultado[1]}</span>${resultado[2]}</div>`;
//         // const highlightedFormattedCurrency = `$${resultado[0]}<span class="highlight">${resultado[1]}</span>${resultado[2]}`;
//         return highlightedFormattedCurrency
//       } else {
//         // Si el término de búsqueda no está presente, continuar con el procesamiento normal
//         // Continuar con el procesamiento normal del valor
//         return cell.value?.toLocaleString('es-CL', {
//           style: 'currency',
//           currency: 'CLP',
//         });;
//       }
//     }

//     // Reemplazar todas las ocurrencias del término de búsqueda con el mismo término envuelto en un span con una clase de resaltado
//     // const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
//     // return highlightedContent;
//     return `<div style="text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`
//   };


//   function insertarSeparadorRut(num1, num2, num3) {
//     // Concatenar los tres valores
//     let rut = num1 + num2 + num3;

//     let resultadoConPuntos = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8);

//     // Cortar la cadena resultante para restaurar num1, num2 y num3 con los puntos añadidos
//     const puntosEncontrados = resultadoConPuntos.match(/\./g); // Encuentra todos los puntos en la cadena
//     // Ejemplo de uso:
//     let longitudTotal = resultadoConPuntos.length;
//     let rnum1 = ''
//     let rnum2 = ''
//     let rnum3 = ''
//     if (num3) {
//       rnum3 = num3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
//     }
//     if (num1) {
//       rnum1 = resultadoConPuntos.slice(0, num1.length + Math.floor(num1.length / 3))
//     }
//     if (num2) {
//       let preNum2 = resultadoConPuntos.slice(0, rut.length + puntosEncontrados.length - rnum3.length)
//       rnum2 = preNum2.slice(rnum1.length)
//     }
//     return [rnum1, rnum2, rnum3];
//   }

//   const onChangeInSelect = event => {
//     setPageSize(Number(event.target.value))
//   }

//   function insertarSeparadorMiles(num1, num2, num3) {
//     // Concatenar los tres valores
//     let cadenaConcatenada = num1 + num2 + num3;
//     // Agregar puntos como separadores de miles
//     let resultadoConPuntos = '';
//     let contador = 0;
//     for (let i = cadenaConcatenada.length - 1; i >= 0; i--) {
//       // Insertar un punto cada tres dígitos, excepto al principio
//       resultadoConPuntos = cadenaConcatenada[i] + resultadoConPuntos;
//       contador++;
//       if (contador % 3 === 0 && i !== 0) {
//         resultadoConPuntos = '.' + resultadoConPuntos;
//       }
//     }
//     // Cortar la cadena resultante para restaurar num1, num2 y num3 con los puntos añadidos
//     const puntosEncontrados = resultadoConPuntos.match(/\./g); // Encuentra todos los puntos en la cadena
//     // Ejemplo de uso:
//     let longitudTotal = resultadoConPuntos.length;
//     let rnum1 = ''
//     let rnum2 = ''
//     let rnum3 = ''
//     if (num3) {
//       // rnum3 = resultadoConPuntos.substring((num3.length + 1) - ((num3.length -1) % 3));
//       rnum3 = num3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
//     }
//     if (num1) {
//       rnum1 = resultadoConPuntos.slice(0, num1.length + Math.floor(num1.length / 3))
//     }
//     if (num2) {
//       let preNum2 = resultadoConPuntos.slice(0, cadenaConcatenada.length + puntosEncontrados.length - rnum3.length)
//       rnum2 = preNum2.slice(rnum1.length)
//     }
//     return [rnum1, rnum2, rnum3];
//   }

//   const handleChange = (event, value) => {
//     gotoPage(value - 1); // Asegúrate de ajustar el valor para que coincida con el índice base cero
//   };

//   const renderHeader = (header) => {
//     return header.split(' ').map((word, index) => (
//       <React.Fragment key={index}>
//         {word}
//         {index < header.split(' ').length - 1 && <br />}
//       </React.Fragment>
//     ));
//   };

//   return (
//     <Fragment>
//       <Row className="mb-2">
//         {iscustomPageSizeOptions &&
//           <Col md={customPageSizeOptions ? 2 : 1}>
//             <select
//               className="form-select"
//               value={pageSize}
//               onChange={onChangeInSelect}
//             >
//               {[10, 20, 30, 40, 50].map(pageSize => (
//                 <option key={pageSize} value={pageSize}>
//                   Show {pageSize}
//                 </option>
//               ))}
//             </select>
//           </Col>
//         }
//         {isGlobalFilter && (
//           <GlobalFilter
//             preGlobalFilteredRows={preGlobalFilteredRows}
//             // globalFilter={state.globalFilter}
//             setGlobalFilter={setGlobalFilter}
//             setGlobalFilterCli={setGlobalFilterCli} // Asegúrate de pasar esto
//             setGlobalFilterDeu={setGlobalFilterDeu} // y esto
//             isJobListGlobalFilter={isJobListGlobalFilter}
//             filterAll={filterAll}
//           />
//         )}
//         {isAddOptions && (
//           <Col sm="7" xxl="8">
//             <div className="text-sm-end">
//               <Button
//                 type="button"
//                 color="success"
//                 className="btn-rounded  mb-2 me-2"
//                 onClick={handleOrderClicks}
//               >
//                 <i className="mdi mdi-plus me-1" />
//                 Add New Order
//               </Button>
//             </div>
//           </Col>
//         )}
//         {isAddUserList && (
//           <Col sm="7" xxl="8">
//             <div className="text-sm-end">
//               <Button
//                 type="button"
//                 color="primary"
//                 className="btn mb-2 me-2"
//                 onClick={handleUserClick}
//               >
//                 <i className="mdi mdi-plus-circle-outline me-1" />
//                 Create New User
//               </Button>
//             </div>
//           </Col>
//         )}
//         {isAddCustList && (
//           <Col sm="7" xxl="8">
//             <div className="text-sm-end">
//               <Button
//                 type="button"
//                 color="success"
//                 className="btn-rounded mb-2 me-2"
//                 onClick={handleCustomerClick}
//               >
//                 <i className="mdi mdi-plus me-1" />
//                 New Customers
//               </Button>
//             </div>
//           </Col>
//         )}
//       </Row>

//       <div className="table-responsive"   >
//         <Table {...getTableProps()} className={tableClass} style={{ marginBottom: 0 }} size="sm">
//           <thead className={theadClass}>
//             {headerGroups.map(headerGroup => (
//               <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map(column => (
//                   // <th key={column.id} {...column.getSortByToggleProps()}>
//                   <th key={column.id} className={`px-2`} style={{ textAlign: column.headerPost ? column.headerPost : '' }}>
//                     {column.headerSlice ?
//                       renderHeader(column.render("Header"))
//                       :
//                       column.render("Header")
//                     }
//                   </th>
//                 ))}

//               </tr>
//             ))}
//           </thead>
//           {isLoading ?
//             <tbody>
//               <tr>
//                 <td colSpan="100%">
//                   <TableLoader rows={customPageSize} />
//                 </td>
//               </tr>
//             </tbody>
//             :
//             <tbody  {...getTableBodyProps()}>
//               {page.length > 0 ?
//                 page.map(row => {
//                   prepareRow(row)

//                   const isDisabled = row.original[rowDisabled];


//                   // return (
//                   //   <Fragment key={row.getRowProps().key}>
//                   //     <tr>
//                   //       {row.cells.map(cell => {
//                   //         if (cell.column.icono) {
//                   //           return (
//                   //             <td key={cell.id} {...cell.getCellProps()}>
//                   //               {cell.render("Cell")}
//                   //             </td>
//                   //           );
//                   //         } else {
//                   //           let campoTitle = false
//                   //           if(cell.column.title){
//                   //               campoTitle = cell.column.id
//                   //             }
//                   //           if (!!selectorRow) {
//                   //             return (
//                   //               <td key={cell.id} {...cell.getCellProps()} style={{ cursor: 'pointer' }} title={!!cell.row.original[campoTitle]? cell.row.original[campoTitle]: ''} >
//                   //                 <span onClick={() => selectorRow(row.original, false, cell.column.Header)}>
//                   //                   <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilterN) }} />
//                   //                 </span>
//                   //               </td>
//                   //             );
//                   //           } else {
//                   //             return (
//                   //               <td key={cell.id} {...cell.getCellProps()} title={!!cell.row.original[campoTitle]? cell.row.original[campoTitle]: ''}>
//                   //                 <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilterN) }} />
//                   //               </td>
//                   //             );
//                   //           }
//                   //         }
//                   //       })}
//                   //     </tr>
//                   //   </Fragment>
//                   // )

//                   return (
//                     <Fragment key={row.getRowProps().key}>
//                       <tr style={{ position: 'relative' }}>
//                         {row.cells.map(cell => {
//                           if (cell.column.icono) {
//                             return (
//                               <td key={cell.id} {...cell.getCellProps()} style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', position: 'relative' }}>
//                                 <span onClick={() => !isDisabled && selectorRow(row.original, false, cell.column.Header)}>
//                                 {cell.render("Cell")}
//                               </span>

//                               </td>
//                       );
//                           } else {
//                         let campoTitle = false
//                       if (cell.column.title) {
//                         campoTitle = cell.column.id
//                       }
//                       if (!!selectorRow) {
//                               return (
//                       <td key={cell.id} {...cell.getCellProps()} style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', position: 'relative' }} title={!!cell.row.original[campoTitle] ? cell.row.original[campoTitle] : ''} >
//                         {/* <span onClick={() => selectorRow(row.original, false, cell.column.Header)}> */}
//                         <span onClick={() => !isDisabled && selectorRow(row.original, false, cell.column.Header)}>
//                           <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilterN) }} />
//                         </span>
//                       </td>
//                       );
//                             } else {
//                               return (
//                       <td key={cell.id} {...cell.getCellProps()} title={!!cell.row.original[campoTitle] ? cell.row.original[campoTitle] : ''}>
//                         <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilterN) }} />
//                       </td>
//                       );
//                             }
//                           }
//                         })}
//                       {isDisabled && (
//                         <td className="overlay-container" colSpan={row.cells.length}>
//                           <div className="overlay">
//                             <Spinner size='sm' />
//                             <span> Loading...</span>
//                           </div>
//                         </td>
//                       )}
//                     </tr>
//                     </Fragment>
//                   );
//                 })
//           :
//           (
//           <Fragment key="no-data">
//             <tr>
//               <td colSpan="100%" style={{ textAlign: 'center', fontSize: '1.15em', padding: '1em', color: '#878787' }}>
//                 No hay datos disponibles
//               </td>
//             </tr>
//           </Fragment>
//                 )}
//           {isTotal && page.length > 0 ?
//             headerGroups.map(groups => {
//               let group = groups.headers
//               prepareRow(group)
//               return (
//                 <Fragment>
//                   <tr>
//                     {group.cells.map(cells => {
//                       let cell = cells.column
//                       if (cell.totalTitulo) {
//                         return (
//                           <td className='pe-2' style={{ textAlign: 'center' }}>
//                             <b>{cell.totalTituloNombre ? cell.totalTituloNombre : 'Totales'}</b>
//                           </td>
//                         );
//                       }
//                       else if (cell.total) {
//                         return (
//                           <td className='' style={{ textAlign: 'right', ...(cell.final ? { paddingRight: '0.8em' } : {}), ...(cell.padd ? { paddingRight: cell.padd } : {}) }}>
//                             <strong>{calcularTotal(data, cell.id)}</strong>
//                           </td>
//                         );
//                       }
//                       else if (cell.totalParcial) {
//                         return (
//                           <td className='' style={{ textAlign: 'right', ...(cell.final ? { paddingRight: '0.8em' } : {}), ...(cell.padd ? { paddingRight: cell.padd } : {}) }}>
//                             <strong>{calcularTotalParcial(data, cell.id)}</strong>
//                           </td>
//                         );
//                       } else {
//                         return (
//                           <td style={{ textAlign: 'center' }}>
//                           </td>
//                         );
//                       }
//                     })}
//                   </tr>
//                 </Fragment>
//               )
//             }
//             )
//             :
//             null
//           }
//           {/* {headerGroups.map(group =>
//                 group.headers.map(column =>
//                   column.total ? (
//                     <tr key={`total-${column.id}`}>
//                       <td colSpan={column.columns ? column.columns.length : 5} style={{ textAlign: 'center' }}>
//                         Total: {calcularTotal(page, column.id)}
//                       </td>
//                     </tr>
//                   ) :
//                     <tr key={`total-${column.id}`}>
//                       <td colSpan={column.columns ? column.columns.length : 5} style={{ textAlign: 'center' }}>
//                       </td>
//                     </tr>
//                 )
//               )} */}
//         </tbody>
//           }
//       </Table>
//     </div>
//       {
//     isPagination && pageOptions.length > 1 && (
//       <Row className="justify-content-between align-items-center">
//         {isShowingPageLength && <div className="col-sm">
//           <div className="text-muted">Showing <span className="fw-semibold">{page.length}</span> of <span className="fw-semibold">{data.length}</span> entries</div>
//         </div>}
//         <div className={paginationDiv}>
//           <ul className={pagination}>
//             <Pagination count={pageOptions.length} color="primary" onChange={handleChange} />
//           </ul>
//         </div>
//       </Row>
//     )
//   }
//     </Fragment >
//   );


// };

// TableContainer.propTypes = {
//   columns: PropTypes.array.isRequired,
//   data: PropTypes.array.isRequired,
//   isLoading: PropTypes.bool.isRequired,
//   tableClass: PropTypes.string,
//   theadClass: PropTypes.string
// };

// export default TableContainer;





































import React, { Fragment, useEffect, useMemo, useState } from "react"
import PropTypes from 'prop-types';
import { Table, Row, Col, Button, Spinner } from 'reactstrap';
import { useAsyncDebounce, useExpanded, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from "react-table"
import Pagination from '@mui/material/Pagination';
import TableLoader from './loading/TableLoader';
import { parse, format, isValid } from 'date-fns';

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setGlobalFilterCli, // Asegúrate de recibir esto
  setGlobalFilterDeu, // y esto
  filterAll
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState('');
  const [valueCli, setValueCli] = useState('');
  const [valueDeu, setValueDeu] = useState('');

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value ? value.toLowerCase() : '');

  }, 200);
  const onChangeCli = useAsyncDebounce(value => {
    setGlobalFilterCli(value ? value.toLowerCase() : '');
  }, 200);
  const onChangeDeu = useAsyncDebounce(value => {
    setGlobalFilterDeu(value ? value.toLowerCase() : '');
  }, 200);

  return (
    <Fragment>
      {/* <Col className="row">
        {/* <Col className="px-3" xs={3}>
          <input
            type="search"
            className="form-control"
            value={value || ""}
            placeholder={`Buscar en todos los registros... (${count} registros)`}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </Col> 
        <Col className="d-flex justify-content-end" xs={9}>
          <Col className="px-3" xs={3}>
            <input
              type="search"
              className="form-control"
              value={valueCli || ""}
              placeholder="Filtrar por nombre de cliente..."
              onChange={e => {
                setValueCli(e.target.value);
                onChangeCli(e.target.value);
              }}
            />
          </Col>
          <Col className="px-3" xs={3}>
            <input
              type="search"
              className="form-control"
              value={valueDeu || ""}
              placeholder="Filtrar por nombre de deudor..."
              onChange={e => {
                setValueDeu(e.target.value);
                onChangeDeu(e.target.value);
              }}
            />
          </Col>
        </Col>
      </Col> */}

      <Row className="d-flex justify-content-between">

        {filterAll ?
          <Col className="px-3" xs={5}>
            <input
              type="search"
              className="form-control"
              value={value || ""}
              placeholder={`Buscar en todos los registros... (${count} registros)`}
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
            />
          </Col>
          :
          <>
            <Col className="ps-5" xs={3}>
              <input
                type="search"
                className="form-control"
                value={valueCli || ""}
                placeholder="Filtrar por nombre de cliente..."
                onChange={e => {
                  setValueCli(e.target.value);
                  onChangeCli(e.target.value);
                }}
              />
            </Col>
            <Col className="pe-5 justify-content-end" xs={3}>
              <input
                type="search"
                className="form-control"
                value={valueDeu || ""}
                placeholder="Filtrar por nombre de deudor..."
                onChange={e => {
                  setValueDeu(e.target.value);
                  onChangeDeu(e.target.value);
                }}
              />
            </Col>
          </>
        }
      </Row>
    </Fragment>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  customPageSizeOptions,
  iscustomPageSizeOptions,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  tableClass,
  theadClass,
  isLoading,
  isTotal,
  sortName,
  secondarySortName,
  sortOrder,
  selectorRow,
  selectorCol,
  rowDisabled,
  filterAll,
  pageIndex, // Añadir pageIndex como prop
  setPageIndex // Añadir setPageIndex como prop

}) => {

  const [globalFilterN, setGlobalFilter] = useState('');
  const [globalFilterCli, setGlobalFilterCli] = useState('');
  const [globalFilterDeu, setGlobalFilterDeu] = useState('');



  useEffect(() => {
  }, [globalFilterN]);
  const filteredData = useMemo(() => {

    const filtered = data.filter(item => {
      const globalFilterPass = Object.values(item).some(value => {
        if (value !== null && value !== undefined) {
          const valueStr = String(value).toLowerCase();  // Convertir el valor a string y luego a minúsculas
          return valueStr.includes(globalFilterN.toLowerCase());
        }
        return false;
      });
      const clientFilterPass = globalFilterCli ? (item.nomCli && item.nomCli.toLowerCase().includes(globalFilterCli.toLowerCase())) : true;
      const debtorFilterPass = globalFilterDeu ? (item.nomDeu && item.nomDeu.toLowerCase().includes(globalFilterDeu.toLowerCase())) : true;
      return globalFilterPass && clientFilterPass && debtorFilterPass;
    });

    // const sorted = filtered.sort((a, b) => {
    //   const valueA = a[sortName];
    //   const valueB = b[sortName];
    //   const secondaryValueA = secondarySortName ? a[secondarySortName] : null;
    //   const secondaryValueB = secondarySortName ? b[secondarySortName] : null;

    //   const compareValues = (valA, valB) => {
    //     if (valA == null && valB != null) return sortOrder === 'asc' ? -1 : 1;
    //     if (valA != null && valB == null) return sortOrder === 'asc' ? 1 : -1;
    //     if (valA == null && valB == null) return 0;

    //     if (typeof valA === 'string' && typeof valB === 'string') {
    //       const dateA = new Date(valA);
    //       const dateB = new Date(valB);
    //       if (!isNaN(dateA) && !isNaN(dateB)) {
    //         return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    //       }
    //       return sortOrder === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    //     }

    //     if (typeof valA === 'number' && typeof valB === 'number') {
    //       return sortOrder === 'asc' ? valA - valB : valB - valA;
    //     }

    //     if (typeof valA === 'boolean' && typeof valB === 'boolean') {
    //       return sortOrder === 'asc' ? (valA === valB ? 0 : valA ? 1 : -1) : (valA === valB ? 0 : valA ? -1 : 1);
    //     }

    //     const valueStrA = String(valA).toLowerCase();
    //     const valueStrB = String(valB).toLowerCase();
    //     return sortOrder === 'asc' ? valueStrA.localeCompare(valueStrB) : valueStrB.localeCompare(valueStrA);
    //   };

    //   const primaryComparison = compareValues(valueA, valueB);
    //   if (primaryComparison !== 0 || !secondarySortName) return primaryComparison;

    //   // If primary values are equal and secondarySortName is defined, compare secondary values
    //   return compareValues(secondaryValueA, secondaryValueB);
    // });

    const sorted = filtered.sort((a, b) => {
      const valueA = a[sortName];
      const valueB = b[sortName];
      const secondaryValueA = secondarySortName ? a[secondarySortName] : null;
      const secondaryValueB = secondarySortName ? b[secondarySortName] : null;

      const compareValues = (valA, valB) => {
        if (valA == null && valB != null) return sortOrder === 'asc' ? -1 : 1;
        if (valA != null && valB == null) return sortOrder === 'asc' ? 1 : -1;
        if (valA == null && valB == null) return 0;

        if (typeof valA === 'string' && typeof valB === 'string') {
          const [dayA, monthA, yearA] = valA.split('/');
          const [dayB, monthB, yearB] = valB.split('/');

          // Convertir las fechas a formato YYYY-MM-DD para comparación correcta
          const dateA = new Date(`${yearA}-${monthA}-${dayA}`);
          const dateB = new Date(`${yearB}-${monthB}-${dayB}`);

          if (!isNaN(dateA) && !isNaN(dateB)) {
            return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
          }

          return sortOrder === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
        }

        if (typeof valA === 'number' && typeof valB === 'number') {
          return sortOrder === 'asc' ? valA - valB : valB - valA;
        }

        if (typeof valA === 'boolean' && typeof valB === 'boolean') {
          return sortOrder === 'asc' ? (valA === valB ? 0 : valA ? 1 : -1) : (valA === valB ? 0 : valA ? -1 : 1);
        }

        const valueStrA = String(valA).toLowerCase();
        const valueStrB = String(valB).toLowerCase();
        return sortOrder === 'asc' ? valueStrA.localeCompare(valueStrB) : valueStrB.localeCompare(valueStrA);
      };

      const primaryComparison = compareValues(valueA, valueB);
      if (primaryComparison !== 0 || !secondarySortName) return primaryComparison;

      // Si los valores principales son iguales y secondarySortName está definido, comparar los valores secundarios
      return compareValues(secondaryValueA, secondaryValueB);
    });


    return sorted;
  }, [data, globalFilterN, globalFilterCli, globalFilterDeu]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    state: { pageIndex: currentPageIndex, pageSize }

  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: {
        pageIndex, // Set the initial state to the current page index
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    if (setPageIndex && typeof setPageIndex === 'function') {
      setPageIndex(currentPageIndex); // Update the page index state when the current page index changes
    }
  }, [currentPageIndex, setPageIndex]);

  const calcularTotal = (rows, accessor) => {
    const sumaTotal = rows.reduce((acumulador, objeto) => {
      return acumulador + objeto[accessor];
    }, 0);
    const formattedTotal = sumaTotal?.toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP'
    });
    // Estilos condicionales basados en propiedades de la columna
    return formattedTotal;
  };
  const calcularTotalParcial = (rows, accessor) => {
    const rowsFiltrados = rows.filter(objeto => objeto.condicionTotalParcial);
    // Sumar los valores de los rows filtrados
    const sumaTotal = rowsFiltrados.reduce((acumulador, objeto) => {
      return acumulador + objeto[accessor];
    }, 0);
    // const sumaTotal = rows.reduce((acumulador, objeto) => {
    //   return acumulador + objeto[accessor];
    // }, 0);
    const formattedTotal = sumaTotal?.toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP'
    });
    // Estilos condicionales basados en propiedades de la columna
    return formattedTotal;
  };

  function parseAndFormatDate(dateString) {
    const formats = ["yyyy-MM-dd'T'HH:mm:ss", 'dd-MM-yyyy HH:mm:ss', 'yyyy-MM-dd', 'yyyy-MM', 'dd/MM/yyyy'];  // Añadir formato dd/MM/yyyy
    let parsedDate;
    let outputFormat;

    const referenceDate = new Date();  // Establece una fecha de referencia válida

    for (let format of formats) {
      try {
        parsedDate = parse(dateString, format, referenceDate);
        if (isValid(parsedDate)) {  // Verifica si la fecha parseada es válida
          // Decidir el formato de salida basado en el formato de entrada
          switch (format) {
            case 'yyyy-MM':
              outputFormat = 'MM-yyyy';  // Formato de salida para año-mes
              break;
            case 'dd/MM/yyyy':
              outputFormat = 'dd-MM-yyyy';  // Mantener el formato de entrada para la salida
              break;
            default:
              outputFormat = 'dd-MM-yyyy';  // Formato de salida estándar para otras fechas
              break;
          }
          break;  // Si se parsea correctamente, salir del bucle
        }
      } catch (error) {
        console.error(`Error parsing date with format ${format}: ${error}`);
      }
    }

    // Si se ha parseado una fecha y se ha establecido un formato de salida
    if (parsedDate && outputFormat && isValid(parsedDate)) {
      return format(parsedDate, outputFormat);
    } else {
      return '';  // Manejo de fechas no parseables
    }
  }


  const highlightMatches = (cell, searchTerm) => {
    let columsEnd = 'padding-right: 0.8em;'
    let columsIni = 'padding-left: 0.8em;'
    let columsp = 'padding-left: 0.8em;'
    let txtTrunc = 'text-truncate'
    if (!globalFilterCli && !globalFilterDeu) {
      if (!searchTerm) {
        if (cell.column.formato === 'moneda') {

          return `<div style="${cell.column.truncate ?? ''}; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ;text-align: right;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value?.toLocaleString('es-CL', {
            style: 'currency',
            currency: 'CLP',
          })}</div>`;
        }
        if (cell.column.formato === 'numero') {
          return `<div  style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: right;${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value}</div>`
        }
        if (cell.column.formato === 'bool') {
          return `<div style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: center;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value ? cell.column.formtTr : cell.column.formtFl}</div>`
        }
        if (cell.column.formato === 'rut') {
          let rut = cell.value
          let rut_format = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)

          return `<div  style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${rut_format}</div>`;
        }
        if (cell.column.formato === 'rut2') {
          let rut = cell.value
          let rut_format = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)

          return `<div  style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${rut_format}</div>`;
        }
        if (cell.column.formato === 'string') {
          return `<div  style="${cell.column.truncate ?? ''}; text-align: left; 
          ${cell.column.final ? columsEnd : ''}
          ${cell.column.inicio ? columsIni : ''}
          ${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}
          min-width: ${cell.column.minWidth ?? 'auto'};
          "
          >
          ${cell.value}
          
          </div>`;

        }
        if (cell.column.formato === 'centro') {
          return `<div  style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value}</div>`;
        }
        if (cell.column.formato === 'fecha') {
          // Corregimos el formato de la fecha a analizar para que coincida con el formato de entrada
          // const fecha = parse(cell.value, 'yyyy-MM-dd', new Date());
          // Formateamos la fecha analizada al formato 'DD/MM/YYYY'
          const fecha = parseAndFormatDate(cell.value);

          return `<div  style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${fecha}</div>`;
        }
        else {
          // return cell.value; // Si no hay término de búsqueda o el contenido es nulo, devolver el contenido sin cambios
          return `<div  style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${cell.value}</div>`// Si no hay término de búsqueda o el contenido es nulo, devolver el contenido sin cambios
        }
      }
    }
    // const textContent = cell.value.toString();
    const textContent = cell.value !== null && cell.value !== undefined ? cell.value.toString() : '';

    const regex = new RegExp(searchTerm, 'gi');
    const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
    // Si la columna es 'Action', se trata de una celda que contiene un icono, así que devolvemos el contenido sin cambios
    if (cell.column.formato === 'numero' && typeof cell.value === 'number') {
      // return cell.value.toLocaleString('es-CL', {
      //   style: 'currency',
      //   currency: 'CLP',
      // });;
      return `<div style="text-align: right; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`
    }
    if (cell.column.formato === 'string' && typeof cell.value === 'string') {
      if (globalFilterCli.length > 0 && cell.column.id === 'nomCli') {
        const regex = new RegExp(globalFilterCli, 'gi');
        const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
        return `<div style="overflow: hidden!important ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; white-space: nowrap!important; text-overflow: ellipsis!important; max-width: 27em!important; text-align: left; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;

      }
      if (globalFilterDeu.length > 0 && cell.column.id === 'nomDeu') {
        const regex = new RegExp(globalFilterDeu, 'gi');
        const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
        return `<div style="text-align: left ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;

      }
      return `<div style="text-align: left ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;
    }

    if (cell.column.formato === 'fecha' && typeof cell.value === 'string') {
      // Formateamos la fecha analizada al formato 'DD/MM/YYYY'
      const fecha = parseAndFormatDate(cell.value);
      const textContent = fecha;
      const regex = new RegExp(searchTerm, 'gi');
      const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);


      return `<div style="text-align: center ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`;
    }
    if (cell.column.Header === 'Action') {
      return cell.value;
    }
    if (cell.column.formato === 'rut' && typeof cell.value === 'string') {
      let rut = cell.value
      let rut_format = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)

      const valorCompleto = cell.value;
      const valorReducido = searchTerm;
      const matchPos = valorCompleto.indexOf(valorReducido);
      const parteAntes = valorCompleto.substring(0, matchPos);
      const parteMatch = valorReducido;
      const parteDespues = valorCompleto.substring(matchPos + valorReducido.length);

      if (matchPos !== -1) {
        let resultado = insertarSeparadorRut(parteAntes, parteMatch, parteDespues);
        const highlightedFormattedCurrency = `<div style="${cell.column.truncate ?? ''} ; min-width: ${cell.column.minWidth ?? 'auto'} ; max-width: ${cell.column.maxWidth ?? 'auto'} ; text-align: left;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${resultado[0]}<span style="text-align: left class="highlight">${resultado[1]}</span>${resultado[2]}</div>`;
        // const highlightedFormattedCurrency = `${resultado[0]}<span style="text-align: left class="highlight">${resultado[1]}</span>${resultado[2]}`;
        return highlightedFormattedCurrency
      } else {
        return rut_format
      }

    }
    if (cell.column.formato === 'moneda' && typeof cell.value === 'number') {
      if (cell.value.toString().includes(searchTerm)) {
        // Si el término de búsqueda está presente, hacer algo aquí
        const valorCompleto = cell.value.toString();
        const valorReducido = searchTerm;
        const matchPos = valorCompleto.indexOf(valorReducido);
        const parteAntes = valorCompleto.substring(0, matchPos);
        const parteMatch = valorReducido;
        const parteDespues = valorCompleto.substring(matchPos + valorReducido.length);

        let resultado = insertarSeparadorMiles(parteAntes, parteMatch, parteDespues);

        const highlightedFormattedCurrency = `<div style="text-align: right;${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">$${resultado[0]}<span class="highlight">${resultado[1]}</span>${resultado[2]}</div>`;
        // const highlightedFormattedCurrency = `$${resultado[0]}<span class="highlight">${resultado[1]}</span>${resultado[2]}`;
        return highlightedFormattedCurrency
      } else {
        // Si el término de búsqueda no está presente, continuar con el procesamiento normal
        // Continuar con el procesamiento normal del valor
        return cell.value?.toLocaleString('es-CL', {
          style: 'currency',
          currency: 'CLP',
        });;
      }
    }

    // Reemplazar todas las ocurrencias del término de búsqueda con el mismo término envuelto en un span con una clase de resaltado
    // const highlightedContent = textContent.replace(regex, match => `<span class="highlight">${match}</span>`);
    // return highlightedContent;
    return `<div style="text-align: center; ${cell.column.final ? columsEnd : ''}${cell.column.inicio ? columsIni : ''}${cell.column.padd ? 'margin-right: ' + cell.column.padd + ';' : ''}">${highlightedContent}</div>`
  };


  function insertarSeparadorRut(num1, num2, num3) {
    // Concatenar los tres valores
    let rut = num1 + num2 + num3;

    let resultadoConPuntos = rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8);

    // Cortar la cadena resultante para restaurar num1, num2 y num3 con los puntos añadidos
    const puntosEncontrados = resultadoConPuntos.match(/\./g); // Encuentra todos los puntos en la cadena
    // Ejemplo de uso:
    let longitudTotal = resultadoConPuntos.length;
    let rnum1 = ''
    let rnum2 = ''
    let rnum3 = ''
    if (num3) {
      rnum3 = num3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
    if (num1) {
      rnum1 = resultadoConPuntos.slice(0, num1.length + Math.floor(num1.length / 3))
    }
    if (num2) {
      let preNum2 = resultadoConPuntos.slice(0, rut.length + puntosEncontrados.length - rnum3.length)
      rnum2 = preNum2.slice(rnum1.length)
    }
    return [rnum1, rnum2, rnum3];
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  function insertarSeparadorMiles(num1, num2, num3) {
    // Concatenar los tres valores
    let cadenaConcatenada = num1 + num2 + num3;
    // Agregar puntos como separadores de miles
    let resultadoConPuntos = '';
    let contador = 0;
    for (let i = cadenaConcatenada.length - 1; i >= 0; i--) {
      // Insertar un punto cada tres dígitos, excepto al principio
      resultadoConPuntos = cadenaConcatenada[i] + resultadoConPuntos;
      contador++;
      if (contador % 3 === 0 && i !== 0) {
        resultadoConPuntos = '.' + resultadoConPuntos;
      }
    }
    // Cortar la cadena resultante para restaurar num1, num2 y num3 con los puntos añadidos
    const puntosEncontrados = resultadoConPuntos.match(/\./g); // Encuentra todos los puntos en la cadena
    // Ejemplo de uso:
    let longitudTotal = resultadoConPuntos.length;
    let rnum1 = ''
    let rnum2 = ''
    let rnum3 = ''
    if (num3) {
      // rnum3 = resultadoConPuntos.substring((num3.length + 1) - ((num3.length -1) % 3));
      rnum3 = num3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
    if (num1) {
      rnum1 = resultadoConPuntos.slice(0, num1.length + Math.floor(num1.length / 3))
    }
    if (num2) {
      let preNum2 = resultadoConPuntos.slice(0, cadenaConcatenada.length + puntosEncontrados.length - rnum3.length)
      rnum2 = preNum2.slice(rnum1.length)
    }
    return [rnum1, rnum2, rnum3];
  }

  const handleChange = (event, value) => {
    gotoPage(value - 1); // Asegúrate de ajustar el valor para que coincida con el índice base cero
  };

  const renderHeader = (header) => {
    return header.split(' ').map((word, index) => (
      <React.Fragment key={index}>
        {word}
        {index < header.split(' ').length - 1 && <br />}
      </React.Fragment>
    ));
  };
  let finalColumnCount = 0;
  return (
    <Fragment>
      <Row className="mb-2">
        {iscustomPageSizeOptions &&
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        }
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            // globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setGlobalFilterCli={setGlobalFilterCli} // Asegúrate de pasar esto
            setGlobalFilterDeu={setGlobalFilterDeu} // y esto
            isJobListGlobalFilter={isJobListGlobalFilter}
            filterAll={filterAll}
          />
        )}
        {isAddOptions && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive"   >
        <Table {...getTableProps()} className={tableClass} style={{ marginBottom: 0 }} size="sm">
          {/* <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // <th key={column.id} {...column.getSortByToggleProps()}>
                  <th key={column.id} className={`px-2`} style={{ textAlign: column.headerPost ? column.headerPost : '' }}>
                    {column.headerSlice ?
                      renderHeader(column.render("Header"))
                      :
                      column.render("Header")
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead> */}
          <thead className={theadClass}>
            {headerGroups.map(headerGroup => (
              <React.Fragment key={headerGroup.id}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    console.log(finalColumnCount)
                    if (!!column.parent) {
                      finalColumnCount++; // Contamos las columnas finales
                      console.log(finalColumnCount)
                    }

                    console.log(!!column.parent);
                    console.log(column.parent);
                    console.log(column);
                    console.log(column?.columns?.length);

                    const colSpan = column.columns ? column.columns.length : 1;
                    console.log(colSpan)
                    return (
                      <th
                        key={column.id}
                        style={{
                          textAlign: column.headerPost ? column.headerPost : '',
                          paddingLeft: column.headerPaddL ? column.headerPaddL : '1em',
                          paddingRight: column.headerPaddR ? column.headerPaddR : '',
                          minWidth: column.minWidthH ? column.minWidthH : '', // Usa el mínimo definido
                          maxWidth: column.maxWidthH ? column.maxWidthH : '', // Usa el máximo definido
                        }} colSpan={column.columns ? colSpan : undefined} // Asegúrate de que sólo el padre tenga colSpan
                      >
                        {column.headerSlice ? renderHeader(column.render("Header")) : column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              </React.Fragment>
            ))}
          </thead>
          {isLoading ?
            <tbody>
              {Array.from({ length: customPageSize }).map((_, rowIndex) => (
                finalColumnCount === 0 ?
                  <tr key={rowIndex} style={{ position: 'relative' }}>
                    {headerGroups.map((headerGroup, headerGroupIndex) => {
                      console.log(`Header group index: ${headerGroupIndex}`); // Log para cada grupo de encabezados
                      return (
                        <Fragment key={headerGroupIndex}>
                          {headerGroup.headers.map((column, colIndex) => {
                            console.log(`Column index: ${colIndex}`); // Log para cada columna
                            return (
                              <td key={colIndex}>
                                <TableLoader rows={customPageSize} />
                              </td>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                  </tr>
                  :
                  <tr key={rowIndex} style={{ position: 'relative' }}>
                    {Array.from({ length: finalColumnCount }).map((_, colIndex) => (
                      <td key={colIndex}>
                        <TableLoader rows={customPageSize} />
                      </td>
                    ))}
                  </tr>
              ))}
            </tbody>
            :
            <tbody  {...getTableBodyProps()}>
              {page.length > 0 ?
                page.map(row => {
                  prepareRow(row)
                  const isDisabled = row.original[rowDisabled];
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr style={{ position: 'relative' }}>
                        {row.cells.map(cell => {
                          console.log(cell.column.personalStyle)
                          if (cell.column.icono) {
                            if (!cell.column.action) {
                              return (
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            } else {
                              return (
                                <td key={cell.id} {...cell.getCellProps()} style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', position: 'relative', ...(cell.column.personalStyle ? cell.column.personalStyle : {}) }}>
                                  <span onClick={() => !isDisabled && selectorRow(row.original, false, cell.column.Header)}>
                                    {cell.render("Cell")}
                                  </span>

                                </td>
                              );
                            }

                          } else {
                            console.log(cell.column.personalStyle)
                            console.log({ cursor: isDisabled ? 'not-allowed' : 'pointer', position: 'relative', ...(cell.column.personalStyle ? cell.column.personalStyle : {}) });
                            let campoTitle = false
                            if (cell.column.title) {
                              campoTitle = cell.column.id
                            }
                            if (!!selectorRow) {
                              return (
                                <td key={cell.id} {...cell.getCellProps()} style={{ cursor: isDisabled ? 'not-allowed' : 'pointer', position: 'relative', ...(cell.column.personalStyle ? cell.column.personalStyle : {}) }} title={!!cell.row.original[campoTitle] ? cell.row.original[campoTitle] : ''} >
                                  {/* <span onClick={() => selectorRow(row.original, false, cell.column.Header)}> */}
                                  <span onClick={() => !isDisabled && selectorRow(row.original, false, cell.column.Header)}>
                                    <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilterN) }} />
                                  </span>
                                </td>
                              );
                            } else {
                              return (
                                <td key={cell.id} {...cell.getCellProps()} style={{ ...(cell.column.personalStyle ? cell.column.personalStyle : {}) }} title={!!cell.row.original[campoTitle] ? cell.row.original[campoTitle] : ''}>
                                  <div dangerouslySetInnerHTML={{ __html: highlightMatches(cell, globalFilterN) }} />
                                </td>
                              );
                            }
                          }
                        })}
                        {isDisabled && (
                          <td className="overlay-container" colSpan={row.cells.length} style={{ border: '1px solid #dee2e6' }}>
                            <div className="overlay">
                              <Spinner size='sm' type="grow" color="warning" style={{ height: '1em', width: '1em' }} />
                              <span style={{ fontSize: '16px' }} title="Actualizando datos solo para este cliente. Esto puede tomar un minuto..."> &nbsp;&nbsp; Actualizando...</span>
                            </div>
                          </td>
                        )}
                      </tr>
                    </Fragment>
                  );
                })
                :
                (
                  <Fragment key="no-data">
                    <tr>
                      <td colSpan="100%" style={{ textAlign: 'center', fontSize: '1.15em', padding: '1em', color: '#878787' }}>
                        No hay datos disponibles
                      </td>
                    </tr>
                  </Fragment>
                )}
              {isTotal && page.length > 0 ?
                headerGroups.map(groups => {
                  let group = groups.headers
                  prepareRow(group)
                  return (
                    <Fragment>
                      <tr>
                        {group.cells.map(cells => {
                          let cell = cells.column
                          if (cell.totalTitulo) {
                            return (
                              <td className='pe-2' style={{ textAlign: 'center' }}>
                                <b>{cell.totalTituloNombre ? cell.totalTituloNombre : 'Totales'}</b>
                              </td>
                            );
                          }
                          else if (cell.total) {
                            console.log(cell)
                            return (
                              <td
                                className=''
                                style={{
                                  textAlign: 'right',
                                  ...(cell.final ? { paddingRight: '0.8em' } : {}),
                                  ...(cell.padd ? { paddingRight: cell.padd } : {}),
                                  // minWidth: cell.minWidth ?? 'auto',
                                  // maxWidth: cell.maxWidth ?? 'auto'
                                }}
                              >                                <strong>{calcularTotal(data, cell.id)}</strong>
                              </td>
                            );
                          }
                          else if (cell.totalParcial) {
                            return (
                              <td className='' style={{ textAlign: 'right', ...(cell.final ? { paddingRight: '0.8em' } : {}), ...(cell.padd ? { paddingRight: cell.padd } : {}) }}>
                                <strong>{calcularTotalParcial(data, cell.id)}</strong>
                              </td>
                            );
                          } else {
                            return (
                              <td style={{ textAlign: 'center' }}>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    </Fragment>
                  )
                }
                )
                :
                null
              }
            </tbody>
          }
        </Table>
      </div>
      {
        isPagination && pageOptions.length > 1 && (
          <Row className="justify-content-between align-items-center">
            {isShowingPageLength && <div className="col-sm">
              <div className="text-muted">Showing <span className="fw-semibold">{page.length}</span> of <span className="fw-semibold">{data.length}</span> entries</div>
            </div>}
            <div className={paginationDiv}>
              <ul className={pagination}>
                <Pagination count={pageOptions.length} color="primary" onChange={handleChange} page={pageIndex + 1} />
              </ul>
            </div>
          </Row>
        )
      }
    </Fragment >
  );


};



TableContainer.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tableClass: PropTypes.string,
  theadClass: PropTypes.string,
  pageIndex: PropTypes.number.isRequired, // Añadir propTypes para pageIndex
  setPageIndex: PropTypes.func.isRequired, // Añadir propTypes para setPageIndex
};

export default TableContainer;