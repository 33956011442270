import { VentasVsCompras } from '../resumen/graficosBarra/VentasVsCompras'
import { NotData } from '../../shared/alert/notData'
import './cardStyles.css'
export const Card1 = (
  {
    ComVensCr,
    Periodo
  }
) => {
  console.log(ComVensCr)
  let calc = 0
  const periodos = []
  const ventas = []
  const compras = []
  const vtacruzadas = []
  const comcruzadas = []
  let totalV = 0
  let totalC = 0
  const options = {
    year: 'numeric',
    month: 'short',
  };
  const msg = 'Cliente no registra compras o ventas cruzadas.'
  ComVensCr.forEach((datos) => {
    periodos.push(datos.periodo.toString().slice(2));
    ventas.push(datos.ventas - datos.notaCred);
    compras.push(datos.compras);
    vtacruzadas.push(datos.vtaCruzada);
    comcruzadas.push(datos.comCruzada);
    calc = calc + datos.ventas + datos.compras + datos.vtaCruzada + datos.comCruzada;
  });
  ComVensCr.forEach((numeros) => {
    totalV += numeros.ventas;
    totalV -= numeros.notaCred;
    totalC += numeros.compras;
  });
  totalV = totalV / Periodo;
  totalC = totalC / Periodo;
  totalV = (new Intl.NumberFormat('es-IN').format(Math.trunc(totalV)));
  totalC = (new Intl.NumberFormat("es-IN").format(Math.trunc(totalC)));
  return (
    <div className="card"  >
      <div className='row'>
        <div className='col-5'>
          <p className='titGraf mx-4'>Gráfico de ventas vs compras</p>
          <p className='titGraf2 mx-4'>Detalle de ventas registradas según período</p>
        </div>
        <div className='col-5'>
          {calc > 0 ?
            <>
              <p className='titGraf'>Promedio de {Periodo} meses</p>
              <div className='d-flex ps-2'>
                <p className='pe-3 titGraf4 pt-1' style={{ color: '#979797' }}>Compras ${totalC} </p>
                <p className='titGraf4 pt-1' style={{ color: '#4163B9' }}> Ventas ${totalV}</p>
              </div>
            </>
            :
            ''
          }
        </div>
        <div className=' col-2 titGraf3 float-end '>
          <div className='d-flex justify-content-evenl '>
            <p className='px-2 py-1' style={{ color: '#BC9C8C' }}>Compras </p>
          </div>
          <div className='d-flex justify-content-evenl '>
            <p className='px-2' style={{ color: '#B55C48' }}>Compras Cruzadas</p>
          </div>
          <div className='d-flex justify-content-start'>
            <p className='px-2 py-1' style={{ color: '#4163B9' }}>Ventas</p>
          </div>
          <div className='d-flex justify-content-start'>
            <p className='px-2' style={{ color: '#224293' }}>Ventas Cruzadas</p>
          </div>
        </div>
      </div>
      <div className="col-12 card-body">
        {ComVensCr.length !== 0 && calc > 0 ?
          <VentasVsCompras
            periodos={periodos.reverse()}
            ventas={ventas.reverse()}
            vtacruzadas={vtacruzadas.reverse()}
            compras={compras.reverse()}
            comcruzadas={comcruzadas.reverse()}
            mp={Periodo}
          />
          :
          <div className="my-5 pb-3 d-flex justify-content-center">
            <NotData
              msg={msg} />
          </div>
        }
      </div>
    </div>
  )
}

