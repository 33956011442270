import { Card } from "../cards/Card";

export const ModalDetalleGraficoVentasDeudor = ({
    ResVensDdr
}) => {
    const notPropagation = (e) => {
        e.stopPropagation();
    }
    return (
        <div className="modal fade" id="ModalDetalleGraficoVentasDeudor" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
            <div className="modal-dialog" style={{ maxWidth: '88%', width: 'auto', top: '50%',  margin: 'auto',transform: 'translate(0%, -50%)'}}>
                <Card
                    ResVensDdr={ResVensDdr}
                />
            </div>
        </div>
    )
}


