import React, { useState } from 'react';
import { mkConfig, generateCsv, download } from 'export-to-csv'; // or use your library of choice here
import { formatVal } from '../../../helpers/formatVal';
import { ModalBoletinConcursal } from '../../modal/ModalBoletinConcursal';
import ShieldX from '../../../shared/icon/shieldX.svg';
import { ModalBoletinConcursalNew } from '../../modal/ModalBoletinConcursalNew';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

export const SociosAccionistasSII = ({ representante, data }) => {
  console.log(representante);
  console.log(data);
  const [consulta, setConsulta] = useState(null);


  const [modal, setModal] = useState(false);

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const idSelector = (rgId) => {
    setConsulta(rgId);
    toggle();
    console.log('abrir modal del idSelector:', rgId);
  };

  return (
    <>
      <table className="table table-hover tableStandard">
        <thead className="table-info">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">RUT</th>
            <th scope="col">Capital Enterado (M$)</th>
            <th scope="col">Capital</th>
            <th scope="col">Utilidad</th>
            <th scope="col">Fecha</th>
            <th>Boletín Concursal</th>
          </tr>
        </thead>
        <tbody>
          {data && data.map(socio => {
            const fechaFormateada = new Date(socio.soFhIncorporacion.slice(0, 10)).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
            return (
              <tr key={socio.soRut}>
                <td>{socio.soNombre}</td>
                <td>{socio.soRut.length === 9 ? `${socio.soRut.slice(0, 1)}.${socio.soRut.slice(1, 4)}.${socio.soRut.slice(4, 7)}${socio.soRut.slice(7)}` : `${socio.soRut.slice(0, 2)}.${socio.soRut.slice(2, 5)}.${socio.soRut.slice(5, 8)}${socio.soRut.slice(8)}`}</td>
                <td>{formatVal.formatValueCLPFull(socio.soCapitalEnterado)}</td>
                <td>{socio.soPctCapital}</td>
                <td>{socio.soPctUtilidad}</td>
                <td>{fechaFormateada}</td>
                <td>
                  {socio.soBoletinConcursal ? (
                    <span
                      onClick={() => idSelector(socio.rgId)}
                      style={{ marginLeft: '2em', cursor: 'pointer' }}
                      data-bs-toggle="modal"
                      data-bs-target="#ModalBoletinConcursal"
                    >
                      <img src={ShieldX} alt="" width="20" height="20" />
                    </span>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="col">
        <p className='titGraf py-3 mx-3'>Representante Legal</p>
      </div>
      <table className="table table-hover tableStandard">
        <thead className="table-info">
          <tr>
            <th>RUT</th>
            <th>Nombre</th>
            <th>Desde</th>
            <th>Boletín Concursal</th>
          </tr>
        </thead>
        <tbody>
          {representante && representante.map(represent => {
            const fechaFormateada = new Date(represent.reFhDesde.slice(0, 10)).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
            return (
              <tr key={represent.rgId}>
                <td>{represent.reRut.length === 9 ? `${represent.reRut.slice(0, 1)}.${represent.reRut.slice(1, 4)}.${represent.reRut.slice(4, 7)}${represent.reRut.slice(7)}` : `${represent.reRut.slice(0, 2)}.${represent.reRut.slice(2, 5)}.${represent.reRut.slice(5, 8)}${represent.reRut.slice(8)}`}</td>
                <td>{represent.reNombre}</td>
                <td>{fechaFormateada}</td>
                <td>
                  {represent.reBoletinConcursal ? (
                    <span
                      onClick={() => idSelector(represent.reRgId)}
                      style={{ marginLeft: '2em', cursor: 'pointer' }}
                    >
                      <img src={ShieldX} alt="" width="20" height="20" />
                    </span>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <ModalBoletinConcursal consulta={consulta} /> */}
      <ModalBoletinConcursalNew
        modal={modal}
        toggle={() => setModal(!modal)}
        data={consulta}
      />
    </>
  );
};