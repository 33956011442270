import { useEffect, useContext, useState } from 'react';
import { IdContext, PeriodoContext } from '../../context/IdContext';
import { useNavigate } from 'react-router-dom';
import { comercial } from '../../helpers/comercial';
import { TablaComercialFacturasEmitidasDemo } from '../tablas/TablaComercialFacturasEmitidasDemo';
import { TablaComercialVentasDispDemo } from '../tablas/TablaComercialVentasDispDemo';
import { TablaComercialDetalleVentCompCrDemo } from '../tablas/TablaComercialDetalleVentCompCrDemo';
import { formatTXT } from '../../helpers/formatTXT';

export const Comercial = () => {
  const navigate = useNavigate();
  const idProvider = useContext(IdContext);
  const id = idProvider.idProvider
  const perProv = useContext(PeriodoContext);
  const mp = perProv.idPeriodoProv
  const [Facturas, setFacturas] = useState([])
  const [DetVentCompCr, setDetVentCompCr] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCed, setIsLoadingCed] = useState(true);
  const data = JSON.parse(localStorage.user);
  // Accediendo a la propiedad rgIdFact
  const rgIdFact = data.rgIdFact;
  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      comercial.getFacturasNoCedidas(id, rgIdFact).then((r) => {
        if (r.status === 200) {
          setFacturas(formatTXT.corregirArray(r.data.listadoFacturasParaGestion))
          console.log(r.data.listadoFacturasParaGestion)
          setIsLoadingCed(false);
        } else {
          setFacturas([])
          setIsLoadingCed(false);
        }
      }).catch((e) => {
        console.error(e)
      })
    }
  }, [])
  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      let per = 365
      if (mp === 12) {
        per = 365
      } else {
        per = 730
      }
      console.log(id, per, rgIdFact)
      comercial.getVentasComprasCruzadasCliPeriodo(id, per, rgIdFact).then((r) => {
        if (r.status === 200) {
          setDetVentCompCr(formatTXT.corregirArray(r.data.listaVentasComprasCruzadasCli))
          console.log(r.data.listaVentasComprasCruzadasCli)
          setIsLoading(false);
        } else {
          setDetVentCompCr([])
          setIsLoading(false);
        }
      }).catch((e) => {
        console.error(e)
        setIsLoading(false);
      })
    }
  }, [mp])
  // const getColumnNames = (data) => {
  //   // Obtener todas las claves del primer objeto
  //   const allColumnNames = data.length ? Object.keys(data[0]) : [];
  //   // Filtrar columnas sin datos
  //   return allColumnNames.filter(col =>
  //     data.some(row => row[col] !== '' && row[col] !== null && row[col] !== undefined)
  //   );
  // };
  return (
    <div className='row ' >
      <div className="col-5 ps-4 p-2 mt-3">
        <div className="card">
          <TablaComercialVentasDispDemo
            Facturas={Facturas}
            isLoading={isLoadingCed}
          />
        </div>
      </div>
      <div className="col-7  pe-4 p-2  mt-3">
        <div className="card" >
          <TablaComercialFacturasEmitidasDemo
            Facturas={Facturas}
            isLoading={isLoadingCed}
          />
        </div>
      </div>
      <div className="col-12   px-4 p-2">
        <div className="card" >
          <TablaComercialDetalleVentCompCrDemo
            DetVentCompCr={DetVentCompCr}
            mp={mp}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}


