
import { useEffect, useState } from 'react';
import TableContainer from '../../../shared/TableContainer';
import { ModalF29Detalle } from '../modal/ModalF29Detalle';
import { formatVal } from '../../../helpers/formatVal';
export const Formulario29SII = ({
  F29
}) => {
  console.log(F29)
  const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
  const [rowSelect, setRowSelect] = useState([]);
  const [modal, setModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  let isPagination = true
  const isLoading = false
  const pagSiz = 10
  if (F29.length < pagSiz) {
    isPagination = false
  }
  F29.forEach(item => {
    item.estado = item.folio !== '0' ? 'Declarado' : 'Sin Declarar';
});

console.log(F29);
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const idSelector = (row) => {
    setRowSelect(row);  // Establecer la fila seleccionada en el estado
    toggle();
  };
  // Convertir las fechas a formato entendible y ordenar
  F29.sort((a, b) => new Date(b.anoMes) - new Date(a.anoMes));
  /************************ Solucion para el salto de pagina en la tabla *********************************/
  useEffect(() => {
    // Asegúrate de que F29 sea un array antes de proceder
    if (Array.isArray(F29) && F29.length > 0) {
      // Si es necesario, ordena F29 antes de mapear
      let sortedF29 = [...F29].sort((a, b) => b.anoMes - a.anoMes); // Reemplaza 'someField' con el campo apropiado
      let data = sortedF29.map((item, index) => ({
        ...item,
        isRecent: index < 3 // Asigna true a los tres primeros elementos
      }));
      setUpdatedData(data);
    } else {
      // Si F29 no es un array o está vacío, asegura que updatedData sea un array vacío
      setUpdatedData([]);
    }
  }, [F29]);
  /*********************************************************/
  const columns = [
    {
      accessor: 'anoMes',
      Header: 'Periodo',
      headerPost: 'center',
      formato: 'fecha',
    },
    {
      accessor: 'folio',
      Header: 'Folio',
      headerPost: 'center',
      formato: 'numero',
      final: true,
      inicio: true,
      padd: '5em',
    },
    {
      accessor: 'estado',
      Header: 'Estado',
      headerPost: 'center',
      formato: 'string',
    },
    {
      accessor: 'montoPago',
      Header: 'Monto',
      headerPost: 'center',
      formato: 'moneda',
      final: true,
      padd: '5em',
    },
    {
      accessor: 'montoPosterga',
      Header: 'Iva Postergado',
      headerPost: 'center',
      formato: 'moneda',
      final: true,
      padd: '5em',
      icono: true,
      Cell: ({ row }) => {
        const backgroundColor = row.original.isRecent && row.original.montoPosterga > 0 ? '#B55C48' : '#000';
        const fontWeightBold = row.original.isRecent && row.original.montoPosterga > 0 ? 501 : 0 ;
        return (
            <>
                <div style={{ textAlign: 'right', paddingRight: '5em',cursor: 'pointer'  }}  onClick={() => idSelector(row.original)}>
                    <span style={{ color: backgroundColor, fontWeight: fontWeightBold }}>
                        {formatVal.formatValueCLPFull(row.original.montoPosterga)}
                    </span>
                </div>
            </>
        );
    }
    }
  ];
  return (
    <>
      <ModalF29Detalle
        modal={modal}
        toggle={() => setModal(!modal)}
        data={rowSelect}
      />
      <TableContainer
        columns={columns}
        data={updatedData}
        isGlobalFilter={false}
        isAddOptions={false}
        customPageSize={pagSiz}
        isPagination={true}
        filterable={false}
        iscustomPageSizeOptions={false}
        tableClass="TbXs align-middle table-nowrap table-check"
        theadClass="table-light"
        pagination="pagination pagination-rounded justify-content-end mb-2"
        isLoading={isLoading}
        sortName='anoMes'
        sortOrder='asc'
        selectorRow={idSelector}
        pageIndex={pageIndex} // Pasar pageIndex
        setPageIndex={setPageIndex} // Pasar setPageIndex
      />
    </>
  )
}