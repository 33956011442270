import { formatVal } from "../../helpers/formatVal";
import { NotData } from "../../shared/alert/notData";

export const TablaSiiEstadoResultado = ({ EstadoResultado }) => {
    const ingresoCodes = ['1410', '1657', '1658', '1659', '1610', '1660'];
    const costoDirectoCodes = ['1141', '1142', '1159', '1406', '1407', '1408', '1409', '1413', '1416', '1417', '1418', '1420', '1421', '1422', '1423', '1424', '1431', '1611', '1612', '1613', '1614', '1618', '1621', '1623', '1624', '1625', '1661', '1667', '1668', '1669', '1670', '1671'];
    const remuneracionesCodes = ['1411', '1616', '1662'];
    const gastosOperacionalesCodes = ['1140', '1412', '1415', '1617', '1620', '1665'];
    const interesCodes = ['1419', '1622', '1664'];
    const gastosFinancierosCodes = ['1144', '1147', '1148', '1149', '1150', '1151', '1152', '1175', '1176', '1183', '1425', '1426', '1427', '1428', '1429', '1615', '1626', '1627', '1628', '1663', '1666', '1673', '1674', '1675', '1676', '1677', '1678', '1679', '1680', '1681', '1682', '1683', '1684', '1685', '1686', '1687', '1688', '1689', '1818', '1820'];
    const impuestosCodes = ['85', '91'];
    // Otros tipos de códigos...
    // Concatenar todos los arrays en uno solo
    const codesToCheck = [
        ...ingresoCodes,
        ...costoDirectoCodes,
        ...remuneracionesCodes,
        ...gastosOperacionalesCodes,
        ...interesCodes,
        ...gastosFinancierosCodes,
        ...impuestosCodes,
        // ...otros arrays de códigos
    ];
    // Realizar la búsqueda
    const hasCodes = EstadoResultado.some(item => codesToCheck.includes(item.codigo));
    let data = EstadoResultado.length > 0 ? EstadoResultado : [];
    const ingresos = [];
    const costoDirecto = [];
    const margenBruto = [];
    const remuneraciones = [];
    const otrGtosOper = [];
    const resOperac = [];
    const intPagados = [];
    const otrGtosFinanc = [];
    const resAntImpt = [];
    const impuestos = [];
    const resEjecrcicio = [];
    const periodos = [];
    let datosPorAnio = {};

    data.forEach((indice) => {
        if (codesToCheck.includes(indice.codigo)) {
            if (indice.periodo && !periodos.includes(indice.periodo)) {
                periodos.push(indice.periodo);
                datosPorAnio[indice.periodo] = [];
            }
            datosPorAnio[indice.periodo].push(indice);
        }
    });
    const years = Object.keys(datosPorAnio);
    years.forEach((year) => {
        let opIngresos = 0;
        // let valor1661_1660 = 0;
        let opCostoDirecto = 0;
        let opRemuneraciones = 0;
        let opOtrGtosOper = 0;
        let opIntPagados = 0;
        let opOtrGtosFinanc = 0;
        let opImpuestos = 0;
        if (datosPorAnio[year]) {
            datosPorAnio[year].forEach((code) => {
                if (code.codigo === '1410' || code.codigo === '1657' || code.codigo === '1658' || code.codigo === '1659' || code.codigo === '1610' || code.codigo === '1660') {
                    opIngresos += code.valor;
                }
                if (code.codigo === '1411' || code.codigo === '1616' || code.codigo === '1662') {
                    opRemuneraciones += code.valor;
                }
                if (code.codigo === '1140' || code.codigo === '1412' || code.codigo === '1415' || code.codigo === '1617' || code.codigo === '1620' || code.codigo === '1665') {
                    opOtrGtosOper += code.valor;
                }
                if (code.codigo === '1141' || code.codigo === '1142' || code.codigo === '1159' || code.codigo === '1406' || code.codigo === '1407' ||
                    code.codigo === '1408' || code.codigo === '1409' || code.codigo === '1413' || code.codigo === '1416' || code.codigo === '1417' ||
                    code.codigo === '1418' || code.codigo === '1420' || code.codigo === '1421' || code.codigo === '1422' || code.codigo === '1423' ||
                    code.codigo === '1424' || code.codigo === '1611' || code.codigo === '1612' || code.codigo === '1613' || code.codigo === '1614' ||
                    code.codigo === '1618' || code.codigo === '1621' || code.codigo === '1623' || code.codigo === '1624' || code.codigo === '1625' ||
                    code.codigo === '1661' || code.codigo === '1667' || code.codigo === '1668' || code.codigo === '1669' || code.codigo === '1670' ||
                    code.codigo === '1671') {
                    opCostoDirecto += code.valor;
                }
                if (code.codigo === '1431') {
                    opCostoDirecto -= code.valor;
            }
                if (code.codigo === '1419' || code.codigo === '1622' || code.codigo === '1664') {
                    opIntPagados += code.valor;
                }

                if (code.codigo === '1152' || code.codigo === '1176' || code.codigo === '1183' || code.codigo === '1425' || code.codigo === '1426' || code.codigo === '1427' || code.codigo === '1428' ||
                    code.codigo === '1429' || code.codigo === '1615' || code.codigo === '1626' || code.codigo === '1627' || code.codigo === '1628' || code.codigo === '1663' || code.codigo === '1666' ||
                    code.codigo === '1673' || code.codigo === '1679' || code.codigo === '1680' || code.codigo === '1681' || code.codigo === '1682' || code.codigo === '1683' || code.codigo === '1684' ||
                    code.codigo === '1685' || code.codigo === '1686' || code.codigo === '1687' || code.codigo === '1688' || code.codigo === '1689' || code.codigo === '1818' || code.codigo === '1820') {
                    opOtrGtosFinanc += code.valor;
                }
                if (code.codigo === '1144' || code.codigo === '1147' || code.codigo === '1148' || code.codigo === '1149' || code.codigo === '1150' || code.codigo === '1151' || code.codigo === '1175' ||
                    code.codigo === '1674' || code.codigo === '1675' || code.codigo === '1676' || code.codigo === '1677' || code.codigo === '1678') {
                    opOtrGtosFinanc -= code.valor;
                }
                if (code.codigo === '91') {
                    opImpuestos += code.valor;
                }
                if (code.codigo === '85') {
                    opImpuestos -= code.valor;
                }
            });
        }
        if (opIngresos !== 0) {
            ingresos.push({ 'monto': formatVal.formatValueCLP(opIngresos), 'valor': opIngresos })
        } else {
            ingresos.push({ 'monto': '', 'valor': 0 })
        }
        if (opCostoDirecto !== 0) {
            costoDirecto.push({ 'monto': formatVal.formatValueCLP(opCostoDirecto), 'valor': opCostoDirecto })
        } else {
            costoDirecto.push({ 'monto': '', 'valor': 0 })
        }
        let ceroMargenBruto = opIngresos - opCostoDirecto;
        margenBruto.push({ 'monto': formatVal.formatValueCLP(ceroMargenBruto), 'valor': ceroMargenBruto });
        if (opRemuneraciones === 0) {
            remuneraciones.push({ 'monto': '', 'valor': 0 });
        } else {
            remuneraciones.push({ 'monto': formatVal.formatValueCLP(opRemuneraciones), 'valor': opRemuneraciones })
        }
        if (opOtrGtosOper === 0) {
            otrGtosOper.push({ 'monto': '', 'valor': 0 });
        } else {
            otrGtosOper.push({ 'monto': formatVal.formatValueCLP(opOtrGtosOper), 'valor': opOtrGtosOper })
        }
        if (opIntPagados === 0) {
            intPagados.push({ 'monto': '', 'valor': 0 });
        } else {
            intPagados.push({ 'monto': formatVal.formatValueCLP(opIntPagados), 'valor': opIntPagados })
        }
        if (opOtrGtosFinanc === 0) {
            otrGtosFinanc.push({ 'monto': '', 'valor': 0 });
        } else {
            otrGtosFinanc.push({ 'monto': formatVal.formatValueCLP(opOtrGtosFinanc), 'valor': opOtrGtosFinanc })
        }
        if (opImpuestos === 0) {
            impuestos.push({ 'monto': '', 'valor': 0 });
        } else {
            impuestos.push({ 'monto': formatVal.formatValueCLP(opImpuestos), 'valor': opImpuestos });
        }
        let ro = ceroMargenBruto - opOtrGtosOper - opRemuneraciones;
        resOperac.push({ 'monto': formatVal.formatValueCLP(ro), 'valor': ro });
        let rai = ro - opIntPagados - opOtrGtosFinanc;
        resAntImpt.push({ 'monto': formatVal.formatValueCLP(rai), 'valor': rai });
        let resEjer = rai - opImpuestos;
        resEjecrcicio.push({ 'monto': formatVal.formatValueCLP(resEjer), 'valor': resEjer });
    });
    function calcularVariacion(array) {
        if (!periodos.length > 1) {
            return [];
        }
        // Check if there are any empty 'monto' values
        const hasEmptyMonto = array.some(item => item.monto.trim() === '');
        if (hasEmptyMonto) {
            return [];
        }
        const variaciones = [];
        for (let i = 1; i < array.length; i++) {
            // Check if both 'valor' and 'monto' are not 0
            if (array[i].valor === 0 || array[i - 1].valor === 0 || array[i].monto.trim() === '$  0' || array[i - 1].monto.trim() === '$  0') {
                continue; // Skip the iteration if either valor or monto is 0
            }
            const variacion = (((array[i].valor - array[i - 1].valor) * 100) / array[i - 1].valor);
            variaciones.push({ 'monto': formatVal.formatPercent(variacion), 'valor': variacion });
        }
        if (array.length === 1) {
            variaciones.push({ 'monto': array[0]?.monto, 'valor': array[0]?.valor });
        }
        return variaciones;
    }
    const ingresosVariacion = calcularVariacion(ingresos?.slice(-2));
    const costoDirectoVariacion = calcularVariacion(costoDirecto?.slice(-2));
    const margenBrutoVariacion = calcularVariacion(margenBruto?.slice(-2));
    const remuneracionesVariacion = calcularVariacion(remuneraciones?.slice(-2));
    const otrGtosOperVariacion = calcularVariacion(otrGtosOper?.slice(-2));
    const resOperacVariacion = calcularVariacion(resOperac?.slice(-2));
    const intPagadosVariacion = calcularVariacion(intPagados?.slice(-2));
    const otrGtosFinancVariacion = calcularVariacion(otrGtosFinanc?.slice(-2));
    const resAntImptVariacion = calcularVariacion(resAntImpt?.slice(-2));
    const impuestosVariacion = calcularVariacion(impuestos?.slice(-2));
    const resEjecrcicioVariacion = calcularVariacion(resEjecrcicio?.slice(-2));
    return (
        <div className="table-container" style={{ position: 'relative' }}>
            {!hasCodes && (
                <div style={{
                    position: 'absolute',
                    top: '59%',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 10,
                    textAlign: 'center',
                    fontSize: '1.5em'
                }}>
                    <NotData
                        msg='Sin información de SII.' />
                </div>
            )}
            <table className="table table-hover tableStandard mb-0 tb-text-end ">
                <thead className="table-info">
                    <tr>
                        <th scope="col">Estado Resultado <span className='titGraf22'>(Miles de pesos)</span></th>
                        {periodos.reverse().map((periodo, index) => (
                            <th className="text-end" key={index}>
                                {periodo - 1}
                            </th>
                        ))}
                        {periodos.length > 1 && <th className="text-end">Variación</th>}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td> Ingresos </td>
                        {ingresos.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                {ingresosVariacion.length > 0 ? ingresosVariacion[ingresosVariacion.length - 1].monto : ''}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td> Costo Directo </td>
                        {costoDirecto.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                {costoDirectoVariacion.length > 0 ? costoDirectoVariacion[costoDirectoVariacion.length - 1].monto : ''}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td><b>Margen Bruto </b> </td>
                        {margenBruto.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                <b>{periodo.monto}</b>
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                <b>{margenBrutoVariacion.length > 0 ? margenBrutoVariacion[margenBrutoVariacion.length - 1].monto : ''}</b>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td> Remuneraciones </td>
                        {remuneraciones.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                {remuneracionesVariacion.length > 0 ? remuneracionesVariacion[remuneracionesVariacion.length - 1].monto : ''}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td> Otros gtos. operacionales </td>
                        {otrGtosOper.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                {otrGtosOperVariacion.length > 0 ? otrGtosOperVariacion[otrGtosOperVariacion.length - 1].monto : ''}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td> <b>Resultado operacional </b></td>
                        {resOperac.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                <b>{periodo.monto}</b>
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                <b>{resOperacVariacion.length > 0 ? resOperacVariacion[resOperacVariacion.length - 1].monto : ''}</b>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td> Intereses pagados </td>
                        {intPagados.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                {intPagadosVariacion.length > 0 ? intPagadosVariacion[intPagadosVariacion.length - 1].monto : ''}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td> Otros gtos. financieros </td>
                        {otrGtosFinanc.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                {otrGtosFinancVariacion.length > 0 ? otrGtosFinancVariacion[otrGtosFinancVariacion.length - 1].monto : ''}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td><b>Resultados antes de impuestos</b></td>
                        {resAntImpt.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                <b>{periodo.monto}</b>
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                <b>{resAntImptVariacion.length > 0 ? resAntImptVariacion[resAntImptVariacion.length - 1].monto : ''}</b>
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td> Impuestos </td>
                        {impuestos.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                {periodo.monto}
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                {impuestosVariacion.length > 0 ? '' : ''}
                                {/* {impuestosVariacion.length > 0 ? impuestosVariacion[impuestosVariacion.length - 1].monto : ''} */}
                            </td>
                        )}
                    </tr>
                    <tr>
                        <td><b>Resultado ejercicio </b></td>
                        {resEjecrcicio.map((periodo, index) => (
                            <td className="text-end" key={index}>
                                <b>{periodo.monto}</b>
                            </td>
                        ))}
                        {periodos.length > 1 && (
                            <td className="text-end">
                                <b>{resEjecrcicioVariacion.length > 0 ? resEjecrcicioVariacion[resEjecrcicioVariacion.length - 1].monto : ''}</b>
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
        </div>

    );
};