import { IdContext, PeriodoContext } from '../../context/IdContext';
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react'
export const TitleBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { idPeriodoProv, setIdPeriodoProv } = useContext(PeriodoContext);
    const [isPeriodo, setisPeriodo] = useState([])

    const IdNombre = useContext(IdContext);
    // const IdRut = useContext(IdContext);

    const NombreCli = IdNombre.IdNombre
    const rut = IdNombre.IdRut

    // const RutCli = rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8);

    useEffect(() => {
        setisPeriodo(false)
    }, [location.pathname])
    useEffect(() => {
        setIdPeriodoProv(12)

    }, [location.pathname])

    const clickPeriodo = (Periodo) => {
        setisPeriodo(!Periodo)
        if (isPeriodo) {
            setIdPeriodoProv(12)
            navigate(location.pathname);

        } else {
            setIdPeriodoProv(24)
            navigate(location.pathname);
        }
    }
    console.log(location.pathname.toUpperCase().slice(1) === 'GESTION'? 'GESTION DE NEGOCIOS': location.pathname.toUpperCase().slice(1))

    return (
        <div>
            <nav className='row titleBar '>
                <div className=" ps-5  align-self-center col-5">
                    <p className="">
                        <b style={{ color: "#FFF", fontSize:'1em' }}>{ NombreCli ? NombreCli.toUpperCase() : NombreCli  }</b> <span className='px-2'>{NombreCli ?'|'  :''}</span> {NombreCli? rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8): ''}</p>
                </div>
                <div className=" ps-5  align-self-center col-5">
                    <p className="">
                        <b style={{ color: "#FFF", fontSize:'1em' }}>
                        {location.pathname.toUpperCase().slice(1) === 'GESTION'? 'GESTION DE NEGOCIOS': location.pathname.toUpperCase().slice(1)} 
                        </b>
                    </p>
                </div>
                {location.pathname === '/resumen' || location.pathname === '/comercial'?
                <div className=" col-2 dropdown align-self-center text-end ">
                    <button className="btn navsearch " style={{ color: "#FFF", fontSize:12 }} type="button" onClick={(e) => clickPeriodo(isPeriodo)}>
                        Ultimos {idPeriodoProv} meses
                    </button>
                </div>:''
                }
            </nav>
        </div>
    )
}

