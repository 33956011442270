import { useState, useEffect } from 'react';
import { gestion } from '../../helpers/gestion';
import { GraficoCompDeu } from '../gestion/GraficoCompDeu';
import { TablaGestionComercialDetalle } from '../tablas/TablaGestionComercialDetalle';
import { NotData } from '../../shared/alert/notData';



export const ModalDetalleDeudor = ({
    IdDeudor,
    NomDeudor
}) => {
    // const data = JSON.parse(localStorage?.user);
    let data 
    const userDataString = localStorage?.getItem('user'); // Usa getItem para acceder a los valores en localStorage
    if (userDataString) {
        data = JSON.parse(userDataString);
        console.log(data); // Hacer algo con los datos
    } else {
        console.log('No user data found in localStorage.');
    }
    const msg = 'Deudor no tiene cartera vigente.'
    // Accediendo a la propiedad rgIdFact
    const rgIdFact = data?.rgIdFact;
    const [InfoDeudor, setInfoDeudor] = useState([])
    const [InfoDeudorPS, setInfoDeudorPS] = useState([])
    const notPropagation = (e) => {
        e.stopPropagation();
    }
    function validacion(val1, val2) {
        // Asegurarse de que ambos valores son números y que val2 no es cero
        return (typeof val1 === 'number' && typeof val2 === 'number' && val2 !== 0)
            ? (val1 / val2).toFixed(1)  // Realiza la división y formatea a 1 decimal
            : '0.0';  // Devuelve '0.0' si la división no es válida
    }
    useEffect(() => {
        gestion.getCompDeudor(IdDeudor).then((r) => {
            if (r.status === 200) {
                console.log(r.data)
                setInfoDeudor(r.data)
            } else {
                setInfoDeudor([])
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [IdDeudor])
    useEffect(() => {
        gestion.getCompDeudorPS(rgIdFact, IdDeudor).then((r) => {
            if (r.status === 200) {
                console.log(r.data)
                setInfoDeudorPS(r.data.listarComportamientoDeudor)
            } else {
                setInfoDeudorPS([])
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [IdDeudor])
    const factoring = []
    const prosystem = []
    const factoringTb = []
    const prosystemTb = []
    const dat = []
    const periodos = ["montoVigente", "0-15", "16-30", "31-45", "46+"];
    if (InfoDeudor !== undefined) {
        factoringTb.push('Prosystem');
        factoringTb.push(InfoDeudor.crCantDoctos);
        factoringTb.push(InfoDeudor.crMontoOperado);
        factoringTb.push(InfoDeudor.crCantDoctosCancelados);
        // factoringTb.push('Revisar');
        factoringTb.push(InfoDeudor.crCantDoctosCanceladosDeudor);
        factoringTb.push(InfoDeudor.crPromedioDiasPago);
        factoringTb.push(InfoDeudor.crPromedioDiasMora);
        factoringTb.push(InfoDeudor.crCantCobJudCast);
        factoring.push(0);
        factoring.push(InfoDeudor.crMontoVencido_15);
        factoring.push(InfoDeudor.crMontoVencido_16_30);
        factoring.push(InfoDeudor.crMontoVencido_31_45);
        factoring.push(InfoDeudor.crMontoVencido_46);
    }
    if (InfoDeudorPS !== undefined) {
        console.log(InfoDeudor)
        console.log(InfoDeudorPS)
        InfoDeudorPS.forEach((datos) => {
            prosystemTb.push('Factoring');
            prosystemTb.push(datos.cantDoctosOperados);
            prosystemTb.push(datos.montoOperado);
            prosystemTb.push(datos.cantDoctosCancelados);
            // prosystemTb.push('Revisar');
            prosystemTb.push(validacion(datos.diasCompraPago, datos.cantDoctosCancelados));
            prosystemTb.push(validacion(datos.diasVencePago, datos.cantDoctosCancelados));
            prosystemTb.push(validacion(datos.cantGestionCobranza, datos.cantDoctosOperados));
            prosystemTb.push(datos.cantCobJudCastigo);
            prosystem.push(datos.montoVigente);
            prosystem.push(datos.vence0_15);
            prosystem.push(datos.vence16_30);
            prosystem.push(datos.vence31_45);
            prosystem.push(datos.vence46ymas);
        });
    }
    dat.push(prosystemTb);
    dat.push(factoringTb);
    console.log(prosystemTb)
    console.log(factoringTb)

    return (
        <div className="modal fade" id="ModalDetalleDeudor" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content" style={{ width: 'auto', minWidth: '50em' }}>
                    <div className="modal-header">
                        <p className="titGraf " id="exampleModalToggleLabel">{NomDeudor}</p>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    </div>
                    <div className="modal-body">
                        <div className='col-12'>
                            <p className='titGraf  mb-2 text-center'>Operaciones Historicas</p>
                        </div>
                        <TablaGestionComercialDetalle
                            factoringTb={factoringTb}
                            prosystemTb={prosystemTb}
                            dat={dat}
                        />
                    </div>
                    <div className="modal-body">
                        <div className='col-12'>
                            <p className='titGraf mb-2 text-center'>Cartera Actual</p>
                        </div>
                        {factoring.some(elemento => elemento !== 0) && prosystem.some(elemento => elemento !== 0) ? (
                            <>
                                <div className='d-flex justify-content-evenly col-3 titGraf3 float-end'>
                                    <p className='pe-3' style={{ color: '#BC9C8C' }}>Prosystem</p>
                                    <p style={{ color: '#4163B9' }}>Factoring</p>
                                </div>
                                <GraficoCompDeu
                                    periodos={periodos}
                                    factoring={factoring}
                                    prosystem={prosystem}
                                />
                            </>
                        ) : (
                            <div className="my-5 pb-3 d-flex justify-content-center">
                                <NotData msg={msg} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
