import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  export const GraficoCompDeu = (
    {
      factoring,
      prosystem,
      periodos
    }
  ) => {
  // const periodos = ["montoVigente", "0-15", "16-30","31-45", "46+"];
  const options = {
    plugins: {
      legend: {
        display: false
      },
    },
    responsive: true,
    animation: {
      delay: (context) => {
          let delay = 0;
          if (context.type === 'data') {
              delay = context.dataIndex * 30 + context.datasetIndex * 50;
          }
          return delay;
      },
  },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          callback: function(value, index, values) {
              return value / 1e6 + ' M';
          }
      }
      },
    },
  };
  
  const data = {
    labels : periodos,
    datasets: [
      {
        label: 'factoring ',
        data: factoring,
        backgroundColor: '#657AB0',
        stack: 'Stack 0',
      
      },
      {
        label: 'prosystem',
        data: prosystem,
        backgroundColor: '#BC9C8C',
        stack: 'Stack 1',
      },
    ],
  };
  return (
    <>
      <div>
        <Bar id='barraDT' options={options} data={data} />
      </div>
    </>
    
  )
}


