import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { useContext, useEffect, useState } from "react";
import { SII } from "../../../helpers/SII";
import { IdContext } from "../../../context/IdContext";

export const ModalF29Detalle = ({
    modal,
    toggle,
    data
}) => {
    const [fila1, setFila1] = useState([]);
    const [fila2, setFila2] = useState([]);
    const [fila3, setFila3] = useState([]);
    const [fila4, setFila4] = useState([])
    const [showError, setShowError] = useState(false);  // Estado para manejar el error

    const idProvider = useContext(IdContext);
    const id = idProvider.idProvider
    const clienteNombre = idProvider.IdNombre



    // useEffect(() => {
    //     console.log(data.anoMes)
    //     setFila1([])
    //     setFila2([])
    //     setFila3([])
    //     setFila4([])

    //     getSII.getObtenerPresentacionF29(id, data.anoMes).then((r) => {
    //         if (r.status === 200) {
    //             console.log(r.data.listadoFormulario29Presentacion)
    //             const data = r.data.listadoFormulario29Presentacion;

    //             const filteredFila1 = data.filter((item) => item.fila === 1 && item.valor !== 0);
    //             setFila1(filteredFila1);

    //             const filteredFila2 = data.filter((item) => item.fila === 2 && item.valor !== 0);
    //             setFila2(filteredFila2);

    //             const filteredFila3 = data.filter((item) => item.fila === 3 && item.valor !== 0);
    //             setFila3(filteredFila3)

    //             const filteredFila4 = data.filter((item) => item.fila === 4 && item.valor !== 0);
    //             setFila4(filteredFila4)

    //         } else {
    //             console.log('else')
    //         }
    //     }).catch((e) => {
    //         console.error(e)
    //     })
    // }, [modal]);

    useEffect(() => {
        setShowError(false); // Resetear el mensaje de error antes de cada solicitud
        SII.getObtenerPresentacionF29(id, data.anoMes).then((r) => {
            if (r.status === 200) {
                const data = r.data.listadoFormulario29Presentacion;
                // Procesar los datos de la respuesta como antes
                setFila1(data.filter((item) => item.fila === 1 && item.valor !== 0));
                setFila2(data.filter((item) => item.fila === 2 && item.valor !== 0));
                setFila3(data.filter((item) => item.fila === 3 && item.valor !== 0));
                setFila4(data.filter((item) => item.fila === 4 && item.valor !== 0));
            } else {
                setShowError(true); // Mostrar el mensaje de error si el status no es 200
            }
        }).catch((e) => {
            console.error(e);
            // setShowTitle(true); // Considerar también mostrar el error en caso de una excepción
        })
    }, [modal]);

    return (
        <>

            <Modal isOpen={modal} toggle={toggle}  size={showError ? 'md' : 'xl'}>
                <ModalBody>
                    <div className="modal-body" style={{ backgroundColor: showError ? 'transparent' : '#FED9AE', height: '100%' }}>
                        {showError ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                                <div className="alert alert-warning" role="alert">
                                    No tenemos datos para mostrar en este periodo.
                                </div>
                            </div>
                        ) : (
                            <div className=" mx-2 my-2  col table-responsive">
                                <table className="table table-hover tableStandard mb-0 mb-1">
                                    <thead className="table-info">
                                        <tr>
                                            <th scope="col" style={{ width: '70%' }}><h6>DECLARACIÓN MENSUAL Y PAGO SIMULTÁNEO DE IMPUESTOS FORMULARIO 29</h6></th>
                                            <th scope="col" style={{ width: '20%' }}><h6>Folio: {data.folio}</h6></th>
                                            <th scope="col" style={{ width: '10%' }}><h6>{data.anoMes}</h6></th>
                                        </tr>
                                    </thead>
                                </table>
                                <table className="table table-hover tableStandard mb-1">
                                    <thead className="table-info">
                                        <tr>
                                            <th scope="col">Apellido Paterno o razón social:</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'><h5>{clienteNombre}</h5> </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="d-flex mb-1">
                                    <table className="table table-hover tableStandard mb-0 me-1">
                                        <thead className="table-info">
                                            <tr>
                                                <th scope="col">Código</th>
                                                <th scope="col">Glosa</th>
                                                <th scope="col">Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fila1.length > 0 ? (
                                                fila1.map((item) => (
                                                    <tr key={item.codigo}>
                                                        <td>{item.codigo}</td>
                                                        <td>{item.descripcion}</td>
                                                        <td style={{ textAlign: 'right' }}>{item.valor.toLocaleString('ES')}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colspan="3"></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <table className="table table-hover tableStandard mb-0">
                                        <thead className="table-info">
                                            <tr>
                                                <th scope="col">Código</th>
                                                <th scope="col">Glosa</th>
                                                <th scope="col">Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fila2.length > 0 ? (
                                                fila2.map((item) => (
                                                    <tr key={item.codigo}>
                                                        <td>{item.codigo}</td>
                                                        <td>{item.descripcion}</td>
                                                        <td style={{ textAlign: 'right' }}>{item.valor.toLocaleString('ES')}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colspan="3"></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <table className="table table-hover tableStandard mb-0 mb-1" style={{ width: '70%' }}>
                                    <tbody>
                                        {fila3.length > 0 ? (
                                            fila3.map((item) => (
                                                <tr key={item.codigo}>
                                                    <td style={{ backgroundColor: '#D7E0F5' }}>{item.descripcion}</td>
                                                    <td style={{ backgroundColor: '#D7E0F5' }}>{item.codigo}</td>
                                                    <td style={{ textAlign: 'right' }}>{item.valor.toLocaleString('ES')}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colspan="3"></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <table className="table table-hover tableStandard mb-0" style={{ width: '70%' }}>
                                    <thead className="table-info">
                                        <tr>
                                            <th scope="col"><span className="me-5">60</span> % Condonación</th>
                                            <th scope="col"><span className="me-5">922</span> Número de la Resolución</th>
                                            <th scope="col"><span className="me-5">915</span> Fecha de la Condonación</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fila4.length > 0 ? (
                                            fila4.map((item) => (
                                                <tr key={item.codigo}>
                                                    <td>{item.codigo}</td>
                                                    <td>{item.descripcion}</td>
                                                    <td style={{ textAlign: 'right' }}>{item.valor.toLocaleString('ES')}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colspan="3"></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

