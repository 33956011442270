import { useState } from 'react';
import TableContainer from '../../shared/TableContainer';
export const TablaGestionComercialDetalle = ({
    factoringTb,
    prosystemTb,
}) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    const columns = [
        {
            accessor: 'Nombre', //access nested data with dot notation
            Header: '',
            size: 100,
            formato: 'string',
            inicio: true,
            headerSlice: true,
            headerPost: 'center',
        },
        {
            accessor: 'DocOp', //access nested data with dot notation
            Header: 'Documentos Operados',
            size: 100,
            formato: 'centro',
            inicio: true,
            headerSlice: true,
            headerPost: 'center',
        },
        {
            accessor: 'montoOp', //normal accessor
            Header: 'Monto Operado',
            size: 100,
            formato: 'moneda',
            headerSlice: true,
            headerPost: 'center',
        },
        {
            accessor: 'totalDocCan', //normal accessor
            Header: 'Total Documentos Cancelados',
            size: 100,
            formato: 'centro',
            headerPost: 'center',
            headerSlice: true,
        },
        {
            accessor: 'docCanDdr', //normal accessor
            Header: 'Documentos Cancelados Deudor',
            size: 100,
            formato: 'centro',
            headerPost: 'center',
            headerSlice: true,
        },
        {
            accessor: 'fechaRecepcionSII', //normal accessor
            Header: 'Días x̅ Pago',
            size: 100,
            headerPost: 'center',
            formato: 'centro',
            headerSlice: true,
        },
        {
            accessor: 'montoVenta',
            Header: 'Días x̅ Mora',
            formato: 'centro',
            size: 90,
            headerPost: 'center',
            headerSlice: true,
        },
        {
            accessor: 'diasEmision',
            Header: 'Gestión x̅ Cobranza',
            formato: 'centro',
            size: 80,
            headerPost: 'center',
            headerSlice: true,
        },
        {
            accessor: 'crCantCobJudCast',
            Header: 'Cobranza Judicial Castigo',
            size: 80,
            headerPost: 'center',
            headerSlice: true,
        },
    ]
// Función para transformar un array a objeto basado en los accessors de las columnas
function transformArrayToObject(array, columns) {
    const obj = {};
    columns.forEach((column, index) => {
        obj[column.accessor] = array[index];
    });
    return obj;
}
// Crear un nuevo array de objetos combinados
let combinedData = [];
if(factoringTb.length > 0 && prosystemTb.length > 0 ){
    combinedData = [factoringTb, prosystemTb].map(array => transformArrayToObject(array, columns))
}else{
    combinedData = [];
}
console.log(factoringTb)
console.log(prosystemTb)
console.log(combinedData)
    return (
        <>
            <TableContainer
                columns={columns}
                data={combinedData}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={20}
                isPagination={false}
                filterable={false}
                iscustomPageSizeOptions={false}
                tableClass="TbXs align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
                pageIndex={pageIndex} // Pasar pageIndex
                setPageIndex={setPageIndex} // Pasar setPageIndex
            />
        </>

    )
}
