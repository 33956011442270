import { dataApi } from "../api/dataApi";

export const resumen = {
    getResumenVentasDeudor: function(id, periodo) {
        return dataApi.get(`${process.env.REACT_APP_RESUMEN_OBTENER_VENTAS_DEUDOR}${id}/${periodo.idPeriodoProv}`);
    },
    getResumenCesionesCesionario: function(id, periodo) {
        return dataApi.get(`${process.env.REACT_APP_RESUMEN_OBTENER_CONCENTRACION_CESIONES_CESIONARIO}${id}/${periodo.idPeriodoProv}`);
    },
    getResumenCesionarioDeudor: function(id, periodo) {
        return dataApi.get(`${process.env.REACT_APP_RESUMEN_OBTENER_CONCENTRACION_CESIONES_DEUDOR}${id}/${periodo.idPeriodoProv}`);
    },
    getAll: function(id, idFact) {
        console.log(id);
        console.log(idFact);
        return dataApi.get(`${process.env.REACT_APP_RESUMEN_VENTAS_COMPRAS_CRUZADAS}${id}/${idFact}`);
    }
};