import { dataApi } from "../api/dataApi";

export const gestion = {
    getGestionFinanc: function(id) {
        return dataApi.get(`${process.env.REACT_APP_GESTION_DETALLE_GESTION_VENTA_EJECUTIVO}${id}`);
    },
    getCompDeudor: function(idDeu) {
        return dataApi.get(`${process.env.REACT_APP_GESTION_COMP_DEUDOR}${idDeu}`);
    },
    getCompDeudorPS: function(idFactoring, idDeu) {
        return dataApi.get(`${process.env.REACT_APP_GESTION_COMP_DEUDOR_FACTORING}${idFactoring}/${idDeu}`);
    }
};