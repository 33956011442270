import { useState, useEffect, useContext } from "react"
import { TablaGestionComercialDemo } from "../tablas/TablaGestionComercialDemo"

import { IdContext } from "../../context/IdContext";
import { useNavigate } from "react-router-dom";
import { gestion } from "../../helpers/gestion";
import { AuthContext } from "../../auth";
import { formatTXT } from "../../helpers/formatTXT";
import { BotonExcel } from "../../helpers/getDataToExcel";
import { formatVal } from "../../helpers/formatVal";
import { getEstadosFactura } from "../../helpers/getEstadosFactura";

export const GestionFinanciera = () => {
    const { user } = useContext(AuthContext);
    const idProvider = useContext(IdContext);
    const id = idProvider.idProvider
    const [FacturasGC, setFacturasGC] = useState([])
    const [montoDesde, setMontoDesde] = useState(100000)
    const [montoHasta, setMontoHasta] = useState(9000000000)
    const [FechaDesde, setFechaDesde] = useState(0)
    const [FechaHasta, setFechaHasta] = useState(10)
    const [RutDeudor, setRutDeudor] = useState(null)
    const [RutCliente, setRutCliente] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [Params, SetParams] = useState()
    const [columns, setColumns] = useState([]);
    const navigate = useNavigate();
    const tituloComponent = 'GestionDeNegocios'
    const handleMontoDesdeChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Remover cualquier carácter que no sea número
        setMontoDesde(value);
    };

    const handleMontoHastaChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Remover cualquier carácter que no sea número
        setMontoHasta(value);
    };
    const handleReceiveColumns = (cols) => {
        // Clonar el array para evitar modificar el array original directamente
        let updatedCols = [...cols];

        // Eliminar el último elemento
        updatedCols.pop();

        // Actualizar el estado con el nuevo array sin el último elemento
        setColumns(updatedCols);

        console.log("Elemento eliminado:", updatedCols); // Ahora updatedCols contiene el array sin el último elemento
    };
    useEffect(() => {
        // navigate(0);
        if (Params !== undefined) {
            gestion.getGestionFinanc(Params).then((r) => {
                if (r.status === 200) {
                    let pre = formatTXT.corregirFechaArray(r.data.listadoDetalleGestionVentaEjecutivo, 'fechaRecepcionSII')
                    let pre2 = formatTXT.corregirFechaArray(pre, 'fechaDocto')
                    console.log(pre2)
                    setFacturasGC(pre2)
                    setIsLoading(false);
                } else {
                    setFacturasGC([])
                }
            }).catch((e) => {
                console.error(e)
            })
        }
    }, [Params])
    useEffect(() => {
        gestion.getGestionFinanc(user.rgIdFact + '?MontoInicio=250000&MontoFinal=300000000&DiaFinal=5').then((r) => {
            if (r.status === 200) {
                let pre = formatTXT.corregirFechaArray(r.data.listadoDetalleGestionVentaEjecutivo, 'fechaRecepcionSII')
                let pre2 = formatTXT.corregirFechaArray(pre, 'fechaDocto')
                console.log(pre2)

                setFacturasGC(pre2)
                setIsLoading(false);
            } else {
                setFacturasGC([])
                setIsLoading(false);
            }
        }).catch((e) => {
            console.error(e)
            if (e.request.status === 401) {
                localStorage.clear();
                navigate(`/inicio`);
            }
        })
    }, [])
    const handleSubmit = (e) => {
        let strPrueba = user.rgIdFact
        let paramsToAPI = 0
        e.preventDefault();
        if (montoDesde && montoDesde > 0) {
            if (paramsToAPI !== 0) {
                strPrueba = strPrueba + '&' + 'MontoInicio=' + montoDesde
            } else {
                strPrueba = strPrueba + '?' + 'MontoInicio=' + montoDesde
                paramsToAPI++
            }
        }
        if (montoHasta && montoHasta > 0) {
            if (paramsToAPI !== 0) {
                strPrueba = strPrueba + '&' + 'MontoFinal=' + montoHasta
            } else {
                strPrueba = strPrueba + '?' + 'MontoFinal=' + montoHasta
                paramsToAPI++
            }
        }
        if (FechaDesde && FechaDesde > 0) {
            if (paramsToAPI !== 0) {
                strPrueba = strPrueba + '&' + 'DiaInicio=' + FechaDesde
            } else {
                strPrueba = strPrueba + '?' + 'DiaInicio=' + FechaDesde
                paramsToAPI++
            }
        }
        if (FechaHasta && FechaHasta > 0) {
            if (paramsToAPI !== 0) {
                strPrueba = strPrueba + '&' + 'DiaFinal=' + FechaHasta
            } else {
                strPrueba = strPrueba + '?' + 'DiaFinal=' + FechaHasta
                paramsToAPI++
            }
        }
        if (RutDeudor) {
            if (paramsToAPI !== 0) {
                strPrueba = strPrueba + '&' + 'RutDeduor=' + RutDeudor
            } else {
                strPrueba = strPrueba + '?' + 'RutDeduor=' + RutDeudor
                paramsToAPI++
            }
        }
        if (RutCliente) {
            if (paramsToAPI !== 0) {
                strPrueba = strPrueba + '&' + 'RutCliente=' + RutCliente
            } else {
                strPrueba = strPrueba + '?' + 'RutCliente=' + RutCliente
                paramsToAPI++
            }
        }
        SetParams(strPrueba)
    };

    const transformDataExcel = (data, columns) => {
        return data.map(item => {
            let newItem = { ...item };
            columns.forEach(column => {
                const { accessor, formato } = column;
                if (accessor === 'estado') {
                    const { descripcion } = getEstadosFactura(newItem[accessor]);
                    newItem[accessor] = descripcion; // Usa la descripción del estado
                }
            });
            return newItem;
        });
    };
    const transformedDataExcel = transformDataExcel(FacturasGC, columns);

    return (
        <>
            <div className="card mt-4 mx-4">
                <div className="">
                    <form className="" onSubmit={handleSubmit}>
                        <div className="row col-12 ps-5 pt-3 container-fluid d-flex flex-column">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="row col-md-10 col-12">
                                    <div className="row col-4 py-2 ">
                                        <div className="col-md-2">
                                            <label htmlFor="montoDesde" className="col-form-label">Monto</label>
                                        </div>
                                        <div className="col-md-5 px-3">
                                            <input
                                                type="text"
                                                id="montoDesde"
                                                placeholder={formatVal.formatValueCLPFull(montoDesde)}
                                                style={{ height: '2rem', textAlign: 'right' }}
                                                value={formatVal.formatValueCLPFull(montoDesde)}
                                                className="form-control"
                                                onChange={handleMontoDesdeChange}
                                            />                                        </div>
                                        <div className="col-md-5 px-3">
                                            <input
                                                type="text"
                                                id="montoHasta"
                                                placeholder={formatVal.formatValueCLPFull(montoHasta)}
                                                style={{ height: '2rem', textAlign: 'right' }}
                                                value={formatVal.formatValueCLPFull(montoHasta)}
                                                className="form-control"
                                                onChange={handleMontoHastaChange}
                                            />                                        </div>
                                    </div>
                                    <div className="row col-4 py-2 px-4">
                                        <div className="col-md-4 pe-0">
                                            <label htmlFor="fechaDesde" className="col-form-label">Días Emisión</label>
                                        </div>
                                        <div className="col-md-5 d-flex">
                                            <input type="text" id="fechaDesde" placeholder="0" style={{ height: '2rem' , textAlign: 'center' }} className="form-control me-2" onChange={(e) => setFechaDesde(e.target.value)} />
                                            <input type="text" id="fechaHasta" placeholder={FechaHasta} style={{ height: '2rem' , textAlign: 'center'}} value={FechaHasta} className="form-control mx-4" onChange={(e) => setFechaHasta(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-4 pt-2 ms-0 d-flex ps-0 ">
                                        <button className="btn btn-sm w-25 " style={{ background: '#D7E0F5', fontSize: '0.8rem', height: '2rem' }} type="submit">Buscar</button>
                                    </div>
                                </div>
                                <div className='col-md-2 col-12 pe-3 justify-content-end d-flex align-items-center'>
                                    <BotonExcel data={transformedDataExcel} getColumnNames={columns} title={tituloComponent} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 pt-0">
                            <TablaGestionComercialDemo
                                FacturasGC={FacturasGC}
                                isLoading={isLoading}
                                sendColumns={handleReceiveColumns}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}


