import React from 'react';

const EnvironmentBanner = () => {
    const env = process.env.REACT_APP_MOD;
    console.log(env)
    
    // No renderizar nada si estamos en producción
    if (env === 'PROD') {
        return null;
    }

    let color;
    let message;
    switch (env) {
        case 'QA':
            message = 'QA';
            color = 'rgba(38, 113, 7, 0.4)'; // Verde personalizado con 50% de opacidad
            break;
        default:
            message = 'Error de Entorno';
            color = 'rgba(255, 0, 0, 0.4)'; // Rojo con 50% de opacidad
    }

    return (
        <div style={{ backgroundColor: color, fontSize:'0.9em' ,padding: '10px', textAlign: 'center', borderRadius:'0.5em' }}>
            {message}
        </div>
    );
};

export default EnvironmentBanner;