import { useContext, useEffect, useState } from "react";
import { SII } from "../../helpers/SII";
import { IdContext } from "../../context/IdContext";
import { formatTXT } from "../../helpers/formatTXT";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";

export const ModalFormulario22sii = ({
    modal,
    toggle,
    data
}) => {
    const notPropagation = (e) => {
        e.stopPropagation();
    }

    console.log(data?.periodo)

    const idProvider = useContext(IdContext);
    const id = idProvider.idProvider
    const clienteNombre = idProvider.IdNombre
    // const nombreFormateado = clienteNombre?.charAt(0).toUpperCase() + clienteNombre?.slice(1).toLowerCase();

    const [formatData, setFormatData] = useState([])
    const [grupo31, setGrupo31] = useState([])
    const [grupo32, setGrupo32] = useState([])
    const [grupo33, setGrupo33] = useState([])
    const [tabla1Data, setTabla1Data] = useState([]);
    const [tabla2Data, setTabla2Data] = useState([]);
    const [showError, setShowError] = useState(false);  // Estado para manejar el error


    const periodoActual = parseInt(data?.periodo) + 1;

    const getValorByCodigo = (codigo) => {
        if (Array.isArray(codigo) && codigo.length === 0) {
            return ''; // Devuelve un valor por defecto si el código es un array vacío
        }
        const item = formatData.find(d => d.codigo === codigo);
        return item ? item.valor : '';
    };


    useEffect(() => {
        getValorByCodigo('')
        setGrupo31([])
        setGrupo32([])
        setGrupo33([])
        setTabla1Data([])
        setTabla2Data([])

        setShowError(false); // Resetear el mensaje de error antes de cada solicitud


        SII.getObtenerFormulario22rReporte(id, periodoActual).then((r) => {
            console.log(id)
            console.log(periodoActual)
            if (r.status === 200) {

                const data = r.data.listadoFormulario22Reporte;
                console.log(data)

                setFormatData(formatTXT.corregirArray(data))

                const codigosFiltrados31 = data.filter(item => [85, 86, 87].includes(item.codigo));
                const codigosFiltrados32 = data.filter(item => [90, 39, 91].includes(item.codigo));
                const codigosFiltrados33 = data.filter(item => [92, 93, 795, 94].includes(item.codigo));
                setGrupo31(formatTXT.corregirArray(codigosFiltrados31))
                setGrupo32(formatTXT.corregirArray(codigosFiltrados32))
                setGrupo33(formatTXT.corregirArray(codigosFiltrados33))


                // Filtrar y ordenar los datos
                const datosFiltrados = data.filter(item => item.ubicaRepo === 2);
                datosFiltrados.sort((a, b) => a.codigo - b.codigo);

                // Distribuir los datos en las dos tablas intercaladamente a partir del código 15
                const datosTabla1 = [];
                const datosTabla2 = [];

                let enTabla1 = true; // Variable para alternar entre tablas
                datosFiltrados.forEach(item => {
                    if (item.codigo >= 15) {
                        if (enTabla1) {
                            datosTabla1.push(item);
                        } else {
                            datosTabla2.push(item);
                        }
                        enTabla1 = !enTabla1; // Alternar entre tablas
                    }
                });

                // Calcular el número total de elementos intercalados y determinar si es impar
                // const totalElements = datosTabla1.length + datosTabla2.length;

                // Si el número total de elementos es impar, añadir una celda vacía a la tabla correspondiente
                // if (totalElements % 2 !== 0) {
                //     if (datosTabla1.length > datosTabla2.length) {
                //         datosTabla2.push({ codigo: '', descripcion: '', valor: '' });
                //     } 
                //     else {
                //         datosTabla1.push({ codigo: '', descripcion: '', valor: '' });
                //     }
                // }

                // Actualizar los estados con los datos para cada tabla
                setTabla1Data(formatTXT.corregirArray(datosTabla1));
                setTabla2Data(formatTXT.corregirArray(datosTabla2));
            } else {
                setShowError(true); // Mostrar el mensaje de error si el status no es 200
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [id, periodoActual])


    // const getValorByCodigo = (codigo) => {
    //     const item = formatData.find(d => d.codigo === codigo);
    //     return item ? item.valor : '';
    // };


    return (

        // <div className="modal fade" id="ModalModalFormulario22sii" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
        //     <div className="modal-dialog modal-dialog-centered d-flex justify-content-center">
        //         <div className="modal-content" style={{ width: 'auto', minWidth: '1200px' }}>
        //         <div className="modal-body" style={{ backgroundColor: showError ? 'transparent' : '#FFFFFF', height: '100%' }}>
        //             {/* <div className="modal-body"> */}

        //                 {showError ? (
        //                     <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
        //                         <div className="alert alert-warning" role="alert">
        //                             No tenemos datos para mostrar en este periodo.
        //                         </div>
        //                     </div>
        //                 ) : (


        //                     <div className=" mx-2 my-2  col table-responsive">
        //                         <div className="text-center"><h5>AÑO TRIBUTARIO {periodoActual}</h5> </div>
        //                         <div className="text-center"><p className="fs-6">IMPUESTOS ANUALES A LA RENTA</p></div>
        //                         <table className="table table-hover tableStandard mb-0">
        //                             <thead className="table-info">
        //                                 <tr>
        //                                     <th><span>03</span> ROL UNICO</th>
        //                                     <th><span>01</span> Apellido Paterno o razón social</th>
        //                                     <th><span>02</span> Apellido Materno</th>
        //                                     <th><span>05</span> Nombres</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <tr>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(3)}</td>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(1)}</td>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(2)}</td>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(5)}</td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>

        //                         <table className="table table-hover tableStandard mb-0">
        //                             <thead className="table-info">
        //                                 <tr>
        //                                     <th><span>06</span> Calle; N°; Of; Depto.</th>
        //                                     <th><span>09</span> Teléfono</th>
        //                                     <th><span>08</span> Comuna</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <tr>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(6)}</td>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(9)}</td>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(8)}</td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>

        //                         <table className="table table-hover tableStandard mb-0">
        //                             <thead className="table-info">
        //                                 <tr>
        //                                     <th><span>13</span> Actividad, profesión o giro del negocio</th>
        //                                     <th><span>14</span> Código actividad económica</th>
        //                                     <th><span>90</span> RUT del Representante</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <tr>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(13)}</td>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(14)}</td>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(90)}</td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>


        //                         <table className="table table-hover tableStandard mb-3">
        //                             <thead className="table-info">
        //                                 <tr>
        //                                     <th><span>55</span> Correo Electrónico</th>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <tr>
        //                                     <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(55)}</td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>

        //                         <div className="d-flex mb-1">
        //                             <table className="table table-hover tableStandard row">
        //                                 <tbody className="col-6">
        //                                     {tabla1Data.length > 0 ? (
        //                                         tabla1Data.map((item, index) => (
        //                                             <tr key={index}>
        //                                                 <td className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F9C0A6', width: '50px', height: '59px' }}>{item.codigo}</td>
        //                                                 <td style={{ backgroundColor: '#EAEAEA', minHeight: '5em', }}>
        //                                                     <span className="d-flex align-items-center" style={{ height: '100%', width: '380px' }}>
        //                                                         {item.descripcion}
        //                                                     </span>
        //                                                 </td>
        //                                                 <td className="d-flex justify-content-end align-items-center" style={{ minHeight: '5em', width: '120px', maxWidth: '120px', cursor: 'pointer' }}>
        //                                                     <span className="cardDot2" title={item.valor}>
        //                                                         {item.valor}
        //                                                     </span>
        //                                                 </td>
        //                                             </tr>
        //                                         ))
        //                                     ) : (
        //                                         <tr>
        //                                             <td colSpan="3"></td>
        //                                         </tr>
        //                                     )}
        //                                 </tbody>

        //                                 <tbody className="col-6">
        //                                     {tabla2Data.length > 0 ? (
        //                                         tabla2Data.map((item, index) => (
        //                                             <tr key={index}>
        //                                                 <td className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F9C0A6', width: '50px', height: '59px' }}>{item.codigo}</td>
        //                                                 <td style={{ backgroundColor: '#EAEAEA', minHeight: '5em' }}>
        //                                                     <span className="d-flex align-items-center " style={{ height: '100%', width: '380px' }}>
        //                                                         {item.descripcion}
        //                                                     </span>
        //                                                 </td>
        //                                                 <td className="d-flex justify-content-end align-items-center" style={{ minHeight: '5em', width: '120px', maxWidth: '120px', cursor: 'pointer' }}>
        //                                                     <span className="cardDot2" title={item.valor}>
        //                                                         {item.valor}
        //                                                     </span>
        //                                                 </td>
        //                                             </tr>
        //                                         ))
        //                                     ) : (
        //                                         <tr>

        //                                             <td colSpan="3" className="row"></td>
        //                                         </tr>

        //                                     )}
        //                                 </tbody>
        //                             </table>
        //                         </div>

        //                         <table className="table table-hover tableStandard mb-0">
        //                             <thead className="table-info">
        //                                 <tr>
        //                                     <th colSpan='4'>FOLIO N° {data?.nroFicha} </th>
        //                                 </tr>
        //                             </thead>
        //                         </table>

        //                         <div className="d-flex mb-1">

        //                             <table className="table table-hover tableStandard mb-3 ">
        //                                 <thead className="table-info">
        //                                     <tr>
        //                                         <th colSpan='3'>REMANENTE DE CREDITO</th>
        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                     {grupo31.length > 0 ? (
        //                                         grupo31.map((item, index) => (
        //                                             <tr key={index}>
        //                                                 <td style={{ backgroundColor: '#EAEAEA' }}>{item.descripcion}</td>
        //                                                 <td style={{ backgroundColor: '#F9C0A6' }}>
        //                                                     <div className="d-flex justify-content-center align-items-center">
        //                                                         <strong>{item.codigo}</strong>
        //                                                     </div>
        //                                                 </td>
        //                                                 <td className="text-end" style={{ minHeight: '40px' }}>{item.valor}</td>
        //                                             </tr>
        //                                         ))
        //                                     ) : (
        //                                         <tr>
        //                                             <td colSpan="3" style={{ minHeight: '3em' }}></td>
        //                                         </tr>
        //                                     )}
        //                                 </tbody>
        //                             </table>
        //                             <table className="table table-hover tableStandard mb-3 ">
        //                                 <thead className="table-info">
        //                                     <tr>
        //                                         <th colSpan='3'>IMPUESTO A PAGAR</th>

        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                     {grupo32.length > 0 ? (
        //                                         grupo32.map((item, index) => (
        //                                             <tr key={index}>
        //                                                 <td style={{ backgroundColor: '#EAEAEA' }}>{item.descripcion}</td>
        //                                                 <td className="text-center" style={{ backgroundColor: '#F9C0A6' }}><strong>{item.codigo}</strong></td>
        //                                                 <td className="text-end" >{item.valor}</td>
        //                                             </tr>
        //                                         ))
        //                                     ) : (
        //                                         <tr>
        //                                             <td colSpan="3"></td>
        //                                         </tr>
        //                                     )}

        //                                 </tbody>
        //                                 <thead>
        //                                     <tr>
        //                                         <th colSpan='3'>RECARGOS POR DECLARACIÓN FUERA DE PLAZO (RECARGOS POR MORA
        //                                             EN EL PAGO)</th>
        //                                     </tr>
        //                                 </thead>
        //                                 <tbody>
        //                                     {grupo33.length > 0 ? (
        //                                         grupo33.map((item, index) => (
        //                                             <tr key={index}>
        //                                                 <td style={{ backgroundColor: '#EAEAEA' }}>{item.descripcion}</td>
        //                                                 <td className="text-center" style={{ backgroundColor: '#F9C0A6' }}>
        //                                                     <strong >{item.codigo}</strong>
        //                                                 </td>
        //                                                 <td className="text-end">{item.valor}</td>
        //                                             </tr>
        //                                         ))
        //                                     ) : (
        //                                         <tr>
        //                                             <td colSpan="4"></td>
        //                                         </tr>
        //                                     )}

        //                                 </tbody>
        //                             </table>
        //                         </div>

        //                         <div className="d-flex flex-row justify-content-end">
        //                             <div className="w-50">
        //                                 <table className="table table-hover tableStandard mb-3">
        //                                     <thead className="table-info">
        //                                         <tr>
        //                                             <th colSpan='2'>PAGO DIFERIDO ART.72 LEY RENTA</th>
        //                                         </tr>
        //                                         <tr>
        //                                             <th>FOLIO</th>
        //                                             <th><span>98</span> VALOR PAGADO</th>
        //                                         </tr>
        //                                     </thead>
        //                                     <tbody>
        //                                         <tr>
        //                                             <td style={{ backgroundColor: '#EAEAEA' }}>{data?.nroFicha}</td>
        //                                             <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(90)}</td>
        //                                         </tr>
        //                                     </tbody>
        //                                 </table>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 )}

        //             </div>
        //         </div>
        //     </div>
        // </div>

        <Modal isOpen={modal} toggle={toggle} style={{ width: 'auto', minWidth: '1200px' }}>
            {/* <div className="modal-body" style={{ backgroundColor: showError ? 'transparent' : '#FFFFFF', height: '100%' }}> */}
            <ModalBody style={{ backgroundColor: showError ? 'transparent' : '#FFFFFF', height: '100%' }}>
                {/* <div className="modal-body"> */}

                    {showError ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                            <div className="alert alert-warning" role="alert">
                                No tenemos datos para mostrar en este periodo.
                            </div>
                        </div>
                    ) : (


                        <div className=" mx-2 my-2  col table-responsive">
                            <div className="text-center"><h5>AÑO TRIBUTARIO {periodoActual}</h5> </div>
                            <div className="text-center"><p className="fs-6">IMPUESTOS ANUALES A LA RENTA</p></div>
                            <table className="table table-hover tableStandard mb-0">
                                <thead className="table-info">
                                    <tr>
                                        <th><span>03</span> ROL UNICO</th>
                                        <th><span>01</span> Apellido Paterno o razón social</th>
                                        <th><span>02</span> Apellido Materno</th>
                                        <th><span>05</span> Nombres</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(3)}</td>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(1)}</td>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(2)}</td>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(5)}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table table-hover tableStandard mb-0">
                                <thead className="table-info">
                                    <tr>
                                        <th><span>06</span> Calle; N°; Of; Depto.</th>
                                        <th><span>09</span> Teléfono</th>
                                        <th><span>08</span> Comuna</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(6)}</td>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(9)}</td>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(8)}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table table-hover tableStandard mb-0">
                                <thead className="table-info">
                                    <tr>
                                        <th><span>13</span> Actividad, profesión o giro del negocio</th>
                                        <th><span>14</span> Código actividad económica</th>
                                        <th><span>90</span> RUT del Representante</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(13)}</td>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(14)}</td>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(90)}</td>
                                    </tr>
                                </tbody>
                            </table>


                            <table className="table table-hover tableStandard mb-3">
                                <thead className="table-info">
                                    <tr>
                                        <th><span>55</span> Correo Electrónico</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(55)}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="d-flex mb-1">
                                <table className="table table-hover tableStandard row">
                                    <tbody className="col-6">
                                        {tabla1Data.length > 0 ? (
                                            tabla1Data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F9C0A6', width: '50px', height: '59px' }}>{item.codigo}</td>
                                                    <td style={{ backgroundColor: '#EAEAEA', minHeight: '5em', }}>
                                                        <span className="d-flex align-items-center" style={{ height: '100%', width: '380px' }}>
                                                            {item.descripcion}
                                                        </span>
                                                    </td>
                                                    <td className="d-flex justify-content-end align-items-center" style={{ minHeight: '5em', width: '120px', maxWidth: '120px', cursor: 'pointer' }}>
                                                        <span className="cardDot2" title={item.valor}>
                                                            {item.valor}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3"></td>
                                            </tr>
                                        )}
                                    </tbody>

                                    <tbody className="col-6">
                                        {tabla2Data.length > 0 ? (
                                            tabla2Data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F9C0A6', width: '50px', height: '59px' }}>{item.codigo}</td>
                                                    <td style={{ backgroundColor: '#EAEAEA', minHeight: '5em' }}>
                                                        <span className="d-flex align-items-center " style={{ height: '100%', width: '380px' }}>
                                                            {item.descripcion}
                                                        </span>
                                                    </td>
                                                    <td className="d-flex justify-content-end align-items-center" style={{ minHeight: '5em', width: '120px', maxWidth: '120px', cursor: 'pointer' }}>
                                                        <span className="cardDot2" title={item.valor}>
                                                            {item.valor}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>

                                                <td colSpan="3" className="row"></td>
                                            </tr>

                                        )}
                                    </tbody>
                                </table>
                            </div>

                            <table className="table table-hover tableStandard mb-0">
                                <thead className="table-info">
                                    <tr>
                                        <th colSpan='4'>FOLIO N° {data?.nroFicha} </th>
                                    </tr>
                                </thead>
                            </table>

                            <div className="d-flex mb-1">

                                <table className="table table-hover tableStandard mb-3 ">
                                    <thead className="table-info">
                                        <tr>
                                            <th colSpan='3'>REMANENTE DE CREDITO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grupo31.length > 0 ? (
                                            grupo31.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ backgroundColor: '#EAEAEA' }}>{item.descripcion}</td>
                                                    <td style={{ backgroundColor: '#F9C0A6' }}>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <strong>{item.codigo}</strong>
                                                        </div>
                                                    </td>
                                                    <td className="text-end" style={{ minHeight: '40px' }}>{item.valor}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" style={{ minHeight: '3em' }}></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <table className="table table-hover tableStandard mb-3 ">
                                    <thead className="table-info">
                                        <tr>
                                            <th colSpan='3'>IMPUESTO A PAGAR</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grupo32.length > 0 ? (
                                            grupo32.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ backgroundColor: '#EAEAEA' }}>{item.descripcion}</td>
                                                    <td className="text-center" style={{ backgroundColor: '#F9C0A6' }}><strong>{item.codigo}</strong></td>
                                                    <td className="text-end" >{item.valor}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3"></td>
                                            </tr>
                                        )}

                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th colSpan='3'>RECARGOS POR DECLARACIÓN FUERA DE PLAZO (RECARGOS POR MORA
                                                EN EL PAGO)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grupo33.length > 0 ? (
                                            grupo33.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ backgroundColor: '#EAEAEA' }}>{item.descripcion}</td>
                                                    <td className="text-center" style={{ backgroundColor: '#F9C0A6' }}>
                                                        <strong >{item.codigo}</strong>
                                                    </td>
                                                    <td className="text-end">{item.valor}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4"></td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                            </div>

                            <div className="d-flex flex-row justify-content-end">
                                <div className="w-50">
                                    <table className="table table-hover tableStandard mb-3">
                                        <thead className="table-info">
                                            <tr>
                                                <th colSpan='2'>PAGO DIFERIDO ART.72 LEY RENTA</th>
                                            </tr>
                                            <tr>
                                                <th>FOLIO</th>
                                                <th><span>98</span> VALOR PAGADO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ backgroundColor: '#EAEAEA' }}>{data?.nroFicha}</td>
                                                <td style={{ backgroundColor: '#EAEAEA' }}>{getValorByCodigo(90)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}

                </ModalBody>
    </Modal>
    )
}



