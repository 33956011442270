import { useState } from "react"
import { IdContext, PeriodoContext } from "./IdContext"

export const IdProvider = ({children}) => {
  const [idFactProvider, setIdFactProvider] = useState();
  const [idProvider, setIdProvider] = useState();
  const [idPeriodoProv, setIdPeriodoProv] = useState();
  const [IdNombre, setIdNombre] = useState();
  const [IdRut, setIdRut] = useState();
  
  return (
      <IdContext.Provider value={{idProvider, setIdProvider, IdNombre, setIdNombre, IdRut, setIdRut, idFactProvider, setIdFactProvider}}>
        <PeriodoContext.Provider value={{idPeriodoProv, setIdPeriodoProv}}>
          { children }
        </PeriodoContext.Provider>
      </IdContext.Provider>
  )
}

