// import TableContainer from '../../shared/TableContainer';

// export const TablaBoletin = (boletinSel) => {
//     console.log(boletinSel)
//     const boletin = boletinSel.boletinSel
//     let isLoading = false
//     let isPagination = true
//     const pagSiz = 20
//     if (boletin.length < pagSiz) {
//         isPagination = false
//     }
//     const columns = [
//         {
//             accessor: 'bcRol', // access nested data with dot notation
//             Header: 'Rol',
//             formato: 'string',
//             inicio: true
//         },
//         {
//             accessor: 'bcboFechaInicio',
//             Header: 'Fecha Inicio',
//             formato: 'string',
//             inicio: true
//         },
//         {
//             accessor: 'bcboFechaUltima', // normal accessor
//             Header: 'Fecha Última',
//             formato: 'string',
//             inicio: true
//         },
//         {
//             accessor: 'bcliDescrip',
//             Header: 'Liquidador',
//             formato: 'string',
//             inicio: true
//         },
//         {
//             accessor: 'bcprDescrip',
//             Header: 'Procedimiento',
//             formato: 'string',
//             inicio: true
//         },
//         {
//             accessor: 'bctrTribunal',
//             Header: 'Tribunal',
//             formato: 'string',
//             inicio: true
//         }
//     ];
//     return (
//         <TableContainer
//             columns={columns}
//             data={boletin}
//             isGlobalFilter={false}
//             isAddOptions={false}
//             customPageSize={pagSiz}
//             isPagination={isPagination}
//             filterable={false}
//             iscustomPageSizeOptions={false}
//             tableClass="TbXs align-middle table-nowrap table-check"
//             theadClass="table-light"
//             pagination="pagination pagination-rounded justify-content-end mb-2"
//             isLoading={isLoading}
//             isTotal={true}
//             sortName='fechaEmision'
//             sortOrder='desc'
//         />
//     )
// }
import React, { useState, useEffect, useMemo } from 'react';
import TableContainer from '../../shared/TableContainer';
import { formatVal } from '../../helpers/formatVal';

export const TablaBoletin = ({ boletinSel }) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    console.log(boletinSel);
    const boletin = boletinSel || [];
    let isLoading = false;
    let isPagination = true;
    const pagSiz = 20;
    if (boletin.length < pagSiz) {
        isPagination = false;
    }

    // Función para extraer las descripciones y guardarlas por separado
    const descripciones = useMemo(() => {
        const nuevasDescripciones = { Empresa: [], Representante: [], Sociedad: [], Sociedades: [] };

        boletin.forEach(item => {
            if (item.tipo === 'M') {
                nuevasDescripciones.Empresa.push(item);
            } else if (item.tipo === 'R') {
                nuevasDescripciones.Representante.push(item);
            } else if (item.tipo === 'E') {
                nuevasDescripciones.Sociedad.push(item);
            } else if (item.tipo === 'S') {
                nuevasDescripciones.Sociedades.push(item);
            }
        });

        return nuevasDescripciones;
    }, [boletin]);

    const agruparPorNombreYRut = (datos) => {
        const grupos = {};
        datos.forEach((item) => {
            const clave = `${item.nombre}-${item.rut}`;
            if (!grupos[clave]) {
                grupos[clave] = [];
            }
            grupos[clave].push(item);
        });
        return Object.values(grupos);
    };

    const columns = [
        {
            accessor: 'bcRol', // access nested data with dot notation
            Header: 'Rol',
            formato: 'string',
            inicio: true
        },
        {
            accessor: 'bcboFechaInicio',
            Header: 'Fecha Inicio',
            formato: 'string',
            inicio: true
        },
        {
            accessor: 'bcboFechaUltima', // normal accessor
            Header: 'Fecha Última',
            formato: 'string',
            inicio: true
        },
        {
            accessor: 'bcliDescrip',
            Header: 'Liquidador',
            formato: 'string',
            inicio: true
        },
        {
            accessor: 'bcprDescrip',
            Header: 'Procedimiento',
            formato: 'string',
            inicio: true
        },
        {
            accessor: 'bctrTribunal',
            Header: 'Tribunal',
            formato: 'string',
            inicio: true
        },
        {
            accessor: 'descripcion',
            Header: 'Descripción',
            formato: 'string',
            inicio: true
        }
    ];

    return (
        <>
            <div>
                {Object.keys(descripciones).map((tipo) => (
                    agruparPorNombreYRut(descripciones[tipo]).map((grupo, index) => (
                        <div className='py-3' key={`${tipo}-${index}`}>
                            <h6>{grupo[0].nombre}  &nbsp;&nbsp;&nbsp;&nbsp;  {formatVal.formatRut(grupo[0].rut)}</h6>
                            <TableContainer
                                columns={columns}
                                data={grupo}
                                isGlobalFilter={false}
                                isAddOptions={false}
                                customPageSize={pagSiz}
                                isPagination={isPagination}
                                filterable={false}
                                iscustomPageSizeOptions={false}
                                tableClass="TbXs align-middle table-nowrap table-check"
                                theadClass="table-light"
                                pagination="pagination pagination-rounded justify-content-end mb-2"
                                isLoading={isLoading}
                                isTotal={false}
                                sortName='fechaEmision'
                                sortOrder='desc'
                                pageIndex={pageIndex} // Pasar pageIndex
                                setPageIndex={setPageIndex} // Pasar setPageIndex
                            />
                        </div>
                    ))
                ))}
            </div>
        </>
    );
};