
import { dataApi } from "../api/dataApi"

export const getBoletin =  {
    ObtenerBoletinConcursal: function(rgId) {
        return dataApi.get(`${process.env.REACT_APP_BOLETIN_OBTENER_CONCURSAL}${rgId}`);
    },
    ObtenerExisteBoletinConcursal: function(rgId) {
        return dataApi.get(`${process.env.REACT_APP_BOLETIN_OBTENER_EXISTE_CONCURSAL}${rgId}`);
    }
}

