import { dataApi } from "../api/dataApi";

export const TGR = {
        getTgrConvenios: function(id) {
            return dataApi.get(`${process.env.REACT_APP_TGR_CONVENIOS}${id}`);
        },
        getCertificado: function(id) {
            return dataApi.get(`${process.env.REACT_APP_TGR_CERRTIFICADOS}${id}`);
        },
        getTgrCobranzaJud: function(id){
            return dataApi.get(`${process.env.REACT_APP_TGR_COBRANZA_JUDICIAL}${id}`)
        }
};