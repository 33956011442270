import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from 'material-react-table';
import { useState } from 'react';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { ModalDetalleSituacionTributaria } from '../../modal/ModalDetalleSituacionTributaria';
import { formatTXT } from '../../../helpers/formatTXT';

const columnHelper = createMRTColumnHelper();

export const SituacionTrubutariaSII = ({
  SituacionTributaria
}) => {
  const [rowSelect, setRowSelect] = useState([]);  
  let isPagination = true
  const pagSiz = 15
  if (SituacionTributaria.length < pagSiz) {
    isPagination = false
  }
  const columns = [
    columnHelper.accessor('stFecha', {
      header: 'Fecha',
      size: 10,
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }) => {
        const partesFecha = cell.row.original.stFecha.split('-');
        const dia = partesFecha[2].slice(0,2);
        const mes = partesFecha[1];
        const año = partesFecha[0];
        const formattedDate = new Date(`${mes}/${dia}/${año}`).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
        return (
          <span className='cardDot2' onClick={() => openModalDetalleComportamiento(cell.row.original)} data-bs-toggle="modal" data-bs-target="#ModalDetalleSituacionTributaria">
            {formattedDate}
          </span>
        );
      },
    }),
    columnHelper.accessor('stSituacion', {
      header: 'Situacion',
      size: 60,
      Cell: ({ cell }) => {
        return (
          <span className='cardDot2' title={cell.row.original.stSituacion} onClick={() => openModalDetalleComportamiento(cell.row.original)} data-bs-toggle="modal" data-bs-target="#ModalDetalleSituacionTributaria">
            {cell.row.original.stSituacion}
          </span>
        );
      },
      // onClick={() => openModalDetalleComportamiento(row)} data-bs-toggle="modal" data-bs-target="#ModalDetalleSituacionTributaria"
    }),
    columnHelper.accessor('stDescripcion', {
      header: 'Descripcion',
      size: 60,
      Cell: ({ cell }) => {
        return (
          <span className='cardDot2' title={cell.row.original.stDescripcion} onClick={() => openModalDetalleComportamiento(cell.row.original)} data-bs-toggle="modal" data-bs-target="#ModalDetalleSituacionTributaria">
          {cell.row.original.stDescripcion}
        </span>
        );
      },
      // onClick={() => openModalDetalleComportamiento(row)} data-bs-toggle="modal" data-bs-target="#ModalDetalleSituacionTributaria"
    }),
    columnHelper.accessor('stAcciones', {
      header: 'Acciones',
      size: 60,
      Cell: ({ cell }) => {
        return (
          <span className='cardDot2' title={cell.row.original.stAcciones} onClick={() => openModalDetalleComportamiento(cell.row.original)} data-bs-toggle="modal" data-bs-target="#ModalDetalleSituacionTributaria">
            {cell.row.original.stAcciones}
          </span>
        );
      },
      // onClick={() => openModalDetalleComportamiento(row)} data-bs-toggle="modal" data-bs-target="#ModalDetalleSituacionTributaria"
    })
  ];
  const [Pagination, setPagination] = useState({ pagination: { pageSize: pagSiz, pageIndex: 0 }, density: 'compact', });
  // const textoCorregido = textoOriginal.replace(/[^\x00-\x7F]/g, ' ');
  formatTXT.corregirTXT()
  const openModalDetalleComportamiento = (row) => {
    setRowSelect({
      stFecha: row.stFecha,
      stSituacion: row.stSituacion,
      stDescripcion: row.stDescripcion,
      stAcciones: row.stAcciones
    });
  };
  return (
    <>
      <MaterialReactTable columns={columns}
        data={SituacionTributaria}
        initialState={Pagination}
        enableColumnActions={false}
        enableColumnDragging={false}
        enableColumnFilterModes={false}
        enableColumnVirtualization={true}
        enableTopToolbar={false}
        enableDensityToggle={false}
        enablePagination={isPagination}
        enableBottomToolbar={isPagination}
        localization={MRT_Localization_ES}
        muiTableBodyRowProps={({ }) => ({
          sx: { cursor: 'pointer' },
        })}
      />
      <ModalDetalleSituacionTributaria
        data={rowSelect}
      />
    </>
  )
}






