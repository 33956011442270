import './Resumen.css';
import { Card1 } from '../cards/Card1';
import { Card2 } from '../cards/Card2';
import { Card3 } from '../cards/Card3';
import { Card4 } from '../cards/Card4';
import { Card5 } from '../cards/Card5';
import { Card6 } from '../cards/Card6';
import { resumen } from '../../helpers/resumen';
import { comercial } from '../../helpers/comercial';
import { useContext, useEffect, useState } from 'react';
import { IdContext, PeriodoContext } from '../../context/IdContext';
import { useNavigate } from 'react-router-dom';
import { ModalDetalleGraficoVentasDeudor } from '../modal/ModalDetalleGraficoVentasDeudor';
import { ModalDetalleGraficoCesiones } from '../modal/ModalDetalleGraficoCesiones';
import { ModalDetalleGraficoCesionesDeudor } from '../modal/ModalDetalleGraficoCesionesDeudor';
import { AuthContext } from '../../auth/context/AuthContext';

export const Resumen = () => {
  const navigate = useNavigate();
  const perProv = useContext(PeriodoContext);
  const mp = perProv.idPeriodoProv
  const { login } = useContext(AuthContext)
  const idProvider = useContext(IdContext);
  const id = idProvider.idProvider
  const [mostrarPeriodo, setmostrarPeriodo] = useState()
  const [ComVensCr, setComVensCr] = useState([])
  const [ComVensCr12, setComVensCr12] = useState([])
  const [ComVensCr24, setComVensCr24] = useState([])
  const [FactNoCed, setFactNoCed] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  // Parseando el JSON
  const data = JSON.parse(localStorage.user);

  // Accediendo a la propiedad rgIdFact
  const rgIdFact = data.rgIdFact;
console.log(id)

  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      resumen.getAll(id, rgIdFact).then((r) => {
        if (r.status === 200) {
          setComVensCr24(r.data.listadoVentasComprasCruzadas)
          setComVensCr12(r.data.listadoVentasComprasCruzadas.slice(0, 12))
          setComVensCr(r.data.listadoVentasComprasCruzadas)
          setmostrarPeriodo(true)
        } else {
          setComVensCr24([])
          setComVensCr12([])
          setComVensCr([])
          setmostrarPeriodo(true)
        }
      }).catch((e) => {
        console.error('err ' + e)
      })
    }
  }, [])
  const periodos = []
  const ventas = []
  const compras = []
  const notacred = []
  const cedidos = []
  const vtacruzadas = []
  const comcruzadas = []
  ComVensCr24.forEach((datos) => {
    periodos.push(datos.periodo);
    ventas.push(datos.ventas);
    compras.push(datos.compras);
    notacred.push(datos.notaCred);
    cedidos.push(datos.cedidos);
    vtacruzadas.push(datos.vtaCruzada);
    comcruzadas.push(datos.comCruzada);
  });
  const [ResVensDdr, setResVensDdr] = useState([])
  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      resumen.getResumenVentasDeudor(id, perProv).then((r) => {
        if (r.status === 200) {
          setResVensDdr(r.data.listadoResumenVentasDeudor)
        } else {
          setResVensDdr([])
        }
      }).catch((e) => {
        console.error(e)
      })
    }
  }, [])
  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      resumen.getResumenVentasDeudor(id, perProv).then((r) => {
        if (r.status === 200) {
          setResVensDdr(r.data.listadoResumenVentasDeudor)
        } else {
          setResVensDdr([])
        }
      }).catch((e) => {
        console.error(e)
      })
    }
  }, [perProv])
  const [ResCsrNoBanc, setResCsrNoBanc] = useState([])
  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      resumen.getResumenCesionesCesionario(id, perProv).then((r) => {
        if (r.status === 200) {
          setResCsrNoBanc(r.data.listarConcentracionCesionesCesionario)
        } else {
          setResCsrNoBanc([])
        }
      }).catch((e) => {
        console.error(e)
      })
    }
  }, [])
  useEffect(() => {
    setmostrarPeriodo(!mostrarPeriodo)
    if (mp === 12) {
      setComVensCr(ComVensCr12)
    } else
      setComVensCr(ComVensCr24)
  }, [perProv])
  const [ResCssDdr, setResCssDdr] = useState([])
  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      resumen.getResumenCesionarioDeudor(id, perProv).then((r) => {
        // setResCssDdr(r.data.listarConcentracionCesionesDeudor)
        if (r.status === 200) {
          setResCssDdr(r.data.listarConcentracionCesionesDeudor.sort((a, b) => a.fila - b.fila))
        } else {
          setResCssDdr([])
        }
      }).catch((e) => {
        console.error(e)
      })
    }
  }, [])
  useEffect(() => {
    resumen.getResumenCesionarioDeudor(id, perProv).then((r) => {
      if (r.status === 200) {
        setResCssDdr(r.data.listarConcentracionCesionesDeudor.sort((a, b) => a.fila - b.fila))
      } else {
        setResCssDdr([])
      }
    }).catch((e) => {
      console.error(e)
    })
  }, [perProv])
  useEffect(() => {
    resumen.getResumenCesionesCesionario(id, perProv).then((r) => {
      if (r.status === 200) {
        setResCsrNoBanc(r.data.listarConcentracionCesionesCesionario)
        const sortedData = r.data.listarConcentracionCesionesCesionario.sort((a, b) => {
          if (a.idCesionario === 0) return 1; // Mover a al final
          if (b.idCesionario === 0) return -1; // Mover b al final
          return b.monto - a.monto; // Ordenar por monto de mayor a menor
        });
      } else {
        setResCsrNoBanc([])
      }
    }).catch((e) => {
      console.error(e)
    })
  }, [perProv])
  useEffect(() => {
    if (id == undefined) {
      navigate(`/inicio`);
    } else {
      comercial.getFacturasNoCedidas(id,rgIdFact).then((r) => {
        if (r.status === 200) {
          const facturasConvertidas = r.data.listadoFacturasParaGestion.map(factura => {
            // Extraer la fecha de emisión y verificar si es válida antes de transformarla
            const { fechaEmision } = factura;
            let fechaConvertida = '';
            if (fechaEmision) {
              const partes = fechaEmision.split('/');
              if (partes.length === 3) { // Asegura que la fecha está en el formato esperado
                fechaConvertida = `${partes[2]}-${partes[1].padStart(2, '0')}-${partes[0].padStart(2, '0')}`;
              }
            }
            return {
              ...factura,
              fechaEmision: fechaConvertida
            };
          });
          setFactNoCed(facturasConvertidas);  // Actualiza el estado con las facturas convertidas
          setIsLoading(false);
        } else {
          setFactNoCed([]);
          setIsLoading(false);
        }
      }).catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
    }
  }, [])


  return (
    <>
      <div className=' pt-3'>
        <div className='row'>
          <div className='row col-6 ps-3'>
            <div className='p-2'>
              < Card1
                ComVensCr={mostrarPeriodo ? ComVensCr12 : ComVensCr24}
                Periodo={mp}
              />
            </div>
            <div className='p-2 '>
              <Card2
                ComVensCr={mostrarPeriodo ? ComVensCr12 : ComVensCr24}
                Periodo={mp}
              />
            </div>
          </div>
          <div className='row col-6 p-2 pe-3'>
            < Card3
              ComVensCr={mostrarPeriodo ? ComVensCr12 : ComVensCr24}
              FactNoCed={FactNoCed}
              Periodo={mp}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className='row col px-3' >
          <div className='row col-4 g-1 ps-2 pe-2'>
            <Card4
              ResVensDdr={ResVensDdr}
            />
          </div>
          <div className='row col-4 g-1 px-2'>
            <Card5
              ResCssDdr={ResCssDdr}
            />
          </div>
          <div className='row col-4 g-1 ps-2'>
            <Card6
              ResCsrNoBanc={ResCsrNoBanc}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            {ResVensDdr ? <ModalDetalleGraficoVentasDeudor ResVensDdr={ResVensDdr} Periodo={mp} /> : ''}
            {ResCssDdr ? <ModalDetalleGraficoCesionesDeudor ResCssDdr={ResCssDdr} Periodo={mp} /> : ''}
            {ResCsrNoBanc ? <ModalDetalleGraficoCesiones ResCsrNoBancDet={ResCsrNoBanc} Periodo={mp} /> : ''}
          </div>
        </div>

      </div>
    </>
  )
}

