import { useState } from 'react';
import TableContainer from '../../shared/TableContainer';

export const TablaTgrCuotasConveniosDemo = ({ CuotConvenios, isLoading }) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual

    let isPagination = true
    const pagSiz = 15
    const columns = [
        {
            accessor: 'cuota',
            Header: 'Cuota',
            formato: 'centro',
            headerPost: 'center',
            inicio: true
        }, 
        {
            accessor: 'resolucion',
            Header: 'Resolución',
            formato: 'numero',
            headerPost: 'center',
            padd: '2em'
        }, 
        {
            accessor: 'fechaVence',
            Header: 'Fecha Vencimiento',
            formato: 'fecha',
            headerPost: 'center',
            totalTitulo: true,
            totalTituloNombre: 'Total por pagar',
            headerSlice: true
        },
        {
            accessor: 'monto',
            Header: 'Monto',
            formato: 'moneda',
            headerPost: 'center',
            final: true,
            totalParcial: true,
            condicionTotalParcial: 'estadoPago'
        },
        {
            accessor: 'nuevoEstado',
            Header: 'Estado',
            formato: 'string',
            inicio: true,
        },
    ];
    return <TableContainer
        columns={columns}
        data={CuotConvenios}
        isAddOptions={false}
        customPageSize={pagSiz}
        isPagination={isPagination}
        filterable={false}
        iscustomPageSizeOptions={false}
        tableClass="TbXs align-middle table-nowrap table-check"
        theadClass="table-light"
        pagination="pagination pagination-rounded justify-content-end mb-2"
        isLoading={isLoading}
        isTotal={true}
        sortName = 'resolucion'
        sortOrder = 'Desc'
        pageIndex={pageIndex} // Pasar pageIndex
        setPageIndex={setPageIndex} // Pasar setPageIndex
    />;
};


