// import { Grafico }  from '../resumen';

import { GraficoPie } from "../resumen/graficosPie/GraficoPie"
export const CardGPCD = ({
    ResCssDdr,
    Periodo
}) => {
    const paleta = [
        '#0D2E82',
        '#74D3E8',
        '#657AB0',
        '#EBC3B0',
        '#B55C48',
        '#A2AFCF',
        '#BC9C8C',
        '#D9D9D9',
    ]
    const porcentaje = []
    const listItemsDeudor = [,]
    let suma = 0
    let sum = 0
    let counter = -1
    ResCssDdr.forEach((numeros) => {
        suma += numeros.monto;
    });
    ResCssDdr.forEach((numeros) => {
        porcentaje.push((numeros.monto * 100 / suma).toFixed(1))
        sum += numeros.monto * 100 / suma;
    });
    ResCssDdr.forEach((numeros) => {
        let porc = (numeros.monto * 100 / suma).toFixed(1)
        numeros.porcentaje = parseFloat(porc)
        sum += numeros.monto * 100 / suma;
    });
    return (
        <div className="card cardDot">
            <div className="row  g-3">
                <p className='col-12 titGraf pt-3 text-center' style={{ fontSize: 20 }}>Cesiones x Deudor</p>
                <div className="col-4 ps-5 mb-5">
                    <GraficoPie
                        monto={ResCssDdr.monto}
                        porcentaje={porcentaje}
                    />
                </div>
                <div className="col-7 ps-0">
                    <table className="table TbXs">
                        <thead>
                            <tr>
                                <th></th>
                                <th data-bs-toggle="tooltip" title="Porcentaje del total">Porcentaje</th>
                                <th style={{ width: '30%' }} data-bs-toggle="tooltip" title="Nombre completo del deudor">Nombre del Deudor</th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">Monto {Periodo} meses</th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">30 Días </th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">60 Días </th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">90 Días </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ResCssDdr.map((resvd, index) => (
                                <tr key={index}>
                                    <td style={{ width: '5%' }}>
                                        <div className="cuadradoDetalle mt-1 me-1" style={{ background: paleta[index % paleta.length] }}></div>
                                    </td>
                                    <td className="right-aligned  pe-5" style={{ width: '15%' }}>
                                        {resvd.porcentaje}%
                                    </td>
                                    <td style={{ width: '55%' }}>{resvd.nombreDeudor}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.monto.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.rango0_30.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.rango31_60.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                    <td className="right-aligned pe-4" style={{ width: '15%' }}>{resvd.rango61_90.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="h5 pt-4">
                        Total monto de cesiones x deudor {suma.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}
                    </div>

                </div>
            </div>
        </div>
    )
}

