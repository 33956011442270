import { useContext, useEffect, useState } from 'react'
import { IdContext } from '../../context/IdContext';
import { TGR } from '../../helpers/TGR';
import { useNavigate } from 'react-router-dom';
import { TablaTgrCuotasConveniosDemo } from '../tablas/TablaTgrCuotasConveniosDemo';
import { TablaTgrConveniosDemo } from '../tablas/TablaTgrConveniosDemo';
import { TablaTgrCobranzaJudicialDemo } from '../tablas/TablaTgrCobranzaJudicialDemo';
import { formatTXT } from '../../helpers/formatTXT';
import { TablaTgrDeudaMorosaDemo } from '../tablas/TablaTgrDeudaMorosaDemo';
import { TablaTgrDeudaNoMorosaDemo } from '../tablas/TablaTgrDeudaNoMorosaDemo';
export const Tgr = () => {
  const idProvider = useContext(IdContext);
  const id = idProvider.idProvider
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [DetConvenios, setDetConvenios] = useState([])
  const [CuotConvenios, setCuotConvenios] = useState([])
  useEffect(() => {
    if (id === undefined) {
      navigate(`/inicio`);
    } else {
      TGR.getTgrConvenios(id).then((r) => {
        if (r.status === 200) {
          setDetConvenios(formatTXT.corregirFechaArray(r.data.listadoConvenios, 'fechaVence'));  //a el estado con las facturas convertidas
          setCuotConvenios(formatTXT.corregirFechaArray(r.data.listadoConveniosCuotas, 'fechaVence'));  //a el estado con las facturas convertidas
          setIsLoading(false);
        } else {
          setDetConvenios([])
          setCuotConvenios([])
          setIsLoading(false);
        }
      }).catch((e) => {
        console.error('err ' + e)
      })
    }
  }, [])
  const [CobranzaJudicial, setCobranzaJudicial] = useState([])
  useEffect(() => {
    if (id === undefined) {
      navigate(`/inicio`);
    } else {
      TGR.getTgrCobranzaJud(id).then((r) => {

        if (r.status === 200) {
          setCobranzaJudicial(formatTXT.corregirArray(r.data.listadoCobranzaJudicial))
          setIsLoading(false);
        } else {
          setCobranzaJudicial([])
          setIsLoading(false);
        }
      }).catch((e) => {
        console.error('err ' + e)
      })
    }
  }, [])
  const [certificado, setCertificado] = useState([])
  useEffect(() => {
    if (id === undefined) {
      navigate(`/inicio`);
    } else {
      TGR.getCertificado(id).then((r) => {

        if (r.status === 200) {
          setCertificado(formatTXT.corregirArray(r.data.listadoCertificadoDeudaFiscal))
          setIsLoading(false);
        } else {
          setCertificado([])
          setIsLoading(false);
        }
      }).catch((e) => {
        console.error('err ' + e)
      })
    }
  }, [])
  const procesarCuotas = (cuotas) => {
    const hoy = new Date();
    return cuotas.map(cuota => {
      let nuevoEstado;
      let condicionTotalParcial;

      if (cuota.estadoPago) {
        nuevoEstado = 'Pagado';
        condicionTotalParcial = false
      } else {
        const fechaVence = new Date(cuota.fechaVence);
        if (fechaVence > hoy) {
          nuevoEstado = 'Vigente';
          condicionTotalParcial = true
        } else {
          nuevoEstado = 'Moroso';
          condicionTotalParcial = true
        }
      }
      return { ...cuota, nuevoEstado, condicionTotalParcial };
    });
  };
  const cuotasPreProcesadas = procesarCuotas(CuotConvenios);
  const cuotasProcesadas = cuotasPreProcesadas.filter(cuota => cuota.monto !== 0);
  return (
    <div className='row' >
      <div className='col-8 ps-4 p-2'>
        <div className="col row border rounded mt-3 card">
          <div className="col row ">
            <div className="col-5 ">
              <p className="titGraf  mx-4 ">Deuda: Morosa </p>
              <p className="titGraf2 mx-4">(CLP)</p>
            </div>
            <div className="col-4 ">
            <h5 className="pt-3" style={{ color: '#565656' }}>Certificado de Deuda</h5>
            </div>
            <TablaTgrDeudaMorosaDemo
              certificado={certificado}
              isLoading={isLoading}
            />
          </div>
          <div className="col row ">
            <p className="titGraf pt-2 mx-4 ">Deuda: No Vencida </p>
            <p className="titGraf2 mx-4">(CLP)</p>
            <TablaTgrDeudaNoMorosaDemo
              certificado={certificado}
              isLoading={isLoading}
            />
          </div>
        </div>
        <div className="col row border rounded mt-3 card">
          <p className="titGraf pt-2 mx-4 ">Convenios</p>
          <p className="titGraf2 mx-4">N° de resolución de convenios</p>
          <TablaTgrConveniosDemo
            DetConvenios={DetConvenios}
            CuotConvenios={CuotConvenios}
            isLoading={isLoading}
          />
        </div>
      </div>
      <div className='col-4 pe-4 p-2'>
        <div className="col row border rounded mt-3 card">
          <p className="titGraf pt-2 mx-4">Cobranza Judicial </p>
          <p className="titGraf2 mx-4">N° de resolución de convenios</p>
          <TablaTgrCobranzaJudicialDemo
            CobranzaJudicial={CobranzaJudicial}
            isLoading={isLoading}
          />
        </div>
        <div className="col border rounded mt-3 mx-0 card">
          <p className="titGraf  mx-4 pb-3">Cuotas convenios</p>
          <p className="titGraf2 mx-4"></p>
          <TablaTgrCuotasConveniosDemo
            CuotConvenios={cuotasProcesadas}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}




