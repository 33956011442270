import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);
export const GraficoPie = ({
    monto,
    porcentaje
}) => {
    const options = {
        plugins: {
            legend: {
                display: false
            },
            datalabels:
            {
                font: {
                    size: 0
                },
                color: [
                    'white',
                    'black',
                    'white',
                    'black',
                    'white',
                    'black',
                ],
                formatter: (value) => {
                    return value + "%";
                },
                align: 'end',
                clamp: true,
                offset: 25,
            },
        },
        responsive: true,
    };
    const labels = monto;
    const data = {
        labels,
        datasets: [
            {
                label: monto,
                data: porcentaje,
                backgroundColor: [
                    '#0D2E82',
                    '#74D3E8',
                    '#657AB0',
                    '#EBC3B0',
                    '#B55C48',
                    '#A2AFCF',
                    '#BC9C8C',
                    '#D9D9D9',
                ],
                borderWidth: 0,
            },

        ],
    };

    return (
        <>
            <Pie id='detallePie' type='pie' options={options} data={data} plugins={[ChartDataLabels]} />
        </>
    )
}
