import { useState } from 'react';
import TableContainer from '../../../shared/TableContainer';

export const CaracteristicasSII = ({
  caracteristicas
}) => {
  const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
  const isLoading = false
  let isPagination = true
  const pagSiz = 15
  if (caracteristicas.length < pagSiz) {
    isPagination = false
  }
  caracteristicas.forEach(registro => {
    const anoMesStr = registro.caFhInicio.toString().slice(0, 10);
    if (anoMesStr.length === 6) {
      const ano = anoMesStr.substring(0, 4);
      const mes = anoMesStr.substring(4);
      const anoMesFormateado = ano + '/' + mes;
      registro.caFhInicio = anoMesFormateado;
    }
  });
  const columns = [
    {
      accessor: 'caFhInicio',
      Header: 'Fecha',
      headerPost: 'center',
      inicio: true,
      formato: 'fecha',

    },
    {
      accessor: 'caDescripcion',
      Header: 'Registro',
      headerPost: 'center',
      formato: 'string',
      inicio: true,
    },

  ];
  return <TableContainer
    columns={columns}
    data={caracteristicas}
    isGlobalFilter={false}
    isAddOptions={false}
    customPageSize={20}
    isPagination={true}
    filterable={false}
    iscustomPageSizeOptions={false}
    tableClass="TbXs align-middle table-nowrap table-check"
    theadClass="table-light"
    pagination="pagination pagination-rounded justify-content-end mb-2"
    isLoading={isLoading}
    pageIndex={pageIndex} // Pasar pageIndex
    setPageIndex={setPageIndex} // Pasar setPageIndex
  />;
}




