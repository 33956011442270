export const getEstadosFactura = (estado) => {
    let color, descripcion;

    switch (estado) {
        case 2:
            color = 'green';
            descripcion = 'Recibida';
            break;
        case 3:
            color = '#72C55A'; 
            descripcion = 'No Reclamada dentro de plazo';
            break;
        case 4:
            color = '#FFEA6E';
            descripcion = 'Pendiente';
            break;
        case 5:
            color = '#F44335';
            descripcion = 'Reclamada';
            break;
        default:
            color = 'gray'; // Color por defecto
            descripcion = 'Estado desconocido'; // Descripción por defecto
            break;
    }

    return { color, descripcion };
};