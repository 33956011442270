
// import { useState, useEffect } from "react";
// import { Button } from '@mui/material';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { formatVal } from './formatVal'; // Ajusta la ruta según corresponda
// import * as ExcelJS from 'exceljs';
// import logo1 from '../shared/Logo_2021.jpg'; // Ajusta la ruta según corresponda
// import logo2 from '../shared/Logo_FINMonitor.jpg'; // Ajusta la ruta según corresponda

// export const BotonExcel = ({ data, getColumnNames, title }) => {
//     const [loading, setLoading] = useState(false);
//     const [base64Logo1, setBase64Logo1] = useState('');
//     const [base64Logo2, setBase64Logo2] = useState('');

//     // Convertir las imágenes a base64 al cargar el componente
//     useEffect(() => {
//         convertToBase64(logo1, setBase64Logo1);
//         convertToBase64(logo2, setBase64Logo2);
//     }, []);

//     // Función para convertir una imagen a base64
//     const convertToBase64 = (url, callback) => {
//         const xhr = new XMLHttpRequest();
//         xhr.onload = function () {
//             const reader = new FileReader();
//             reader.onloadend = function () {
//                 callback(reader.result);
//             }
//             reader.readAsDataURL(xhr.response);
//         };
//         xhr.open('GET', url);
//         xhr.responseType = 'blob';
//         xhr.send();
//     }

//     const handleDownload = () => {
//         setLoading(true);

//         // Obtener las columnas de getColumnNames
//         const columns = getColumnNames;

//         // Crear la fila del encabezado dinámicamente usando los headers de getColumnNames
//         const header = columns.map(col => col.Header);

//         // Obtener las claves de los accesores para usarlas como nombres de columna
//         const columnNames = columns.map(col => col.accessor);

//         // Construir las filas de datos dinámicamente
//         const rows = data.map(item => {
//             return columnNames.map(col => {
//                 let value = item[col];
//                 // Aplicar formato si es necesario
//                 const column = columns.find(c => c.accessor === col);
//                 if (column.formato === 'rut') {
//                     value = formatVal.formatRut(value);
//                 } else if (column.formato === 'moneda') {
//                     value = parseFloat(value); // Convertir a número para formato en Excel
//                 }
//                 return value;
//             });
//         });

//         setTimeout(() => {
//             creandoArchivo(header, rows, columns, title);
//             setLoading(false);
//         }, 1000);
//     };

//     const creandoArchivo = async (header, rows, columns, title) => {
//         const workbook = new ExcelJS.Workbook();
//         const worksheet = workbook.addWorksheet('data');

//         // Insertar imágenes con tamaño específico
//         const imageId1 = workbook.addImage({
//             base64: base64Logo1,
//             extension: 'jpeg',
//         });
//         const imageId2 = workbook.addImage({
//             base64: base64Logo2,
//             extension: 'jpeg',
//         });

//         // Obtener la fecha y hora actual
//         const date = new Date();
//         const dateString = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
//         const timeString = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

//         // Formatear el título eliminando espacios
//         const formattedTitle = title.replace(/\s+/g, '');

//         // Nombre del archivo incluyendo título, fecha y hora
//         const fileName = `${formattedTitle}_${dateString}T${timeString}.xlsx`; 

//         // Ajustar la posición de las imágenes para que no se superpongan con los datos
//         worksheet.addImage(imageId2, {
//             tl: { col: 0, row: 0 },
//             br: { col: 2, row: 2 },
//             // ext: { width: 150, height: 75 } // Mantener el tamaño especificado
//         });

//         // worksheet.addImage(imageId1, {
//         //     tl: { col: 0, row: 0 },
//         //     br: { col: 2, row: 2 },
//         //     // ext: { width: 150, height: 75 } // Mantener el tamaño especificado
//         // });
//         // worksheet.addImage(imageId2, {
//         //     tl: { col: 4, row: 0 },
//         //     br: { col: 6, row: 2 },
//         //     // ext: { width: 150, height: 75 } // Mantener el tamaño especificado
//         // });

//         // Insertar título en C1
//         worksheet.getCell('C1').value = title;
//         worksheet.getCell('C1').font = { bold: true, size: 14 };
//         worksheet.getCell('C1').alignment = { horizontal: 'center' };
//         worksheet.getCell('C2').value = dateString;
//         worksheet.getCell('C2').font = { bold: false, size: 14 };
//         worksheet.getCell('C2').alignment = { horizontal: 'center' };

//         // Espacio para las imágenes: empezar el encabezado en la fila 5
//         const startRow = 3;

//         // Agregar encabezado
//         // worksheet.addRow([]);
//         // worksheet.addRow([]);
//         // worksheet.addRow([]);
//         // worksheet.addRow([]);
//         worksheet.addRow(header);

//         // Agregar filas de datos empezando desde la fila 6
//         rows.forEach(row => {
//             worksheet.addRow(row);
//         });

//         // Ajustar anchos de columna
//         columns.forEach((col, index) => {
//             worksheet.getColumn(index + 1).width = Math.max(
//                 ...rows.map(row => (row[index] || '').toString().length),
//                 col.Header.length
//             ) + 2; // Añadir un pequeño margen

//             // Aplicar alineación y formato numérico
//             if (col.formato === 'moneda') {
//                 worksheet.getColumn(index + 1).alignment = { horizontal: 'right' };
//                 worksheet.getColumn(index + 1).numFmt = '"$ "* #,##0_);("$ "* #,##0)'; // Formato sin decimales con símbolo de peso a la izquierda
//             } else if (col.formato === 'string') {
//                 worksheet.getColumn(index + 1).alignment = { horizontal: 'left' }; // Alineación a la izquierda para strings
//             } else {
//                 worksheet.getColumn(index + 1).alignment = { horizontal: 'right' };
//             }
//         });



//     // Descargar archivo
//     const buffer = await workbook.xlsx.writeBuffer();
//     const blob = new Blob([buffer], { type: 'application/octet-stream' });
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = fileName;
//     a.click();
//     window.URL.revokeObjectURL(url);
//     };

//     return (
//         <>
//             <Button
//                 onClick={handleDownload}
//                 startIcon={<FileDownloadIcon sx={{ color: '#1C683F' }} />}
//                 disabled={loading}
//                 sx={{ color: '#1C683F' }}
//             >
//                 {loading ? "Generando..." : "Excel"}
//             </Button>
//         </>
//     );
// };

import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { formatVal } from './formatVal'; // Asegúrate de que esta ruta es correcta
import * as ExcelJS from 'exceljs';
import logo2 from '../shared/Logo_FINMonitor.jpg'; // Asegúrate de que esta ruta es correcta

export const BotonExcel = ({ data, getColumnNames, title }) => {
    const [loading, setLoading] = useState(false);
    const [base64Logo2, setBase64Logo2] = useState('');

    // Convertir el logo a base64 al cargar el componente
    useEffect(() => {
        convertToBase64(logo2, setBase64Logo2);
    }, []);

    const convertToBase64 = (url, callback) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };

    const handleDownload = () => {
        setLoading(true);
        const columns = getColumnNames;
        const header = columns.map(col => col.Header);
        const columnNames = columns.map(col => col.accessor);

        const rows = data.map(item => columnNames.map(col => {
            let value = item[col];
            const column = columns.find(c => c.accessor === col);
            if (column.formato === 'rut') {
                value = formatVal.formatRut(value);
            } else if (column.formato === 'numero') {
                value = parseInt(value, 10);
            } else if (column.formato === 'moneda') {
                value = parseInt(value, 10);
            }
            return value;
        }));

        setTimeout(() => {
            creandoArchivo(header, rows, columns, title);
            setLoading(false);
        }, 1000);
    };

    const creandoArchivo = async (header, rows, columns, title) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
        // Obtener la fecha y hora actual
        const date = new Date();
        const dateString = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
        const timeString = `${date.getHours().toString().padStart(2, '0')}hrs${date.getMinutes().toString().padStart(2, '0')}min`;

        // Formatear el título eliminando espacios
        const formattedTitle = title.replace(/\s+/g, '');
        const fileName = `${formattedTitle}_${dateString}__${timeString}.xlsx`;
        const imageId2 = workbook.addImage({
            base64: base64Logo2,
            extension: 'jpeg',
        });
        worksheet.addImage(imageId2, {
            tl: { col: 0, row: 0 },
            br: { col: 2, row: 2 },
        });

        worksheet.mergeCells('C1:D1');
        const titleRow = worksheet.getCell('C1');
        titleRow.value = title;
        titleRow.font = { bold: true, size: 14 };
        titleRow.alignment =  { horizontal: 'center', vertical: 'middle' };


        worksheet.getCell('C2').value = dateString;
        worksheet.getCell('C2').font = { bold: false, size: 14 };
        worksheet.getCell('C2').alignment =  { horizontal: 'center', vertical: 'middle' };
        // Agregar encabezado y centrar los encabezados
        const headerRow = worksheet.addRow(header);
        headerRow.eachCell((cell, number) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        rows.forEach(row => {
            worksheet.addRow(row);
        });

        columns.forEach((col, index) => {
            const colIndex = index + 1;
            const additionalMargin = col.formato === 'moneda' ? 8 : 2;
            worksheet.getColumn(colIndex).width = Math.max(
                ...rows.map(row => (row[index] || '').toString().length),
                col.Header.length
            ) + additionalMargin;

            // Aplicar alineación y formato numérico
            if (col.formato === 'moneda') {
                worksheet.getColumn(colIndex).alignment = { horizontal: 'right' };
                worksheet.getColumn(colIndex).numFmt = '"$"* #,##0_);("$"* #,##0)';
            } else if (col.formato === 'string') {
                worksheet.getColumn(colIndex).alignment = { horizontal: 'left' };
            } else if (col.formato === 'numero') {
                worksheet.getColumn(colIndex).alignment = { horizontal: 'center' };
            } else if (col.formato === 'rut') {
                worksheet.getColumn(colIndex).alignment = { horizontal: 'right' };
            } else if (col.formato === 'fecha') {
                worksheet.getColumn(colIndex).alignment = { horizontal: 'center' };
            } else {
                worksheet.getColumn(colIndex).alignment = { horizontal: 'left' };
            }
        });
        // Asegurar que los encabezados estén centrados
        headerRow.eachCell((cell) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        // a.download = `${title.replace(/\s+/g, '')}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
    };
    console.log(data.length)
    return (
        <Button
            onClick={handleDownload}
            startIcon={<FileDownloadIcon />}
            disabled={loading || data.length === 0} // Deshabilitar si está cargando o si data está vacío
            sx={{ color: '#1C683F' }}
        >
            {loading ? "Generando..." : "Excel"}
        </Button>
    );
};

