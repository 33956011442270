import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";

export const ModalFormulario22 = ({
    modal,
    toggle,
    data
}) => {
    console.log(data)

    return (
        <Modal isOpen={modal} toggle={toggle} size={'lg'}>
            <ModalHeader className="justify-content-center">
                <h5 >Formulario 22</h5>
            </ModalHeader>
            <ModalBody>
                <div className="mx-5 mt-2 mb-5 col table-responsive">
                    <div><b>Año:</b> <span>{data?.periodo}</span></div>
                    <div><b>Folio:</b> <span>{data?.nroFicha}</span></div>
                    <div><b>Descripcion:</b> <span>{data?.descripcion}</span></div>
                </div>
            </ModalBody>
        </Modal>

        // <div className="modal fade" id="ModalFormulario22" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
        //     <div className="modal-dialog modal-dialog-centered">
        //         <div className="modal-content" style={{ width: 'auto', minWidth: '700px' }}>
        //             <div className="modal-header d-flex justify-content-center align-items-center">
        //                 <h5 className="modal-title text-center">Formulario 22</h5>
        //             </div>
        //             <div className="modal-body">
        //                 <div className="mx-5 mt-2 mb-5 col table-responsive">
        //                     <div><b>Año:</b> <span>{data.periodo}</span></div>
        //                     <div><b>Folio:</b> <span>{data.nroFicha}</span></div>
        //                     <div><b>Descripcion:</b> <span>{data.descripcion}</span></div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

    )
}
