import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { formatVal } from '../../../helpers/formatVal';


ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
export const Cesiones = (
  {
    periodos,
    ventas,
    cedidos,
    cedidosFactoring,
    mp
  }
  ) => {
    const monthNames = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() es base 0, lo ajustamos a base 1.
    const currentYear = new Date().getFullYear(); // Obtiene el año completo
    const shortYear = currentYear.toString().slice(-2); // Convierte el año a cadena y extrae los últimos dos dígitos
    const labels = [];
    // Generamos las fechas hacia atrás desde el mes actual
    // Calculamos las etiquetas de mes/año hacia atrás desde el mes actual
    for (let i = 0; i < mp; i++) {
      const monthIndex = (currentMonth - 1 - i + mp) % 12; // Ajustamos para loop circular en los meses
      const yearAdjustment = Math.floor((currentMonth - 1 - i) / 12); // Calculamos cuántos años atrás debemos ir
      const year = parseInt(shortYear, 10) + yearAdjustment;
      labels.unshift(`${monthNames[monthIndex]}-${year}`); // Usamos unshift para mantener el orden correcto desde más reciente a más antiguo
    }
  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        backgroundColor: '#E4EBF0', // Color de fondo del tooltip
        titleColor: '#000', // Color del título del tooltip
        bodyColor: '#666', // Color del cuerpo del tooltip
        borderColor: '#DDD', // Color del borde del tooltip
        borderWidth: 1, // Ancho del borde del tooltip
        titleFont: {
          size: 14, // Tamaño de la fuente del título
          weight: 'bold' // Grosor de la fuente del título
        },
        bodyFont: {
          size: 12 // Tamaño de la fuente del cuerpo
        },
        callbacks: {
          title: function(tooltipItems) {
            return tooltipItems[0].label;
          },
          label: function(tooltipItem) {
            let label = tooltipItem.dataset.label + ':';
            let value = tooltipItem.raw;
            return label + formatVal.formatValueCLPFull(value);
          },
        }
      },
      legend: {
        display: false
      },
    },
    responsive: true,
    animation: {
      delay: (context) => {
          let delay = 0;
          if (context.type === 'data') {
              delay = context.dataIndex * 30 + context.datasetIndex * 50;
          }
          return delay;
      },
  },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          callback: function (value, index, values) {
            return value / 1e6 + ' M';
          }
        }
      },
    },
  };


  const data = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'cedidos Factoring',
        backgroundColor: '#B55C48',
        data: cedidosFactoring,
        stack: 'Stack 0',
        stacked: false,
      },
      {
        type: 'bar',
        label: 'Cedido',
        backgroundColor: '#BC9C8C',
        data: cedidos,
        stack: 'Stack 0',
        stacked: false,
      },
      {
        type: 'bar',
        label: 'Venta',
        backgroundColor: '#657AB0',
        data: ventas,
        stack: 'Stack 0',
        stacked: false,
      },
    ],
  };
  return (
    <>
      <Chart id='barra2' type='bar' options={options} data={data} />
    </>
  )
}

