import { useState, useEffect } from 'react';

const useDeviceScale = () => {
//     const [scale, setScale] = useState(1);

//     useEffect(() => {
//         const handleResize = () => {
//             const currentScale = window.devicePixelRatio;
//             setScale(currentScale);
//         };

//         window.addEventListener('resize', handleResize);
//         handleResize(); // Initial call to set scale

//         return () => window.removeEventListener('resize', handleResize);
//     }, []);

//     return scale;
// };

// export default useDeviceScale;

// const [scale, setScale] = useState(1);

// useEffect(() => {
//     const handleResize = () => {
//         const screenWidth = window.innerWidth;
//         if (screenWidth < 600) {
//             setScale(0.75); // Escala más pequeña para dispositivos pequeños
//         } else if (screenWidth < 1024) {
//             setScale(0.85); // Escala intermedia para tablets
//         } else {
//             setScale(1); // Escala normal para pantallas grandes
//         }
//     };

//     window.addEventListener('resize', handleResize);
//     handleResize(); // Llamada inicial

//     return () => window.removeEventListener('resize', handleResize);
// }, []);

// return scale;
// };
const [scale, setScale] = useState(1);

useEffect(() => {
    const handleResize = () => {
        // Obtener el nivel de zoom del navegador
        const zoomLevel = window.devicePixelRatio;
        if (zoomLevel < 1) {
            // Fijar la escala a 1 si el zoom es mayor que 100%
            setScale(1);
        } else {
            // Permitir que la escala sea dinámica si el zoom es 100% o menor
            setScale(zoomLevel);
        }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Llamada inicial para establecer la escala

    return () => window.removeEventListener('resize', handleResize);
}, []);

return scale;
};
export default useDeviceScale;
