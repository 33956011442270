import { useState } from 'react';
import { BotonExcel } from '../../helpers/getDataToExcel';
import TableContainer from '../../shared/TableContainer';
import moment from 'moment';
import { getEstadosFactura } from '../../helpers/getEstadosFactura';

export const TablaComercialFacturasEmitidasDemo = ({
    Facturas,
    isLoading
}) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual

    // should be memoized or stable
    let isPagination = true
    const pagSiz = 10
    const tituloComponent = 'Facturas Cedidas'
    const facturasFilt = []
    if (Facturas.length > 0) {
        Facturas.forEach((datos) => {
            if (datos.cedido === 'cedido') {
                facturasFilt.push(datos);
            }
            if (datos.cedido === 'no cedido') {
            }
        });
    }
    if (facturasFilt.length < pagSiz) {
        isPagination = false
    }
    const currentDate = moment();
    const obtenerFechaActual = () => {
        const hoy = new Date();
        const dia = hoy.getDate().toString().padStart(2, '0');
        const mes = (hoy.getMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript son 0-indexed
        const año = hoy.getFullYear();
        return `${dia}/${mes}/${año}`;
    };
    const fechaActual = obtenerFechaActual()

    function calcularDiferenciaDias(fecha1, fecha2) {
        const [dia1, mes1, año1] = fecha1.split('/').map(Number);
        const [dia2, mes2, año2] = fecha2.split('/').map(Number);
        const date1 = new Date(año1, mes1 - 1, dia1);
        const date2 = new Date(año2, mes2 - 1, dia2);
        const diferenciaTiempo = date1 - date2;
        if (Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24)) === 1) {
            return Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24)) + ' dia';
        } else {
            return Math.floor(diferenciaTiempo / (1000 * 60 * 60 * 24)) + ' días';
        }
    }

    function calcularDiferenciaHoras(hora1, hora2) {
    
        if (!hora1 || !hora2) {
            return "El mismo dia";
        }
    
        // Convertir horas y minutos a minutos desde medianoche
        const totalMinutos1 = horaToMinutos(hora1);
        const totalMinutos2 = horaToMinutos(hora2);
    
        // Calcular la diferencia en minutos
        let diferenciaMinutos = totalMinutos1 - totalMinutos2;
    
        // Si la diferencia es negativa, añadir 12 horas a hora1 y recalcular
        if (diferenciaMinutos < 0) {
            const totalMinutos1Ajustado = totalMinutos1 + 12 * 60; // Sumar 720 minutos (12 horas)
            diferenciaMinutos = totalMinutos1Ajustado - totalMinutos2;
        }
    
        // Convertir la diferencia de minutos a horas y minutos
        const horas = Math.floor(diferenciaMinutos / 60).toString().padStart(2, '0');
        const minutos = (diferenciaMinutos % 60).toString().padStart(2, '0');
    
        return `${horas === '00' ? minutos + ' Min' : horas + 'Hrs:' + minutos + 'Min'}`;
    }
    
    // Función auxiliar para convertir una hora "HH:mm" a minutos desde medianoche
    function horaToMinutos(hora) {
        const [horas, minutos] = hora.split(':').map(Number);
        return horas * 60 + minutos;
    }

    const procesarArray = (array) => {
        return array.map(item => {
            const nuevoItem = { ...item };
            if (item.fechaCesion) {
                if (item.fechaCesion !== item.fechaEmision) {
                    nuevoItem.tiempoCesion = `${calcularDiferenciaDias(item.fechaCesion, item.fechaEmision)}`;
                    // nuevoItem.tiempoCesion = `${calcularDiferenciaDias(fechaActual, item.fechaCesion)} días`;
                } else {
                    nuevoItem.tiempoCesion = calcularDiferenciaHoras(item.horaCesion, item.horaEmision);
                }
            } else {
                nuevoItem.tiempoCesion = '';
            }
            return nuevoItem;
        });
    };
    const arrayProcesado = procesarArray(facturasFilt);
    const columns = [
        {
            accessor: 'fechaEmision', //access nested data with dot notation
            Header: 'Fecha Emisión',
            size: 100,
            formato: 'fecha',
            headerPost: 'center',
            headerSlice: true,
        },
        {
            accessor: 'rutDeudor',
            Header: 'RUT',
            formato: 'rut',
            headerPost: 'center',
        },
        {
            accessor: 'nomDeudor', //normal accessor
            Header: 'Nombre',
            title: true,
            formato: 'string',
            truncate: 'max-Width: 20em !important; text-align: left;   white-space: nowrap;  overflow: hidden;   text-overflow: ellipsis;',
        },
        {
            accessor: 'tipoDocto',
            Header: 'Tipo Documento',
            headerPost: 'center',
            headerSlice: true,
        },
        {
            accessor: 'estado',
            Header: 'Estado',
            size: 100,
            headerPost: 'center',
            icono: true,
            action: false,
            Cell: ({ row }) => {
                const { color, descripcion } = getEstadosFactura(row.original.estado);
                return (
                    <>
                        <span
                            title={descripcion}
                            style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginLeft: '1em',
                                cursor: 'default' // Asegura que el cursor sea el estándar
                            }}
                        ></span>
                    </>
                );
            }
        },
        {
            accessor: 'nroDocto',
            Header: 'Numero Documento',
            headerPost: 'center',
            totalTitulo: true,
            headerSlice: true,
        },
        {
            accessor: 'montoTotal',
            Header: 'Monto',
            headerPost: 'center',
            formato: 'moneda',
            total: true,
            padd: '1em'
        },
        {
            accessor: 'cedido',
            Header: 'Cedido',
            headerPost: 'center',
            formato: 'center',
        },
        {
            accessor: 'cesionario',
            Header: 'Cesionario',
            formato: 'string',
            truncate: 'max-Width: 11em !important; text-align: left;   white-space: nowrap;  overflow: hidden;   text-overflow: ellipsis;',
        },
        {
            accessor: 'tiempoCesion',
            Header: 'Tiempo Cesion',
            formato: 'centro',
            headerPost: 'center',
            headerSlice: true,
        },
    ]
    const transformData = (data, columns) => {
        return data.map(item => {
            let newItem = { ...item };
            columns.forEach(column => {
                const { accessor, formato } = column;
                if (formato) {
                    switch (formato) {
                        case 'numero':
                            newItem[accessor] = Number(newItem[accessor]);
                            break;
                        case 'moneda':
                            newItem[accessor] = Number(newItem[accessor]);
                            break;
                        // case 'fecha':
                        //     newItem[accessor] = new Date(newItem[accessor]).toLocaleDateString();
                        //     break;
                        // Puedes agregar más formatos aquí
                        default:
                            break;
                    }
                }
            });
            return newItem;
        });
    };
    const transformDataExcel = (data, columns) => {
        return data.map(item => {
            let newItem = { ...item };
            columns.forEach(column => {
                const { accessor, formato } = column;
                if (accessor === 'estado') {
                    const { descripcion } = getEstadosFactura(newItem[accessor]);
                    newItem[accessor] = descripcion; // Usa la descripción del estado
                }
            });
            return newItem;
        });
    };
    const transformedData = transformData(arrayProcesado, columns);
    const transformedDataExcel = transformDataExcel(transformedData, columns);
    // Crear una copia del array original y modificar el header
    const modifiedColumns = columns.map(column => {
        if (column.accessor === 'nroDocto') {
            return {
                ...column,
                Header: 'N° Documento' // Modificar el Header
            };
        }
        return column;
    });
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <p className='titGraf mx-3'>{tituloComponent}</p>
                    <p className="titGraf2 pb-2 mx-3">Últimos 30 días</p>
                </div>
                <div className='pe-3'>
                    <BotonExcel data={transformedDataExcel} getColumnNames={modifiedColumns} title={tituloComponent} />
                </div>
            </div>
            <TableContainer
                columns={columns}
                data={transformedData}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={pagSiz}
                isPagination={isPagination}
                filterable={false}
                iscustomPageSizeOptions={false}
                tableClass="TbXs align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
                isLoading={isLoading}
                isTotal={true}
                sortName='fechaEmision'
                secondarySortName='montoTotal'
                sortOrder='asc'
                pageIndex={pageIndex} // Pasar pageIndex
                setPageIndex={setPageIndex} // Pasar setPageIndex
            />
        </>

    )
}