import { Navigate, Route, Routes } from 'react-router-dom';

import { Inicio } from '../inicio/Inicio';
import { Resumen } from '../resumen/Resumen';
import { Tgr } from "../tgr/Tgr";
import { Sii } from "../sii/Sii";
import { Comercial } from "../comercial/Comercial";
import { GestionFinanciera } from "../gestion/GestionFinanciera";
import NavBar from '../navbar/NavBar';
import SideBar from '../sidebar/SideBar';
import { TitleBar } from '../titlebar/TitleBar';

export const ComponentRoutes = () => {
    return (
        <>
            <div className="d-flex flex-column h-100 min-vh-100 fondo">
                <div className="row">
                    <div className="col-12">
                        <NavBar />
                    </div>
                </div>
                <div className="row flex-grow-1">
                    <div className="col-1 d-flex flex-column">
                        <SideBar />
                    </div>
                    <div className="col-11 ps-0 pe-0 flex-grow-1">
                        <TitleBar />
                        <div className="p-0 m-0 flex-grow-1">
                            <Routes>
                                <Route path="/inicio" element={<Inicio />} />
                                <Route path="/resumen/" element={<Resumen />} />
                                <Route path="/tgr/" element={<Tgr />} />
                                <Route path="/sii/" element={<Sii />} />
                                <Route path="/comercial/" element={<Comercial />} />
                                <Route path="/gestion/" element={<GestionFinanciera />} />
                                <Route path="*" element={<Navigate to="/Login" />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-1 ">
                    <SideBar />
                </div>
                <div className="col-11 ps-0 pe-0">
                    
                    <NavBar />
                        <TitleBar />
                    
                    <div className=" p-0 m-0">
                        <Routes>
                            <Route path="/inicio" element={<Inicio />} />
                            <Route path="/resumen/" element={<Resumen />} />
                            <Route path="/tgr/" element={<Tgr />} />
                            <Route path="/sii/" element={<Sii />} />
                            <Route path="/comercial/" element={<Comercial />} />
                            <Route path="/gestion/" element={<GestionFinanciera />} />
                            <Route path="*" element={<Navigate to="/Login" />} />

                        </Routes>
                    </div>
                </div> */}
            {/* </div > */}

        </>
    )
}