import TableContainer from '../../../shared/TableContainer';
import { useState } from 'react';
import { ModalFormulario22 } from '../../modal/ModalFormulario22';
import { ModalFormulario22sii } from '../../modal/ModalFormulario22sii';
import { IconButton, Tooltip } from '@mui/material';
import Assignment from '../../../shared/icon/newIcons/assignment.svg'

export const Formulario22SII = ({ data }) => {
  const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
  const [rowSelect, setRowSelect] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalF22sii, setModalF22sii] = useState(false);
  const F22 = data?.map(item => {
    return {
      ...item,
      periodo: (parseInt(item.periodo) - 1).toString()
    };
  });
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const toggleF22sii = () => {
    if (modal) {
      setModalF22sii(false);
    } else {
      setModalF22sii(true);
    }
  };
  const idSelector = (row, bool, id) => {
    if(id === 'DAI'){
      setRowSelect(row);  // Establecer la fila seleccionada en el estado
      toggleF22sii();
    }else{
      setRowSelect(row);  // Establecer la fila seleccionada en el estado
      toggle();
    }
  };
  const columns = [
    {
      accessor: 'periodo',
      Header: 'Año Contable',
      inicio:true
    },
    {
      accessor: 'nroFicha',
      Header: 'Folio',
      inicio:true
    },
    {
      accessor: 'descripcion',
      Header: 'Descripción',
      truncate: 'max-Width: 58em !important; text-align: left;   white-space: nowrap;  overflow: hidden;   text-overflow: ellipsis;',
      formato:'string',
      title: true
    },
    {
      accessor: 'dai',
      Header: 'DAI',
      size: 50,
      icono: true,
      headerPost:'center',
      Cell: ({ row }) => (
          <div className='d-flex justify-content-center align-items-center'>
              <Tooltip title="Comportamiento" className='p-0' style={{ height: '50%' }}>
                  <IconButton color="success" onClick={() => idSelector(row.original,'' , 'DAI')}>
                  <img className='pb-1 pe-1' src={Assignment} alt="" width="22" height="22" />
                  </IconButton>
              </Tooltip>
          </div>
      ),
  },
  ];
  return (
    <>
      <TableContainer
        columns={columns}
        data={F22}
        isGlobalFilter={false}
        isAddOptions={false}
        customPageSize={20}
        isPagination={true}
        filterable={false}
        iscustomPageSizeOptions={false}
        tableClass="TbXs align-middle table-nowrap table-check"
        theadClass="table-light"
        pagination="pagination pagination-rounded justify-content-end mb-2"
        isLoading={false}
        selectorRow={idSelector}
        sortName = 'periodo'
        sortOrder = 'asc'
        pageIndex={pageIndex} // Pasar pageIndex
        setPageIndex={setPageIndex} // Pasar setPageIndex
      />
      <ModalFormulario22
        modal={modal}
        toggle={() => setModal(!modal)}
        data={rowSelect}
      />
      <ModalFormulario22sii
        modal={modalF22sii}
        toggle={() => setModalF22sii(!modalF22sii)}
        data={rowSelect}
      />
    </>
  );
};
    