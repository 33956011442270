import { useContext } from 'react'
import Logo from '../../shared/logo.png'
import { AuthContext } from '../../auth'
import { useNavigate } from 'react-router-dom';
import { IdContext } from '../../context/IdContext';

const NavBar = () => {
  const { user, logout } = useContext(AuthContext);
  const idProvider = useContext(IdContext);
  const IdNombre = useContext(IdContext);

  const NombreCli = IdNombre.IdNombre

  const id = idProvider.idProvider

  const navigate = useNavigate();

  const onLogout = () => {
    navigate('/login', {
      replace: true,
    })
    logout();
  }
  return (
    <div>

      <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top navTop" data-bs-theme="dark">
      <img className="ps-3" src={Logo} alt="" width="203" height="26" />
        <div className="row col">
          <span className="text-light user col-4 pt-2 ps-3" > </span>
          <span className="text-light user col-4 pt-2  text-uppercase" > </span>
          <span className="text-light d-flex  user col-4 pe-3 pt-2 justify-content-end" > {user?.nameFactorin}  <span className='px-2'>|</span> {user?.name} <span className='ps-2'>|</span>
            <button
              className="nav-item nav-link btn ps-2 pb-2"
              onClick={onLogout}
            >
              Cerrar sesion
            </button>

          </span>

        </div>
      </nav>

    </div>
  )
}

export default NavBar
