import { dataApi } from "../api/dataApi";

export const SII = {
    getResumenSiiCarpetaTrib: function(id) {
        return dataApi.get(`${process.env.REACT_APP_SII_OBTENER_CARPETA_TRIBUTARIA}${id}`);
    },
    getResumenSiiF29: function(id) {
        return dataApi.get(`${process.env.REACT_APP_SII_OBTENER_RESUMEN_F29}${id}`);
    },
    getResumenSiiF22: function(id) {
        return dataApi.get(`${process.env.REACT_APP_SII_OBTENER_F22}${id}`);
    },
    getObtenerMiSII: function(id) {
        return dataApi.get(`${process.env.REACT_APP_SII_OBTENER_MI_SII}${id}`);
    },
    getObtenerPresentacionF29: function(id, x) {
        return dataApi.get(`${process.env.REACT_APP_SII_OBTENER_PRESENTACION_F29}${id}/${x}`);
    },
    getObtenerFormulario22rReporte: function(id, x) {
        return dataApi.get(`${process.env.REACT_APP_SII_OBTENER_F22R_REPORTE}${id}/${x}`);
    },
};