import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import {  useEffect, useState } from "react";
import { getBoletin } from "../../helpers/getBoletin";
import { TablaBoletin } from '../tablas/TablaBoletin';

export const ModalBoletinConcursalNew = ({
    modal,
    toggle,
    data
}) => {
    const [boletinSel, setBoletinSel] = useState([]);
    useEffect(() => {
                getBoletin.ObtenerBoletinConcursal(data).then((r) => {
                    console.log("Datos obtenidos del boletín:", r.data);
                    setBoletinSel(r.data.listadoBoletinConcursal);
                }).catch((e) => {
                    console.error(e);
                });
    }, [data]);
    return (
        <Modal isOpen={modal} toggle={toggle} size={'xl'}>
            <ModalHeader className="justify-content-center">
                <h5 >Boletin Concursal</h5>
            </ModalHeader>
            <ModalBody>
            {boletinSel?.length > 0 ? (
                            <TablaBoletin boletinSel={boletinSel} />
                        ) : (
                            <p>No hay datos disponibles</p>
                        )}
            </ModalBody>
        </Modal>
        // <div className="modal fade" id="ModalFormulario22" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
        //     <div className="modal-dialog modal-dialog-centered">
        //         <div className="modal-content" style={{ width: 'auto', minWidth: '700px' }}>
        //             <div className="modal-header d-flex justify-content-center align-items-center">
        //                 <h5 className="modal-title text-center">Formulario 22</h5>
        //             </div>
        //             <div className="modal-body">
        //                 <div className="mx-5 mt-2 mb-5 col table-responsive">
        //                     <div><b>Año:</b> <span>{data.periodo}</span></div>
        //                     <div><b>Folio:</b> <span>{data.nroFicha}</span></div>
        //                     <div><b>Descripcion:</b> <span>{data.descripcion}</span></div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
