export const NotData = (msg) => {
    return (
        <div className="alert alert-warning text-center " style={{backgroundColor: '#F5E0D6', border: '#EBC3B0', fontSize: '0.8em', color: '#C4714A'}}  role="alert">
            {msg.msg}
        </div>

    )
}


