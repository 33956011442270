// import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ModalDetalleDeudor } from '../modal/ModalDetalleDeudor'
import TableContainer from '../../shared/TableContainer';
import Data_loss_prevention from '../../shared/icon/newIcons/data_loss_prevention.svg'
import { getEstadosFactura } from '../../helpers/getEstadosFactura';

export const TablaGestionComercialDemo = ({
    FacturasGC,
    isLoading,
    sendColumns
}) => {
    const [pageIndex, setPageIndex] = useState(0); // Estado para la página actual
    const [IdDeudor, setIdDeudor] = useState([])
    const [NomDeudor, setNomDeudor] = useState([])
    const ColumnFilter = ({ column }) => {
        const { filterValue, setFilter } = column;
        return (
            <input
                value={filterValue || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Buscar..."
            />
        );
    };
    const columns = [
        {
            accessor: 'rutCli', //access nested data with dot notation
            Header: 'Rut Cliente',
            size: 100,
            formato: 'rut',
            inicio: true,
            minWidth:'8em'
        },
        {
            accessor: 'nomCli',
            Header: 'Nombre Cliente',
            minSize: 300,
            formato: 'string',
            Filter: ColumnFilter,
            minWidth:'40em'
        },
        {
            accessor: 'tipoDocto', //normal accessor
            Header: 'Tipo Doc',
            
        },
        {
            accessor: 'nroDocto', //normal accessor
            Header: 'Num',
            size: 100,
            formato: 'numero',
            minWidth:'3em'
        },
        {
            accessor: 'fechaDocto', //normal accessor
            Header: 'Fecha Emisión',
            size: 100,
            headerPost: 'center',
            formato: 'fecha',
        },
        {
            accessor: 'estado',
            Header: 'Estado',
            size: 100,
            headerPost: 'center',
            icono: true,
            action: false,
            Cell: ({ row }) => {
                const { color, descripcion } = getEstadosFactura(row.original.estado);
                return (
                    <>
                        <span
                            title={descripcion}
                            style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginLeft: '1em',
                                cursor: 'default' // Asegura que el cursor sea el estándar
                            }}
                        ></span>
                    </>
                );
            }
        },
        {
            accessor: 'montoVenta',
            Header: 'Monto',
            formato: 'moneda',
            size: 90,
            headerPost: 'center',
        },
        {
            accessor: 'diasEmision',
            Header: 'Dias Emisión',
            size: 80,
            headerPost: 'center',
        },
        {
            accessor: 'rutDeu',
            Header: 'Rut Deudor',
            size: 100,
            inicio: true,
            formato: 'rut',
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            accessor: 'nomDeu',
            Header: 'Nombre Deudor',
            minSize: 450,
            formato: 'string',
            Filter: ColumnFilter,
            minWidth:'40em'
        },
        {
            accessor: 'idDeu',
            Header: 'Analisis Deudor',
            size: 50,
            icono: true,
            headerPost:'center',
            Cell: ({ row }) => (
                <div className='d-flex justify-content-center align-items-center'>
                    <Tooltip title="Comportamiento" className='p-0' style={{ height: '50%' }}>
                        <IconButton color="success" onClick={() => openModalDetalleComportamiento(row.original.idDeu, row.original.nomDeu)} data-bs-toggle="modal" data-bs-target="#ModalDetalleDeudor">
                        <img className='pb-1 pe-1' src={Data_loss_prevention} alt="" width="22" height="22" />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ]
    useEffect(() => {
        sendColumns(columns);
    }, []);
    const openModalDetalleComportamiento = (IdDeu, nomDeu) => {
        setIdDeudor(IdDeu)
        setNomDeudor(nomDeu)
    };
    return (
        <>
            <TableContainer
                columns={columns}
                data={FacturasGC}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={20}
                isPagination={true}
                filterable={false}
                iscustomPageSizeOptions={false}
                tableClass="TbXs align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
                isLoading={isLoading}
                sortName='fechaDocto'
                secondarySortName='montoVenta'
                sortOrder='asc'
                pageIndex={pageIndex} // Pasar pageIndex
                setPageIndex={setPageIndex} // Pasar setPageIndex
            />
            <ModalDetalleDeudor
                IdDeudor={IdDeudor}
                NomDeudor={NomDeudor}
                id={'ModalDetalleDeudor'} />
        </>
    )
}





