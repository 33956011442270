// import './Inicio.css';
// import { inicio } from '../../helpers/inicio';
// import { TablaInicioDemo } from '../tablas/TablaInicioDemo';
// import { useContext, useEffect, useState } from 'react';
// import { IdContext } from '../../context/IdContext';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../auth';
// import { ModalBoletinConcursal } from '../modal/ModalBoletinConcursal';
// import { ModalEliminarEmpresa } from '../modal/ModalEliminarEmpresa';
// import { ModalCorreccionEmpresa } from '../modal/ModalCorreccionEmpresa';


// export const Inicio = () => {
//   // const  Empresas =  getDataByEmpresa('146');
//   const navigate = useNavigate();
//   const [Empresas, setEmpresas] = useState([])
//   const { idProvider, setIdProvider } = useContext(IdContext);
//   const { idNombre, setIdNombre } = useContext(IdContext);
//   const [ld, setLd] = useState([])
//   const { user } = useContext(AuthContext);
//   const [isLoading, setIsLoading] = useState(true);
//   const logNam = localStorage.getItem("fuLogin")
//   useEffect(() => {
//     setIdProvider(0);
//     setIdNombre('')
//   }, []);


//   useEffect(() => {
//     inicio.ObtenerEmpresasUsuarios(logNam).then((r) => {
//       setEmpresas(r.data.listadoFactoringEmpresas)
//       setIsLoading(false);
//     }).catch((e) => {
//       if (e.request.status === 401) {
//         localStorage.clear();
//         // navigate(`/inicio`);
//         navigate('/login', {
//           replace: true,
//         })
//         setLd(1)
//       }
//       if (localStorage.loadInit === 0) {
//         localStorage.setItem('loadInit', 1)
//         setLd(1)
//       }
//     })
//   }, [, ld])

//   const updatedData = Empresas.map(item => 
//     item.idEmpresa === 2 ? { ...item, isLoading: true } : item
// );
//   console.log(updatedData)

//   return (
//     <>
//       <div className='row   ps-4 pt-3'>
//         <div className='row-12 col-8 '>
//           <div className="card" >
//             <div className='col-9'>
//               <p className="titGraf pt-2 mx-4 ">Empresas Registradas</p>
//               <p className="titGraf2 mx-4">Debe seleccionar un cliente para visualizar la información</p>
//             </div>
//             <TablaInicioDemo
//               Empresas={updatedData}
//               isLoading={isLoading}
//             />
//           </div>
//         </div>
//       </div>
//       {/* <ModalBoletinConcursal id={'ModalBoletinConcursal'} /> */}
//       {/* <ModalEliminarEmpresa id={'ModalEliminarEmpresa'} />
//       <ModalCorreccionEmpresa id={"ModalCorreccionEmpresa"} /> */}
//     </>
//   )
// }


// import './Inicio.css';
// import { inicio } from '../../helpers/inicio';
// import { TablaInicioDemo } from '../tablas/TablaInicioDemo';
// import { useContext, useEffect, useState } from 'react';
// import { IdContext } from '../../context/IdContext';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../auth';
// import { ModalBoletinConcursal } from '../modal/ModalBoletinConcursal';
// import { ModalEliminarEmpresa } from '../modal/ModalEliminarEmpresa';
// import { ModalCorreccionEmpresa } from '../modal/ModalCorreccionEmpresa';

// export const Inicio = () => {
//   const navigate = useNavigate();
//   const [Empresas, setEmpresas] = useState([]);
//   const { idProvider, setIdProvider } = useContext(IdContext);
//   const { idNombre, setIdNombre } = useContext(IdContext);
//   const [ld, setLd] = useState([]);
//   const { user } = useContext(AuthContext);
//   const [isLoading, setIsLoading] = useState(true);
//   const logNam = localStorage.getItem("fuLogin");

//   useEffect(() => {
//     setIdProvider(0);
//     setIdNombre('');
//   }, []);

//   useEffect(() => {
//     const fetchEmpresas = () => {
//       inicio.ObtenerEmpresasUsuarios(logNam).then((r) => {
//         setEmpresas(r.data.listadoFactoringEmpresas);
//         setIsLoading(false);
//       }).catch((e) => {
//         if (e.request.status === 401) {
//           localStorage.clear();
//           navigate('/login', {
//             replace: true,
//           });
//           setLd(1);
//         }
//         if (localStorage.loadInit === 0) {
//           localStorage.setItem('loadInit', 1);
//           setLd(1);
//         }
//       });
//     };

//     fetchEmpresas();
    
//     const interval = setInterval(() => {
//       fetchEmpresas();
//     }, 10000);

//     return () => clearInterval(interval); // Cleanup interval on component unmount
//   }, [logNam, navigate]);

//   // const updatedData = Empresas.map(item => 
//   //   item.idEmpresa === 2 ? { ...item, isLoading: true } : item
//   // );

//   // console.log(updatedData);

//   return (
//     <>
//       <div className='row ps-4 pt-3'>
//         <div className='row-12 col-8 '>
//           <div className="card">
//             <div className='col-9'>
//               <p className="titGraf pt-2 mx-4">Empresas Registradas</p>
//               <p className="titGraf2 mx-4">Debe seleccionar un cliente para visualizar la información</p>
//             </div>
//             <TablaInicioDemo
//               Empresas={Empresas}
//               isLoading={isLoading}
//             />
//           </div>
//         </div>
//       </div>
//       {/* <ModalBoletinConcursal id={'ModalBoletinConcursal'} /> */}
//       {/* <ModalEliminarEmpresa id={'ModalEliminarEmpresa'} />
//       <ModalCorreccionEmpresa id={"ModalCorreccionEmpresa"} /> */}
//     </>
//   );
// };


import './Inicio.css';
import { inicio } from '../../helpers/inicio';
import { TablaInicioDemo } from '../tablas/TablaInicioDemo';
import { useContext, useEffect, useState, useRef } from 'react';
import { IdContext } from '../../context/IdContext';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth';
import { ModalBoletinConcursal } from '../modal/ModalBoletinConcursal';
import { ModalEliminarEmpresa } from '../modal/ModalEliminarEmpresa';
import { ModalCorreccionEmpresa } from '../modal/ModalCorreccionEmpresa';

export const Inicio = () => {
  const navigate = useNavigate();
  const [Empresas, setEmpresas] = useState([]);
  const { idProvider, setIdProvider } = useContext(IdContext);
  const { idNombre, setIdNombre } = useContext(IdContext);
  const [ld, setLd] = useState([]);
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const logNam = localStorage.getItem("fuLogin");

  useEffect(() => {
    setIdProvider(0);
    setIdNombre('');
  }, []);

  useEffect(() => {
    const fetchEmpresas = () => {
      inicio.ObtenerEmpresasUsuarios(logNam).then((r) => {
          const newEmpresas = r.data.listadoFactoringEmpresas;
        
          logIsLoadingEmpresas(newEmpresas);
          setEmpresas(newEmpresas);
          setIsLoading(false);
      }).catch((e) => {
        console.log(e)
        if (e.request.status === 401) {
          localStorage.clear();
          navigate('/login', {
            replace: true,
          });
          setLd(1);
        }
        if (localStorage.loadInit === 0) {
          localStorage.setItem('loadInit', 1);
          setLd(1);
        }
      });
    };

    const logIsLoadingEmpresas = (empresas) => {
      const currentTime = new Date().toLocaleTimeString();
      console.log(empresas)
      empresas.forEach((empresa) => {
        if (empresa.isLoading) {
          console.log(`Empresa con isLoading=true: ${empresa.nombre} a las ${currentTime}`);
        }
      });
    };

    fetchEmpresas();
    
    const interval = setInterval(() => {
      fetchEmpresas();
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [logNam, navigate]);

  return (
    <>
      <div className='row ps-4 pt-3'>
        <div className='row-12 col-8 '>
          <div className="card">
            <div className='col-9'>
              <p className="titGraf pt-2 mx-4">Empresas Registradas</p>
              <p className="titGraf2 mx-4">Debe seleccionar un cliente para visualizar la información</p>
            </div>
            <TablaInicioDemo
              Empresas={Empresas}
              // isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {/* <ModalBoletinConcursal id={'ModalBoletinConcursal'} /> */}
      {/* <ModalEliminarEmpresa id={'ModalEliminarEmpresa'} />
      <ModalCorreccionEmpresa id={"ModalCorreccionEmpresa"} /> */}
    </>
  );
};