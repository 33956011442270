import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here
import ShieldX from '../../../shared/icon/shieldX.svg'
import ShieldOk from '../../../shared/icon/shieldOk.svg'
import { useState } from 'react';
import { ModalBoletinConcursalNew } from '../../modal/ModalBoletinConcursalNew';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

export const SociedadesSII = ({
  sociedades
}) => {
  const [consulta, setConsulta] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const idSelector = (rgId) => {
    setConsulta(rgId);
    toggle();
    console.log('abrir modal del idSelector:', rgId);
  };
  return (
    <>
      <table className="table table-hover tableStandard">
        <thead className="table-info">
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">RUT</th>
            <th scope="col">Capital Enterado</th>
            <th scope="col">Capital</th>
            <th scope="col">Utilidad</th>
            <th scope="col">Fecha </th>
            <th>Boletín Concursal</th>
          </tr>
        </thead>
        <tbody>
          {sociedades !== undefined && sociedades.map(sociedad => {
            let rut = sociedad.sdRut + '-' + sociedad.sdDv
            // let fechaFormateada = new Date(sociedad.sdFhIncorporacion).toLocaleDateString('es-ES').slice(0, 10);
            const fechaFormateada = new Date(sociedad.sdFhIncorporacion.slice(0, 10)).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('-');
            return (
              <tr key={sociedad.rgId}>
                <td>{sociedad.sdRznSocial}</td>
                <td>{rut.length === 9 ? rut.slice(0, 1) + '.' + rut.slice(1, 4) + '.' + rut.slice(4, 7) + rut.slice(7) : rut.slice(0, 2) + '.' + rut.slice(2, 5) + '.' + rut.slice(5, 8) + rut.slice(8)}</td>
                <td>$ {sociedad.sdAporteEnterado}</td>
                <td>{sociedad.sdPctPartiCapital}%</td>
                <td>{sociedad.sdPctPartiUtilidad}%</td>
                <td>{fechaFormateada}</td>
                <td>{sociedad.sdBoletinConcursal? (
                    <span
                      onClick={() => idSelector(sociedad.rgId)}
                      style={{ marginLeft: '2em', cursor: 'pointer' }}
                    >
                      <img src={ShieldX} alt="" width="20" height="20" />
                    </span>
                  ) : null}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ModalBoletinConcursalNew
        modal={modal}
        toggle={() => setModal(!modal)}
        data={consulta}
      />
    </>
  )
}






