
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import './Login.css'
import { useEffect, useState } from 'react';
import { loggin } from '../../helpers/loggin';
import { useContext } from 'react';
import { AuthContext } from '../../auth/context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Alert from '../../shared/icon/alert.svg'
import { IdContext } from '../../context/IdContext';
import Logo from '../../shared/logo.png'
import { Spinner } from 'reactstrap';
export const Login = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('loadInit')
  localStorage.removeItem('fuLogin')
  localStorage.removeItem('IdFact')
  const { login } = useContext(AuthContext)
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginOk, setIsLoginOk] = useState(false);
  const { setIdFactProvider } = useContext(IdContext);
  const onLogin = () => {

    navigate('/inicio')

  }
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    localStorage.clear();
    e.preventDefault();
    setLoading(true)
    loggin.getlogin(email, password)
      .then((r) => {
        localStorage.setItem('token', r.data.token)
        localStorage.setItem('loadInit', 0)
        localStorage.setItem('fuLogin', r.data.fuLogin)
        localStorage.setItem('IdFact', r.data.rgIdFactoring)
        setIdFactProvider(r.data.rgIdFactoring)
        login(r.data.nomUsuario, r.data.nomFactoring, r.data.fuLogin, r.data.token, r.data.rgIdFactoring);
        setLoading(false)
        // navigate('/inicio')
        onLogin()
      }).catch((e) => {
        setLoading(false)
        if (e.response?.status === 401) {
          setIsLoginOk(true)
          const timer = setTimeout(() => {
            setIsLoginOk(false);
          }, 4000); // 4000ms = 4 segundos
        }
        console.error(e)
      })
  };
  return (
    <>
      <div className='bgdLogin '>
        <div className="card cardLogin container col-2 pb-2" >
          <form className="row-4 container " onSubmit={handleSubmit}>
            <div className='text-center pt-3'>
              <label className="form-label text-center "> <img className='me-3' src={Logo}
                alt="" width="100%" height="auto" /></label>
            </div>
            <div className="col-md-auto pt-3">
              <label className="form-label">Usuario</label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="validationTooltipUsernamePrepend"><AccountCircleRoundedIcon /></span>
                <input type="text" className="form-control" id="validationTooltipUsername" placeholder="Ingrese su usuario"
                  aria-describedby="validationTooltipUsernamePrepend" required onChange={(e) => setEmail(e.target.value)} />
                <div className="invalid-tooltip">
                  Please choose a unique and valid username.
                </div>
              </div>
            </div>
            <div className="col-md-auto py-3">
              <label className="form-label">Clave</label>
              <div className="input-group has-validation">
                <span className="input-group-text" id="validationTooltipPasswordPrepend"><KeyRoundedIcon /></span>
                <input type="password" className="form-control" id="validationTooltipPassword" placeholder="Ingrese su clave"
                  aria-describedby="validationTooltipPasswordPrepend" required onChange={(e) => setPassword(e.target.value)} />
                <div className="invalid-tooltip">
                  Please choose a unique and valid username.
                </div>
              </div>
            </div>
            <div className="col-md-auto py-3  px-5 row justify-content-center">
              <button className="btn" style={{ background: '#657AB0', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }} type="submit">
                {loading ? <><Spinner size="sm" /> <span style={{ marginLeft: '0.5rem' }}>Iniciando sesión</span></> : 'Ingresar'}
              </button>
            </div>
            <span className='mx-0 mb-3' style={{ fontSize: '0.7em' }}>
              {!isLoginOk ? 'Utilice el mismo usuario y la misma clave de acceso de su aplicación Factoring Prosystem.' :
                <div className={`alert alert-danger col-md-auto  ${isLoginOk ? 'd-block' : 'd-none'}`} style={{ fontSize: '0.8em' }} role="alert">
                  <img className='me-3' src={Alert}
                    alt="" width="12" height="13" />
                  Error en el usuario o la contraseña
                </div>}
            </span>
          </form>
        </div>
      </div>

    </>
  )
}