import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { formatVal } from '../../../helpers/formatVal';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const VentasVsCompras = ({ data, mp }) => {
  const [processedData, setProcessedData] = useState({ ventas: {}, compras: {} });
  useEffect(() => {
    const ventasTemp = {};
    const comprasTemp = {};
    data.forEach(item => {
      const parseFecha = (fecha) => {
        if (!fecha) return null;
        const parts = fecha.split('/');
        // Asumiendo que la fecha es día/mes/año y se crea correctamente en la zona horaria local
        return new Date(parts[2], parts[1] - 1, parts[0]);
      };
      const fechaCompra = parseFecha(item.fecRecCom);
      const fechaVenta = parseFecha(item.fecRecVta);
      const claveCompra = fechaCompra ? `${fechaCompra.getMonth() + 1}/${fechaCompra.getFullYear()}` : null;
      const claveVenta = fechaVenta ? `${fechaVenta.getMonth() + 1}/${fechaVenta.getFullYear()}` : null;
      if (claveCompra) {
        const monto = item.desDocCom.includes('NOTA') ? -item.montoCompra : item.montoCompra;
        comprasTemp[claveCompra] = (comprasTemp[claveCompra] || 0) + monto;
      }
      if (claveVenta) {
        const monto = item.desDocVta.includes('NOTA') ? -item.montoVenta : item.montoVenta;
        ventasTemp[claveVenta] = (ventasTemp[claveVenta] || 0) + monto;
      }
    });
    setProcessedData({ ventas: ventasTemp, compras: comprasTemp });
  }, [data]);
  // Meses en formato texto
  const monthNames = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
  const endDate = new Date();
  const startDate = new Date();
  startDate.setMonth(endDate.getMonth() - mp + 1);
  const labels = [];
  for(let date = new Date(startDate); date <= endDate; date.setMonth(date.getMonth() + 1)) {
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    labels.push(`${monthNames[monthIndex]}-${year}`);
  }
  const dataChart = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'Compras',
        backgroundColor: '#BC9C8C',
        data: labels.map(label => {
          const [month, year] = label.split('-');
          const key = `${monthNames.indexOf(month) + 1}/${year}`;
          return processedData.compras[key] || 0;
        }),
        stack: 'Stack 1',
      },
      {
        type: 'bar',
        label: 'Ventas',
        backgroundColor: '#657AB0',
        data: labels.map(label => {
          const [month, year] = label.split('-');
          const key = `${monthNames.indexOf(month) + 1}/${year}`;
          return processedData.ventas[key] || 0;
        }),
        stack: 'Stack 0',
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        backgroundColor: '#E4EBF0', // Color de fondo del tooltip
        titleColor: '#000', // Color del título del tooltip
        bodyColor: '#666', // Color del cuerpo del tooltip
        borderColor: '#DDD', // Color del borde del tooltip
        borderWidth: 1, // Ancho del borde del tooltip
        titleFont: {
          size: 14, // Tamaño de la fuente del título
          weight: 'bold' // Grosor de la fuente del título
        },
        bodyFont: {
          size: 12 // Tamaño de la fuente del cuerpo
        },
        callbacks: {
          title: function(tooltipItems) {
            return tooltipItems[0].label;
          },
          label: function(tooltipItem) {
            let label = tooltipItem.dataset.label + ':';
            let value = tooltipItem.raw;
            return label + formatVal.formatValueCLPFull(value);
          },
        }
      },
      legend: {
        display: false
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          // Shorthand the millions
          callback: function(value, index, values) {
              return value / 1e6 + ' M';
          }
      }
      },
    }
  };
  return (
    <>
      <Chart id='barra' type='bar' options={options} data={dataChart} />
    </>
  );
};