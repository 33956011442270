// import { Grafico }  from '../resumen';

import { GraficoPie } from "../resumen/graficosPie/GraficoPie"
import { VentasDeudor } from "../resumen/graficosPie/VentasDeudor"

export const Card = ({
    ResVensDdr
}) => {
    console.log(ResVensDdr)
    const paleta = [
        '#0D2E82',
        '#74D3E8',
        '#657AB0',
        '#EBC3B0',
        '#B55C48',
        '#A2AFCF',
        '#BC9C8C',
        '#D9D9D9',
    ]
    const porcentaje = []
    const listItemsDeudor = [,]
    let suma = 0
    let sum = 0
    let counter = -1
    ResVensDdr.forEach((numeros) => {
        console.log(numeros)
        suma += numeros.montoFactura - numeros.montoNCredito;
    });

    ResVensDdr.forEach((numeros) => {
        porcentaje.push((numeros.montoFactura * 100 / suma).toFixed(1))
        sum += numeros.montoFactura * 100 / suma;
    });

    ResVensDdr.forEach((numeros) => {
        let porc = (numeros.montoFactura * 100 / suma).toFixed(1)
        numeros.porcentaje = parseFloat(porc)
        sum += numeros.montoFactura * 100 / suma;
    });
    const idSelector = () => {

        return 'modal'
    }

    return (
        <div className="card cardDot" onClick={() => idSelector()}>
            <div className="row  g-3">
                <p className='col-12 titGraf pt-3 text-center' style={{ fontSize: 20 }}>Ventas x deudor</p>
                <div className="col-2 ps-5 mb-5">
                    <GraficoPie
                        monto={ResVensDdr.montoFactura}
                        porcentaje={porcentaje}
                    />
                </div>
                <div className="col-9 ps-5">
                    <table className="table TbXs">
                        <thead>
                            <tr>
                                <th></th>
                                <th data-bs-toggle="tooltip" title="Porcentaje del total">Porcentaje</th>
                                <th style={{ width: '30%' }} data-bs-toggle="tooltip" title="Nombre completo del deudor">Nombre del Deudor</th>
                                <th data-bs-toggle="tooltip" title="Total en facturas">Total Ventas</th>
                                <th data-bs-toggle="tooltip" title="Número total de facturas">Cant Facturas</th>
                                <th data-bs-toggle="tooltip" title="Total en notas de crédito">Monto NC</th>
                                <th data-bs-toggle="tooltip" title="Número total de notas de crédito">Cant NC</th>
                                <th data-bs-toggle="tooltip" title="Total reclamado">Monto Reclamo</th>
                                <th data-bs-toggle="tooltip" title="Número total de reclamos">Cant Reclamos</th>
                                <th data-bs-toggle="tooltip" title="Monto total cedido">Monto Cedido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ResVensDdr.map((resvd, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="cuadradoDetalle mt-1 me-1" style={{ background: paleta[index % paleta.length] }}></div>
                                    </td>
                                    <td className="right-aligned  pe-3">
                                        {resvd.porcentaje}%
                                    </td>
                                    <td>{resvd.nombreDeudor}</td>
                                    <td className="right-aligned">{(resvd.montoFactura - resvd.montoNCredito).toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                    <td className="right-aligned pe-3">{resvd.cantFactura}</td>
                                    <td className="right-aligned pe-3" style={{
                                        backgroundColor: '#f2d2cd',
                                        border: '1px solid #ccc', // Borde sutil para mejorar separación
                                        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)', // Sombra sutil para efecto 3D
                                    }}>
                                        {resvd.montoNCredito.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}
                                    </td>
                                    <td className="right-aligned pe-3" style={{
                                        backgroundColor: '#f2d2cd',
                                        border: '1px solid #ccc',
                                        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                                    }}>
                                        {resvd.cantNCredito}
                                    </td>
                                    <td className="right-aligned pe-3" style={{
                                        backgroundColor: '#cde5f2',
                                        border: '1px solid #ccc',
                                        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                                    }}>
                                        {resvd.montoReclamo.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}
                                    </td>
                                    <td className="right-aligned pe-3" style={{
                                        backgroundColor: '#cde5f2',
                                        border: '1px solid #ccc',
                                        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                                    }}>
                                        {resvd.cantReclamo}
                                    </td>
                                    <td className="right-aligned pe-3">{resvd.montoCedido.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="h5 pt-4">
                        Total monto de facturas {suma.toLocaleString("es-CL", { style: "currency", currency: "CLP" })}
                    </div>
                </div>
            </div>
        </div>
    )
}

