import { Card } from "../cards/Card";
import { CardGPCs } from "../cards/CardGPCs";

export const ModalDetalleGraficoCesiones = ({
    ResCsrNoBancDet,
    Periodo
}) => {
    const notPropagation = (e) => {
        e.stopPropagation();
    }
    return (

        <div className="modal fade" id="ModalDetalleGraficoCesiones" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" onClick={(e) => notPropagation(e)}>
        <div className="modal-dialog" style={{ maxWidth: '65%', width: 'auto', top: '50%',  margin: 'auto',transform: 'translate(0%, -50%)'}}>
            <CardGPCs
                ResCsrNoBancDet={ResCsrNoBancDet}
                Periodo={Periodo}
            />
        </div>
    </div>
    )
}


